import { tracked } from "@glimmer/tracking";
import { ajax } from "discourse/lib/ajax";
import { shortDateNoYear } from "discourse/lib/formatter";
import { cook } from "discourse/lib/text";
export default class TopicSummary {
  static #_ = (() => dt7948.g(this.prototype, "text", [tracked], function () {
    return "";
  }))();
  #text = (() => (dt7948.i(this, "text"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "summarizedOn", [tracked], function () {
    return null;
  }))();
  #summarizedOn = (() => (dt7948.i(this, "summarizedOn"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "summarizedBy", [tracked], function () {
    return null;
  }))();
  #summarizedBy = (() => (dt7948.i(this, "summarizedBy"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "newPostsSinceSummary", [tracked], function () {
    return null;
  }))();
  #newPostsSinceSummary = (() => (dt7948.i(this, "newPostsSinceSummary"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "outdated", [tracked], function () {
    return false;
  }))();
  #outdated = (() => (dt7948.i(this, "outdated"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "canRegenerate", [tracked], function () {
    return false;
  }))();
  #canRegenerate = (() => (dt7948.i(this, "canRegenerate"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "regenerated", [tracked], function () {
    return false;
  }))();
  #regenerated = (() => (dt7948.i(this, "regenerated"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "showSummaryBox", [tracked], function () {
    return false;
  }))();
  #showSummaryBox = (() => (dt7948.i(this, "showSummaryBox"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "canCollapseSummary", [tracked], function () {
    return false;
  }))();
  #canCollapseSummary = (() => (dt7948.i(this, "canCollapseSummary"), void 0))();
  static #_10 = (() => dt7948.g(this.prototype, "loadingSummary", [tracked], function () {
    return false;
  }))();
  #loadingSummary = (() => (dt7948.i(this, "loadingSummary"), void 0))();
  processUpdate(update) {
    const topicSummary = update.topic_summary;
    return cook(topicSummary.summarized_text).then(cooked => {
      this.text = cooked;
      this.loading = false;
    }).then(() => {
      if (update.done) {
        this.summarizedOn = shortDateNoYear(topicSummary.summarized_on);
        this.summarizedBy = topicSummary.algorithm;
        this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
        this.outdated = topicSummary.outdated;
        this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
        this.canRegenerate = topicSummary.outdated && topicSummary.can_regenerate;
      }
    });
  }
  collapse() {
    this.showSummaryBox = false;
    this.canCollapseSummary = false;
  }
  generateSummary(currentUser, topicId) {
    this.showSummaryBox = true;
    if (this.text && !this.canRegenerate) {
      this.canCollapseSummary = false;
      return;
    }
    let fetchURL = `/t/${topicId}/strategy-summary?`;
    if (currentUser) {
      fetchURL += `stream=true`;
      if (this.canRegenerate) {
        fetchURL += "&skip_age_check=true";
      }
    }
    this.loading = true;
    return ajax(fetchURL).then(data => {
      if (!currentUser) {
        data.done = true;
        this.processUpdate(data);
      }
    });
  }
}
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class GroupMessages extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  titleToken() {
    return I18n.t("groups.messages");
  }
  model() {
    return this.modelFor("group");
  }
  afterModel(group) {
    if (!group.get("is_group_user") && !(this.currentUser && this.currentUser.admin)) {
      this.router.transitionTo("group.members", group);
    }
  }
  triggerRefresh() {
    this.refresh();
  }
  static #_2 = (() => dt7948.n(this.prototype, "triggerRefresh", [action]))();
}
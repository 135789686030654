/* import __COLOCATED_TEMPLATE__ from './cdn-img.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { htmlSafe } from "@ember/template";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./cdn-img.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  cdnSrc(src) {
    return getURLWithCDN(src);
  },
  style(width, height) {
    if (width && height) {
      return htmlSafe(`--aspect-ratio: ${width / height};`);
    }
  }
}, [["method", "cdnSrc", [discourseComputed("src")]], ["method", "style", [discourseComputed("width", "height")]]])));
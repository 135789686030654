/* import __COLOCATED_TEMPLATE__ from './tag-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { sort } from "@ember/object/computed";
import Category from "discourse/models/category";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./tag-list.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNameBindings: [":tags-list", ":tag-list", "categoryClass", "tagGroupNameClass"],
  isPrivateMessage: false,
  sortedTags: sort("tags", "sortProperties"),
  title(titleKey) {
    return titleKey && I18n.t(titleKey);
  },
  category(categoryId) {
    return categoryId && Category.findById(categoryId);
  },
  categoryClass(slug) {
    return slug && `tag-list-${slug}`;
  },
  tagGroupNameClass(groupName) {
    if (groupName) {
      groupName = groupName.replace(/\s+/g, "-").replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "").toLowerCase();
      return groupName && `tag-group-${groupName}`;
    }
  }
}, [["method", "title", [discourseComputed("titleKey")]], ["method", "category", [discourseComputed("categoryId")]], ["method", "categoryClass", [discourseComputed("category.fullSlug")]], ["method", "tagGroupNameClass", [discourseComputed("tagGroupName")]]])));
/* import __COLOCATED_TEMPLATE__ from './second-factor-add-totp.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { MAX_SECOND_FACTOR_NAME_LENGTH, SECOND_FACTOR_METHODS } from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-add-totp.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SecondFactorAddTotp extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "secondFactorImage", [tracked]))();
  #secondFactorImage = (() => (dt7948.i(this, "secondFactorImage"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "secondFactorKey", [tracked]))();
  #secondFactorKey = (() => (dt7948.i(this, "secondFactorKey"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "showSecondFactorKey", [tracked], function () {
    return false;
  }))();
  #showSecondFactorKey = (() => (dt7948.i(this, "showSecondFactorKey"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "errorMessage", [tracked]))();
  #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "secondFactorToken", [tracked]))();
  #secondFactorToken = (() => (dt7948.i(this, "secondFactorToken"), void 0))();
  maxSecondFactorNameLength = (() => MAX_SECOND_FACTOR_NAME_LENGTH)();
  totpType = (() => SECOND_FACTOR_METHODS.TOTP)();
  totpRequested() {
    this.args.model.secondFactor.createSecondFactorTotp().then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.errorMessage = null;
      this.secondFactorKey = response.key;
      this.secondFactorImage = response.qr;
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => this.loading = false);
  }
  static #_7 = (() => dt7948.n(this.prototype, "totpRequested", [action]))();
  enableShowSecondFactorKey(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.showSecondFactorKey = true;
  }
  static #_8 = (() => dt7948.n(this.prototype, "enableShowSecondFactorKey", [action]))();
  enableSecondFactor() {
    if (!this.secondFactorToken || !this.secondFactorName) {
      this.errorMessage = I18n.t("user.second_factor.totp.name_and_code_required_error");
      return;
    }
    this.loading = true;
    this.args.model.secondFactor.enableSecondFactorTotp(this.secondFactorToken, this.secondFactorName).then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.args.model.markDirty();
      this.errorMessage = null;
      this.args.closeModal();
      if (this.args.model.enforcedSecondFactor) {
        window.location.reload();
      }
    }).catch(error => this.args.model.onError(error)).finally(() => this.loading = false);
  }
  static #_9 = (() => dt7948.n(this.prototype, "enableSecondFactor", [action]))();
}
setComponentTemplate(TEMPLATE, SecondFactorAddTotp);
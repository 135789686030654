import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ADMIN_NAV_MAP } from "discourse/lib/sidebar/admin-nav-map";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ToggleAllSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "keyValueStore", [service]))();
  #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
  get allSectionsExpanded() {
    return ADMIN_NAV_MAP.every(adminNav1 => {
      return !this.sidebarState.collapsedSections.has(`sidebar-section-${this.sidebarState.currentPanel.key}-${adminNav1.name}-collapsed`);
    });
  }
  get title() {
    return this.allSectionsExpanded ? "admin.collapse_all_sections" : "admin.expand_all_sections";
  }
  get icon() {
    return this.allSectionsExpanded ? "angle-double-up" : "angle-double-down";
  }
  toggleAllSections() {
    const collapse1 = this.allSectionsExpanded;
    ADMIN_NAV_MAP.forEach(adminNav1 => {
      const key1 = `${this.sidebarState.currentPanel.key}-${adminNav1.name}`;
      if (collapse1) {
        this.sidebarState.collapseSection(key1);
      } else {
        this.sidebarState.expandSection(key1);
      }
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "toggleAllSections", [action]))();
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DButton
        @action={{this.toggleAllSections}}
        @icon={{this.icon}}
        @title={{this.title}}
        class="btn-transparent sidebar-toggle-all-sections"
      />
    
  */
  {
    "id": "PgR1VP+Z",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-transparent sidebar-toggle-all-sections\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleAllSections\"]],[30,0,[\"icon\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/toggle-all-sections.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this))();
}
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class SignupRoute extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  beforeModel() {
    this.showCreateAccount();
  }
  async showCreateAccount() {
    const {
      canSignUp
    } = this.controllerFor("application");
    const route = await this.router.replaceWith(this.siteSettings.login_required ? "login" : "discovery.latest").followRedirects();
    if (canSignUp) {
      next(() => route.send("showCreateAccount"));
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "showCreateAccount", [action]))();
}
/* import __COLOCATED_TEMPLATE__ from './popup-input-tip.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { not, or, reads } from "@ember/object/computed";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./popup-input-tip.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  composer: service(),
  tagName: "a",
  classNameBindings: [":popup-tip", "good", "bad", "lastShownAt::hide"],
  attributeBindings: ["role", "ariaLabel", "tabindex"],
  tipReason: null,
  lastShownAt: or("shownAt", "validation.lastShownAt"),
  bad: reads("validation.failed"),
  good: not("bad"),
  tabindex: "0",
  role(bad) {
    if (bad) {
      return "alert";
    }
  },
  ariaLabel(reason) {
    return reason?.replace(/(<([^>]+)>)/gi, "");
  },
  dismiss() {
    this.set("shownAt", null);
    this.composer.clearLastValidatedAt();
    this.element.previousElementSibling?.focus();
  },
  click() {
    this.dismiss();
  },
  keyDown(event) {
    if (event.key === "Enter") {
      this.dismiss();
    }
  },
  didReceiveAttrs() {
    this._super(...arguments);
    let reason = this.get("validation.reason");
    if (reason) {
      this.set("tipReason", htmlSafe(`${reason}`));
    } else {
      this.set("tipReason", null);
    }
  }
}, [["method", "role", [discourseComputed("bad")]], ["method", "ariaLabel", [discourseComputed("validation.reason")]]])));
import { gt, not } from "@ember/object/computed";
import { propertyEqual } from "discourse/lib/computed";
import RestModel from "discourse/models/rest";
export default class Archetype extends RestModel {
  static #_ = (() => dt7948.g(this.prototype, "hasOptions", [gt("options.length", 0)]))();
  #hasOptions = (() => (dt7948.i(this, "hasOptions"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "isDefault", [propertyEqual("id", "site.default_archetype")]))();
  #isDefault = (() => (dt7948.i(this, "isDefault"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "notDefault", [not("isDefault")]))();
  #notDefault = (() => (dt7948.i(this, "notDefault"), void 0))();
}
/* import __COLOCATED_TEMPLATE__ from './username-preference.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { empty, or } from "@ember/object/computed";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { setting } from "discourse/lib/computed";
import DiscourseURL, { userPath } from "discourse/lib/url";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./username-preference.hbs";
import { setComponentTemplate } from "@ember/component";
export default class UsernamePreference extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "editing", [tracked], function () {
    return false;
  }))();
  #editing = (() => (dt7948.i(this, "editing"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "newUsername", [tracked], function () {
    return this.args.user.username;
  }))();
  #newUsername = (() => (dt7948.i(this, "newUsername"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "errorMessage", [tracked], function () {
    return null;
  }))();
  #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  }))();
  #saving = (() => (dt7948.i(this, "saving"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "taken", [tracked], function () {
    return false;
  }))();
  #taken = (() => (dt7948.i(this, "taken"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "maxLength", [setting("max_username_length")]))();
  #maxLength = (() => (dt7948.i(this, "maxLength"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "minLength", [setting("min_username_length")]))();
  #minLength = (() => (dt7948.i(this, "minLength"), void 0))();
  static #_10 = (() => dt7948.g(this.prototype, "newUsernameEmpty", [empty("newUsername")]))();
  #newUsernameEmpty = (() => (dt7948.i(this, "newUsernameEmpty"), void 0))();
  static #_11 = (() => dt7948.g(this.prototype, "saveDisabled", [or("saving", "newUsernameEmpty", "taken", "unchanged", "errorMessage")]))();
  #saveDisabled = (() => (dt7948.i(this, "saveDisabled"), void 0))();
  get unchanged() {
    return this.newUsername === this.args.user.username;
  }
  get saveButtonText() {
    return this.saving ? I18n.t("saving") : I18n.t("user.change");
  }
  toggleEditing() {
    this.editing = !this.editing;
    this.newUsername = this.args.user.username;
    this.errorMessage = null;
    this.saving = false;
    this.taken = false;
  }
  static #_12 = (() => dt7948.n(this.prototype, "toggleEditing", [action]))();
  async onInput(event) {
    this.newUsername = event.target.value;
    this.taken = false;
    this.errorMessage = null;
    if (isEmpty(this.newUsername)) {
      return;
    }
    if (this.newUsername === this.args.user.username) {
      return;
    }
    if (this.newUsername.length < this.minLength) {
      this.errorMessage = I18n.t("user.name.too_short");
      return;
    }
    const result = await User.checkUsername(this.newUsername, undefined, this.args.user.id);
    if (result.errors) {
      this.errorMessage = result.errors.join(" ");
    } else if (result.available === false) {
      this.taken = true;
    }
  }
  static #_13 = (() => dt7948.n(this.prototype, "onInput", [action]))();
  changeUsername() {
    return this.dialog.yesNoConfirm({
      title: I18n.t("user.change_username.confirm"),
      didConfirm: async () => {
        this.saving = true;
        try {
          await this.args.user.changeUsername(this.newUsername);
          DiscourseURL.redirectTo(userPath(this.newUsername.toLowerCase() + "/preferences"));
        } catch (e) {
          popupAjaxError(e);
        } finally {
          this.saving = false;
        }
      }
    });
  }
  static #_14 = (() => dt7948.n(this.prototype, "changeUsername", [action]))();
}
setComponentTemplate(TEMPLATE, UsernamePreference);
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import ForgotPassword from "discourse/components/modal/forgot-password";
import { defaultHomepage } from "discourse/lib/utilities";
import DiscourseRoute from "discourse/routes/discourse";
export default class ForgotPasswordRoute extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  async beforeModel() {
    const {
      loginRequired
    } = this.controllerFor("application");
    await this.router.replaceWith(loginRequired ? "login" : `discovery.${defaultHomepage()}`);
    next(() => this.modal.show(ForgotPassword));
  }
}
/* import __COLOCATED_TEMPLATE__ from './categories-boxes-with-topics.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes-with-topics.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "section",
  classNameBindings: [":category-boxes-with-topics", "anyLogos:with-logos:no-logos"],
  lockIcon: "lock",
  anyLogos() {
    return this.categories.any(c => {
      return !isEmpty(c.get("uploaded_logo.url"));
    });
  }
}, [["method", "anyLogos", [discourseComputed("categories.[].uploaded_logo.url")]]])));
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  model(params) {
    return this.modelFor("user").get("groups").find(group => {
      return group.name.toLowerCase() === params.name.toLowerCase();
    });
  }
  afterModel(model) {
    if (!model) {
      this.router.transitionTo("exception-unknown");
      return;
    }
  }
  setupController(controller, model) {
    controller.set("group", model);
  }
}
/* import __COLOCATED_TEMPLATE__ from './categories-boxes-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "li",
  attributeBindings: ["topic.id:data-topic-id"],
  topicStatusIcon(pinned, closed, archived) {
    if (pinned) {
      return "thumbtack";
    }
    if (closed || archived) {
      return "lock";
    }
    return "far-file-alt";
  }
}, [["method", "topicStatusIcon", [discourseComputed("topic.pinned", "topic.closed", "topic.archived")]]])));
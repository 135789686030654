import Component from "@glimmer/component";
import { service } from "@ember/service";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import TagsSection from "./tags-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarAnonymousSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="sidebar-sections sidebar-sections-anonymous">
        <CustomSections @collapsable={{@collapsableSections}} />
        <CategoriesSection @collapsable={{@collapsableSections}} />
  
        {{#if this.siteSettings.tagging_enabled}}
          <TagsSection @collapsable={{@collapsableSections}} />
        {{/if}}
      </div>
    
  */
  {
    "id": "zogYd21w",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-sections sidebar-sections-anonymous\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/anonymous/sections.js",
    "scope": () => [CustomSections, CategoriesSection, TagsSection],
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import concatClass from "discourse/helpers/concat-class";
import TopicStatusIcons from "discourse/helpers/topic-status-icons";
import { escapeExpression } from "discourse/lib/utilities";
import icon from "discourse-common/helpers/d-icon";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class Status extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  get canAct() {
    return this.currentUser && !this.args.disableActions;
  }
  get topicStatuses() {
    let topicStatuses1 = [];
    TopicStatusIcons.render(this.args.topic, (name1, key1) => {
      const iconArgs1 = {
        class: key1 === "unpinned" ? "unpinned" : null
      };
      const statusIcon1 = {
        name: name1,
        iconArgs: iconArgs1
      };
      const attributes1 = {
        title: escapeExpression(I18n.t(`topic_statuses.${key1}.help`))
      };
      let klass1 = ["topic-status"];
      if (key1 === "unpinned" || key1 === "pinned") {
        klass1.push("pin-toggle-button", key1);
        klass1 = klass1.join(" ");
      }
      topicStatuses1.push({
        attributes: attributes1,
        klass: klass1,
        icon: statusIcon1
      });
    });
    return topicStatuses1;
  }
  togglePinnedForUser(e1) {
    if (!this.canAct) {
      return;
    }
    const parent1 = e1.target.closest(".topic-statuses");
    if (parent1?.querySelector(".pin-toggle-button")?.contains(e1.target)) {
      this.args.topic.togglePinnedForUser();
    }
  }
  static #_2 = (() => dt7948.n(this.prototype, "togglePinnedForUser", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <span class="topic-statuses">
        {{#each this.topicStatuses as |status|}}
          {{! template-lint-disable no-invalid-interactive }}
          <span
            class={{concatClass status.klass "topic-status"}}
            {{on "click" this.togglePinnedForUser}}
          >
            {{icon status.icon.name class=status.icon.iconArgs.class}}
          </span>
        {{/each}}
      </span>
    
  */
  {
    "id": "O2uTew4m",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic-statuses\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"topicStatuses\"]]],null]],null],null,[[[1,\"        \"],[11,1],[16,0,[28,[32,0],[[30,1,[\"klass\"]],\"topic-status\"],null]],[4,[32,1],[\"click\",[30,0,[\"togglePinnedForUser\"]]],null],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,1,[\"icon\",\"name\"]]],[[\"class\"],[[30,1,[\"icon\",\"iconArgs\",\"class\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"status\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/topic/status.js",
    "scope": () => [concatClass, on, icon],
    "isStrictMode": true
  }), this))();
}
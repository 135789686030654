/* import __COLOCATED_TEMPLATE__ from './top-period-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./top-period-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["top-title-buttons"],
  periods(period) {
    return this.site.get("periods").filter(p => p !== period);
  },
  changePeriod(p) {
    this.action(p);
  }
}, [["method", "periods", [discourseComputed("period")]], ["method", "changePeriod", [action]]])));
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import { confirmNotification, context } from "discourse/lib/desktop-notifications";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import KeyValueStore from "discourse/lib/key-value-store";
import { isPushNotificationsSupported, keyValueStore as pushNotificationKeyValueStore, subscribe as subscribePushNotification, unsubscribe as unsubscribePushNotification, userSubscriptionKey as pushNotificationUserSubscriptionKey } from "discourse/lib/push-notifications";
const keyValueStore = new KeyValueStore(context);
const DISABLED = "disabled";
const ENABLED = "enabled";
const DesktopNotificationsService = dt7948.c(class DesktopNotificationsService extends Service {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "notificationsDisabled", [tracked]))();
  #notificationsDisabled = (() => (dt7948.i(this, "notificationsDisabled"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "isEnabledPush", [tracked]))();
  #isEnabledPush = (() => (dt7948.i(this, "isEnabledPush"), void 0))();
  constructor() {
    super(...arguments);
    this.notificationsDisabled = keyValueStore.getItem("notifications-disabled") === DISABLED;
    this.isEnabledPush = this.currentUser ? pushNotificationKeyValueStore.getItem(pushNotificationUserSubscriptionKey(this.currentUser)) : false;
  }
  get isNotSupported() {
    return typeof window.Notification === "undefined";
  }
  get notificationsPermission() {
    return this.isNotSupported ? "" : Notification.permission;
  }
  setNotificationsDisabled(value) {
    keyValueStore.setItem("notifications-disabled", value);
    this.notificationsDisabled = value === DISABLED;
  }
  get isDeniedPermission() {
    if (this.isNotSupported) {
      return false;
    }
    return this.notificationsPermission === "denied";
  }
  get isGrantedPermission() {
    if (this.isNotSupported) {
      return false;
    }
    return this.notificationsPermission === "granted";
  }
  get isEnabledDesktop() {
    if (this.isGrantedPermission) {
      return !this.notificationsDisabled;
    }
    return false;
  }
  setIsEnabledPush(value) {
    const user = this.currentUser;
    if (!user) {
      return false;
    }
    pushNotificationKeyValueStore.setItem(pushNotificationUserSubscriptionKey(user), value);
    this.isEnabledPush = pushNotificationKeyValueStore.getItem(pushNotificationUserSubscriptionKey(user));
  }
  get isEnabled() {
    return this.isEnabledDesktop || this.isEnabledPush;
  }
  get isSubscribed() {
    if (!this.isEnabled) {
      return false;
    }
    if (this.isPushNotificationsPreferred) {
      return this.isEnabledPush === "subscribed";
    } else {
      return !this.notificationsDisabled;
    }
  }
  get isPushNotificationsPreferred() {
    return (this.site.mobileView || this.siteSettings.enable_desktop_push_notifications) && isPushNotificationsSupported();
  }
  async disable() {
    if (this.isEnabledDesktop) {
      this.setNotificationsDisabled(DISABLED);
    }
    if (this.isEnabledPush) {
      await unsubscribePushNotification(this.currentUser, () => {
        this.setIsEnabledPush("");
      });
    }
    return true;
  }
  static #_6 = (() => dt7948.n(this.prototype, "disable", [action]))();
  enable() {
    if (this.isPushNotificationsPreferred) {
      return subscribePushNotification(() => {
        this.setIsEnabledPush("subscribed");
      }, this.siteSettings.vapid_public_key_bytes);
    } else {
      this.setNotificationsDisabled(ENABLED);
      return Notification.requestPermission(permission => {
        confirmNotification(this.siteSettings);
        return permission === "granted";
      });
    }
  }
  static #_7 = (() => dt7948.n(this.prototype, "enable", [action]))();
}, [disableImplicitInjections]);
export default DesktopNotificationsService;
/* import __COLOCATED_TEMPLATE__ from './group-manage-logs-filter.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-manage-logs-filter.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  label(type) {
    return I18n.t(`groups.manage.logs.${type}`);
  },
  filterText(value, type) {
    return type === "action" ? I18n.t(`group_histories.actions.${value}`) : value;
  }
}, [["method", "label", [discourseComputed("type")]], ["method", "filterText", [discourseComputed("value", "type")]]])));
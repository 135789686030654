import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import SummaryBox from "discourse/components/summary-box";
import PrivateMessageMap from "discourse/components/topic-map/private-message-map";
import TopicMapExpanded from "discourse/components/topic-map/topic-map-expanded";
import TopicMapSummary from "discourse/components/topic-map/topic-map-summary";
import concatClass from "discourse/helpers/concat-class";
import or from "truth-helpers/helpers/or";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicMap extends Component {
  static #_ = (() => dt7948.g(this.prototype, "collapsed", [tracked], function () {
    return !this.args.model.has_summary;
  }))();
  #collapsed = (() => (dt7948.i(this, "collapsed"), void 0))();
  get userFilters() {
    return this.args.postStream.userFilters || [];
  }
  toggleMap() {
    this.collapsed = !this.collapsed;
  }
  static #_2 = (() => dt7948.n(this.prototype, "toggleMap", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <section class={{concatClass "map" (if this.collapsed "map-collapsed")}}>
        <TopicMapSummary
          @topic={{@model}}
          @topicDetails={{@topicDetails}}
          @toggleMap={{this.toggleMap}}
          @collapsed={{this.collapsed}}
          @userFilters={{this.userFilters}}
        />
      </section>
      {{#unless this.collapsed}}
        <section
          class="topic-map-expanded"
          id="topic-map-expanded__aria-controls"
        >
          <TopicMapExpanded
            @topicDetails={{@topicDetails}}
            @userFilters={{this.userFilters}}
          />
        </section>
      {{/unless}}
      {{#if (or @model.has_summary @model.summarizable)}}
        <section class="information toggle-summary">
          <SummaryBox
            @topic={{@model}}
            @postStream={{@postStream}}
            @cancelFilter={{@cancelFilter}}
            @showTopReplies={{@showTopReplies}}
            @collapseSummary={{@collapseSummary}}
            @showSummary={{@showSummary}}
          />
        </section>
      {{/if}}
      {{#if @showPMMap}}
        <section class="information private-message-map">
          <PrivateMessageMap
            @topicDetails={{@topicDetails}}
            @showInvite={{@showInvite}}
            @removeAllowedGroup={{@removeAllowedGroup}}
            @removeAllowedUser={{@removeAllowedUser}}
          />
        </section>
      {{/if}}
    
  */
  {
    "id": "Pgn89eVe",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[15,0,[28,[32,0],[\"map\",[52,[30,0,[\"collapsed\"]],\"map-collapsed\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@topic\",\"@topicDetails\",\"@toggleMap\",\"@collapsed\",\"@userFilters\"],[[30,1],[30,2],[30,0,[\"toggleMap\"]],[30,0,[\"collapsed\"]],[30,0,[\"userFilters\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"collapsed\"]]],[[[1,\"      \"],[10,\"section\"],[14,0,\"topic-map-expanded\"],[14,1,\"topic-map-expanded__aria-controls\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@topicDetails\",\"@userFilters\"],[[30,2],[30,0,[\"userFilters\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[32,3],[[30,1,[\"has_summary\"]],[30,1,[\"summarizable\"]]],null],[[[1,\"      \"],[10,\"section\"],[14,0,\"information toggle-summary\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@topic\",\"@postStream\",\"@cancelFilter\",\"@showTopReplies\",\"@collapseSummary\",\"@showSummary\"],[[30,1],[30,3],[30,4],[30,5],[30,6],[30,7]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,8],[[[1,\"      \"],[10,\"section\"],[14,0,\"information private-message-map\"],[12],[1,\"\\n        \"],[8,[32,5],null,[[\"@topicDetails\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],[[30,2],[30,9],[30,10],[30,11]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\",\"@topicDetails\",\"@postStream\",\"@cancelFilter\",\"@showTopReplies\",\"@collapseSummary\",\"@showSummary\",\"@showPMMap\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map.js",
    "scope": () => [concatClass, TopicMapSummary, TopicMapExpanded, or, SummaryBox, PrivateMessageMap],
    "isStrictMode": true
  }), this))();
}
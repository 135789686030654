import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class UserNotificationsIndex extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  controllerName = "user-notifications";
  templateName = "user/notifications-index";
  titleToken() {
    return I18n.t("user.filters.all");
  }
  afterModel(model) {
    if (!model) {
      this.router.transitionTo("userNotifications.responses");
    }
  }
}
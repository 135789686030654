/* import __COLOCATED_TEMPLATE__ from './reviewable-post-edits.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { gt } from "@ember/object/computed";
import { service } from "@ember/service";
import HistoryModal from "discourse/components/modal/history";
import { longDate } from "discourse/lib/formatter";
import { historyHeat } from "discourse/widgets/post-edits-indicator";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-post-edits.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  modal: service(),
  hasEdits: gt("reviewable.post_version", 1),
  historyClass(updatedAt) {
    return historyHeat(this.siteSettings, new Date(updatedAt));
  },
  editedDate(updatedAt) {
    return longDate(updatedAt);
  },
  showEditHistory(event) {
    event?.preventDefault();
    let postId = this.get("reviewable.post_id");
    this.store.find("post", postId).then(post => {
      this.modal.show(HistoryModal, {
        model: {
          post,
          postId,
          postVersion: "latest",
          topicController: null
        }
      });
    });
  }
}, [["method", "historyClass", [discourseComputed("reviewable.post_updated_at")]], ["method", "editedDate", [discourseComputed("reviewable.post_updated_at")]], ["method", "showEditHistory", [action]]])));
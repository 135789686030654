/* import __COLOCATED_TEMPLATE__ from './groups-info.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./groups-info.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "span",
  classNames: ["group-info-details"],
  showFullName(fullName) {
    return fullName && fullName.length;
  }
}, [["method", "showFullName", [discourseComputed("group.full_name")]]])));
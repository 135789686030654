import Component from "@glimmer/component";
import { service } from "@ember/service";
import AdminHeader from "./admin-header";
import ApiSection from "./api-section";
import FilterNoResults from "./filter-no-results";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarApiSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get sections() {
    if (this.sidebarState.combinedMode) {
      return this.sidebarState.panels.filter(panel1 => !panel1.hidden).flatMap(panel1 => panel1.sections);
    } else {
      return this.sidebarState.currentPanel.sections;
    }
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <AdminHeader />
  
      {{#each this.sections as |sectionConfig|}}
        <ApiSection
          @sectionConfig={{sectionConfig}}
          @collapsable={{@collapsable}}
        />
      {{/each}}
  
      <FilterNoResults />
    
  */
  {
    "id": "HX98Zixl",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@sectionConfig\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n    \"],[8,[32,2],null,null,null],[1,\"\\n  \"]],[\"sectionConfig\",\"@collapsable\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-sections.js",
    "scope": () => [AdminHeader, ApiSection, FilterNoResults],
    "isStrictMode": true
  }), this))();
}
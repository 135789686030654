/* import __COLOCATED_TEMPLATE__ from './create-invite-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./create-invite-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, dt7948.p({
  id: "create-invite-uploader",
  tagName: "div",
  type: "csv",
  autoStartUploads: false,
  uploadUrl: "/invites/upload_csv",
  preventDirectS3Uploads: true,
  fileInputSelector: "#csv-file",
  validateUploadedFilesOptions() {
    return {
      bypassNewUserRestriction: true,
      csvOnly: true
    };
  },
  submitDisabled(filesAwaitingUpload, uploading) {
    return !filesAwaitingUpload || uploading;
  },
  uploadDone() {
    this.set("uploaded", true);
  },
  startUpload() {
    this._startUpload();
  },
  setElement(element) {
    this.set("fileInputEl", element);
    this._initialize();
  }
}, [["method", "submitDisabled", [discourseComputed("filesAwaitingUpload", "uploading")]], ["method", "startUpload", [action]], ["method", "setElement", [action]]])));
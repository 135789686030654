/* import __COLOCATED_TEMPLATE__ from './customize-form-template-view.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import TEMPLATE from "./customize-form-template-view.hbs";
import { setComponentTemplate } from "@ember/component";
export default class CustomizeFormTemplateViewModal extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "showPreview", [tracked], function () {
    return false;
  }))();
  #showPreview = (() => (dt7948.i(this, "showPreview"), void 0))();
  togglePreview() {
    this.showPreview = !this.showPreview;
  }
  static #_4 = (() => dt7948.n(this.prototype, "togglePreview", [action]))();
  editTemplate() {
    this.router.transitionTo("adminCustomizeFormTemplates.edit", this.args.model);
  }
  static #_5 = (() => dt7948.n(this.prototype, "editTemplate", [action]))();
  deleteTemplate() {
    return this.dialog.yesNoConfirm({
      message: I18n.t("admin.form_templates.delete_confirm", {
        template_name: this.args.model.name
      }),
      didConfirm: () => {
        ajax(`/admin/customize/form-templates/${this.args.model.id}.json`, {
          type: "DELETE"
        }).then(() => {
          this.args.refreshModel();
        }).catch(popupAjaxError);
      }
    });
  }
  static #_6 = (() => dt7948.n(this.prototype, "deleteTemplate", [action]))();
}
setComponentTemplate(TEMPLATE, CustomizeFormTemplateViewModal);
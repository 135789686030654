/* import __COLOCATED_TEMPLATE__ from './responsive-table.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./responsive-table.hbs";
export default class ResponsiveTable extends Component {
  static #_ = (() => dt7948.g(this.prototype, "lastScrollPosition", [tracked], function () {
    return 0;
  }))();
  #lastScrollPosition = (() => (dt7948.i(this, "lastScrollPosition"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "ticking", [tracked], function () {
    return false;
  }))();
  #ticking = (() => (dt7948.i(this, "ticking"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "_table", [tracked], function () {
    return document.querySelector(".directory-table");
  }))();
  #_table = (() => (dt7948.i(this, "_table"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "_topHorizontalScrollBar", [tracked], function () {
    return document.querySelector(".directory-table-top-scroll");
  }))();
  #_topHorizontalScrollBar = (() => (dt7948.i(this, "_topHorizontalScrollBar"), void 0))();
  checkScroll() {
    const _fakeScrollContent = document.querySelector(".directory-table-top-scroll-fake-content");
    if (this._table.getBoundingClientRect().bottom < window.innerHeight) {
      // Bottom of the table is visible. Hide the scrollbar
      _fakeScrollContent.style.height = 0;
    } else {
      _fakeScrollContent.style.width = `${this._table.scrollWidth}px`;
      _fakeScrollContent.style.height = "1px";
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "checkScroll", [bind]))();
  onTopScroll() {
    this.onHorizontalScroll(this._topHorizontalScrollBar, this._table);
  }
  static #_6 = (() => dt7948.n(this.prototype, "onTopScroll", [bind]))();
  onBottomScroll() {
    this.onHorizontalScroll(this._table, this._topHorizontalScrollBar);
  }
  static #_7 = (() => dt7948.n(this.prototype, "onBottomScroll", [bind]))();
  onHorizontalScroll(primary, replica) {
    this.set("lastScrollPosition", primary?.scrollLeft);
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        replica.scrollLeft = this.lastScrollPosition;
        this.set("ticking", false);
      });
      this.set("ticking", true);
    }
  }
  static #_8 = (() => dt7948.n(this.prototype, "onHorizontalScroll", [bind]))();
}
setComponentTemplate(TEMPLATE, ResponsiveTable);
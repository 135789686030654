import Component from "@glimmer/component";
import { service } from "@ember/service";
import ApiSections from "./api-sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarApiPanels extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get panelCssClass() {
    return `${this.sidebarState.currentPanel.key}-panel`;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="sidebar-sections {{this.panelCssClass}}">
        <ApiSections @collapsable={{@collapsableSections}} />
      </div>
    
  */
  {
    "id": "V3dt+4He",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"sidebar-sections \",[30,0,[\"panelCssClass\"]]]]],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-panels.js",
    "scope": () => [ApiSections],
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './group-member.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-member.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["item"],
  remove(event) {
    event?.preventDefault();
    this.removeAction(this.member);
  }
}, [["method", "remove", [action]]])));
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Input, setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ajax } from "discourse/lib/ajax";
import { extractError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import { createTemplateFactory } from "@ember/template-factory";
export default class RenamePasskey extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "passkeyName", [tracked]))();
  #passkeyName = (() => (dt7948.i(this, "passkeyName"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "errorMessage", [tracked]))();
  #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
  instructions = (() => I18n.t("user.passkeys.rename_passkey_instructions"))();
  constructor() {
    super(...arguments);
    this.passkeyName = this.args.model.name;
  }
  async saveRename() {
    try {
      await ajax(`/u/rename_passkey/${this.args.model.id}`, {
        type: "PUT",
        data: {
          name: this.passkeyName
        }
      });
      this.errorMessage = null;
      this.router.refresh();
      this.dialog.didConfirmWrapped();
    } catch (error1) {
      this.errorMessage = extractError(error1);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "saveRename", [action]))();
  static #_6 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.errorMessage}}
        <div class="alert alert-error">
          {{this.errorMessage}}
        </div>
      {{/if}}
  
      <div class="rename-passkey__form">
        <div class="rename-passkey__message">
          <p>{{this.instructions}}</p>
        </div>
        <form>
          <div class="rename-passkey__form inline-form">
            <Input @value={{this.passkeyName}} autofocus={{true}} @type="text" />
            <DButton
              class="btn-primary"
              @type="submit"
              @action={{this.saveRename}}
              @label="user.passkeys.save"
            />
          </div>
        </form>
      </div>
    
  */
  {
    "id": "RNNTbKZI",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n        \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"rename-passkey__form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"rename-passkey__message\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[30,0,[\"instructions\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"rename-passkey__form inline-form\"],[12],[1,\"\\n          \"],[8,[32,0],[[16,\"autofocus\",true]],[[\"@value\",\"@type\"],[[30,0,[\"passkeyName\"]],\"text\"]],null],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@type\",\"@action\",\"@label\"],[\"submit\",[30,0,[\"saveRename\"]],\"user.passkeys.save\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-preferences/rename-passkey.js",
    "scope": () => [Input, DButton],
    "isStrictMode": true
  }), this))();
}
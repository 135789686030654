import Component from "@glimmer/component";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import { or } from "truth-helpers";
import DeferredRender from "discourse/components/deferred-render";
import ApiPanels from "./api-panels";
import Footer from "./footer";
import Sections from "./sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarHamburgerDropdown extends Component {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  triggerRenderedAppEvent() {
    this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
  }
  static #_6 = (() => dt7948.n(this.prototype, "triggerRenderedAppEvent", [action]))();
  get collapsableSections() {
    if (this.siteSettings.navigation_menu === "header dropdown" && !this.args.collapsableSections) {
      return this.site.mobileView || this.site.narrowDesktopView;
    } else {
      this.args.collapsableSections;
    }
  }
  static #_7 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="hamburger-panel">
        <div
          {{didInsert this.triggerRenderedAppEvent}}
          data-max-width="320"
          class="revamped menu-panel drop-down"
        >
          <div class="panel-body">
            <div class="panel-body-contents">
              <DeferredRender>
                <div class="sidebar-hamburger-dropdown">
                  {{#if
                    (or this.sidebarState.showMainPanel @forceMainSidebarPanel)
                  }}
                    <Sections
                      @currentUser={{this.currentUser}}
                      @collapsableSections={{this.collapsableSections}}
                      @panel={{this.sidebarState.currentPanel}}
                      @hideApiSections={{@forceMainSidebarPanel}}
                    />
                  {{else}}
                    <ApiPanels
                      @currentUser={{this.currentUser}}
                      @collapsableSections={{this.collapsableSections}}
                    />
                  {{/if}}
                  <Footer />
                </div>
              </DeferredRender>
            </div>
          </div>
        </div>
      </div>
    
  */
  {
    "id": "e1y0x+Gs",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"hamburger-panel\"],[12],[1,\"\\n      \"],[11,0],[24,\"data-max-width\",\"320\"],[24,0,\"revamped menu-panel drop-down\"],[4,[32,0],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n            \"],[8,[32,1],null,null,[[\"default\"],[[[[1,\"\\n              \"],[10,0],[14,0,\"sidebar-hamburger-dropdown\"],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,0,[\"sidebarState\",\"showMainPanel\"]],[30,1]],null],[[[1,\"                  \"],[8,[32,3],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\",\"@hideApiSections\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]],[30,0,[\"sidebarState\",\"currentPanel\"]],[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"                  \"],[8,[32,4],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]]]],null],[1,\"\\n\"]],[]]],[1,\"                \"],[8,[32,5],null,null,null],[1,\"\\n              \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@forceMainSidebarPanel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/hamburger-dropdown.js",
    "scope": () => [didInsert, DeferredRender, or, Sections, ApiPanels, Footer],
    "isStrictMode": true
  }), this))();
}
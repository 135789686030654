/* import __COLOCATED_TEMPLATE__ from './category-read-only-banner.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { and } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./category-read-only-banner.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  user() {
    return this.currentUser;
  },
  shouldShow: and("category.read_only_banner", "readOnly", "user")
}, [["method", "user", [discourseComputed]]])));
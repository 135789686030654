/* import __COLOCATED_TEMPLATE__ from './google-search.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./google-search.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["google-search-form"],
  classNameBindings: ["hidden:hidden"],
  hidden: alias("siteSettings.login_required"),
  siteUrl() {
    return `${location.protocol}//${location.host}${getURL("/")}`;
  }
}, [["method", "siteUrl", [discourseComputed]]])));
/* import __COLOCATED_TEMPLATE__ from './revise-and-reject-post-reviewable.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import TEMPLATE from "./revise-and-reject-post-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
const OTHER_REASON = "other_reason";
export default class ReviseAndRejectPostReviewable extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "reason", [tracked]))();
  #reason = (() => (dt7948.i(this, "reason"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "customReason", [tracked]))();
  #customReason = (() => (dt7948.i(this, "customReason"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "feedback", [tracked]))();
  #feedback = (() => (dt7948.i(this, "feedback"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "submitting", [tracked], function () {
    return false;
  }))();
  #submitting = (() => (dt7948.i(this, "submitting"), void 0))();
  get configuredReasons() {
    const reasons = this.siteSettings.reviewable_revision_reasons.split("|").filter(Boolean).map(reason => ({
      id: reason,
      name: reason
    })).concat([{
      id: OTHER_REASON,
      name: I18n.t("review.revise_and_reject_post.other_reason")
    }]);
    return reasons;
  }
  get showCustomReason() {
    return this.reason === OTHER_REASON;
  }
  get sendPMDisabled() {
    return isEmpty(this.reason) || this.reason === OTHER_REASON && isEmpty(this.customReason) || this.submitting;
  }
  async rejectAndSendPM() {
    this.submitting = true;
    try {
      await this.args.model.performConfirmed(this.args.model.action, {
        revise_reason: this.reason,
        revise_custom_reason: this.customReason,
        revise_feedback: this.feedback
      });
      this.args.closeModal();
    } catch (error) {
      popupAjaxError(error);
    } finally {
      this.submitting = false;
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "rejectAndSendPM", [action]))();
}
setComponentTemplate(TEMPLATE, ReviseAndRejectPostReviewable);
import Controller from "@ember/controller";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(dt7948.p({
  pendingLabel(invitesCountTotal, invitesCountPending) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.pending_tab_with_count", {
        count: invitesCountPending
      });
    } else {
      return I18n.t("user.invited.pending_tab");
    }
  },
  expiredLabel(invitesCountTotal, invitesCountExpired) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.expired_tab_with_count", {
        count: invitesCountExpired
      });
    } else {
      return I18n.t("user.invited.expired_tab");
    }
  },
  redeemedLabel(invitesCountTotal, invitesCountRedeemed) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.redeemed_tab_with_count", {
        count: invitesCountRedeemed
      });
    } else {
      return I18n.t("user.invited.redeemed_tab");
    }
  }
}, [["method", "pendingLabel", [discourseComputed("invitesCount.total", "invitesCount.pending")]], ["method", "expiredLabel", [discourseComputed("invitesCount.total", "invitesCount.expired")]], ["method", "redeemedLabel", [discourseComputed("invitesCount.total", "invitesCount.redeemed")]]]));
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action, computed, set } from "@ember/object";
import { and, equal, gt, not, or, readOnly } from "@ember/object/computed";
import { service } from "@ember/service";
import { dasherize } from "@ember/string";
import { isEmpty } from "@ember/utils";
import optionalService from "discourse/lib/optional-service";
import { prioritizeNameInUx } from "discourse/lib/settings";
import CanCheckEmails from "discourse/mixins/can-check-emails";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(CanCheckEmails, dt7948.p({
  currentUser: service(),
  router: service(),
  dialog: service(),
  userNotifications: controller("user-notifications"),
  adminTools: optionalService(),
  viewingSelf(username) {
    return this.currentUser && username === this.currentUser?.get("username");
  },
  canExpandProfile(viewingSelf, profileHidden) {
    return !profileHidden && viewingSelf;
  },
  hasProfileBackgroundUrl(background) {
    return !isEmpty(background.toString());
  },
  isSummaryRoute: equal("router.currentRouteName", "user.summary"),
  collapsedInfo(profileHidden, isSummaryRoute, viewingSelf, forceExpand) {
    if (profileHidden) {
      return true;
    }
    return (!isSummaryRoute || viewingSelf) && !forceExpand;
  },
  canMuteOrIgnoreUser: or("model.can_ignore_user", "model.can_mute_user"),
  hasGivenFlags: gt("model.number_of_flags_given", 0),
  hasFlaggedPosts: gt("model.number_of_flagged_posts", 0),
  hasDeletedPosts: gt("model.number_of_deleted_posts", 0),
  hasBeenSuspended: gt("model.number_of_suspensions", 0),
  hasReceivedWarnings: gt("model.warnings_received_count", 0),
  hasRejectedPosts: gt("model.number_of_rejected_posts", 0),
  collapsedInfoState: computed("collapsedInfo", function () {
    return {
      isExpanded: !this.collapsedInfo,
      icon: this.collapsedInfo ? "angle-double-down" : "angle-double-up",
      label: this.collapsedInfo ? "expand_profile" : "collapse_profile",
      ariaLabel: this.collapsedInfo ? "user.sr_expand_profile" : "user.sr_collapse_profile",
      action: this.collapsedInfo ? "expandProfile" : "collapseProfile"
    };
  }),
  isTrustLevelZero: equal("model.trust_level", 0),
  hasTrustLevel: or("isTrustLevelZero", "model.trust_level"),
  showStaffCounters: or("hasGivenFlags", "hasFlaggedPosts", "hasDeletedPosts", "hasBeenSuspended", "hasReceivedWarnings", "hasRejectedPosts"),
  showFeaturedTopic: and("model.featured_topic", "siteSettings.allow_featured_topic_on_user_profiles"),
  isNotSuspendedOrIsStaff(suspended, isStaff) {
    return !suspended || isStaff;
  },
  linkWebsite: not("model.isBasic"),
  removeNoFollow(trustLevel) {
    return trustLevel > 2 && !this.siteSettings.tl3_links_no_follow;
  },
  showBookmarks(viewingSelf, isAdmin) {
    return viewingSelf || isAdmin;
  },
  showDrafts(viewingSelf) {
    return viewingSelf;
  },
  showRead(viewingSelf) {
    return viewingSelf;
  },
  showPrivateMessages(viewingSelf, isAdmin) {
    return this.currentUser?.can_send_private_messages && (viewingSelf || isAdmin);
  },
  showActivityTab(viewingSelf, isAdmin) {
    return viewingSelf || isAdmin || !this.siteSettings.hide_user_activity_tab;
  },
  showNotificationsTab(viewingSelf, isAdmin) {
    return viewingSelf || isAdmin;
  },
  nameFirst(name) {
    return prioritizeNameInUx(name);
  },
  showBadges(badgeCount) {
    return this.siteSettings.enable_badges && badgeCount > 0;
  },
  canInviteToForum() {
    return this.currentUser?.get("can_invite_to_forum");
  },
  canDeleteUser: and("model.can_be_deleted", "model.can_delete_all_posts"),
  publicUserFields() {
    const siteUserFields = this.site.get("user_fields");
    if (!isEmpty(siteUserFields)) {
      const userFields = this.get("model.user_fields");
      return siteUserFields.filterBy("show_on_profile", true).sortBy("position").map(field => {
        set(field, "dasherized_name", dasherize(field.get("name")));
        const value = userFields ? userFields[field.get("id").toString()] : null;
        return isEmpty(value) ? null : EmberObject.create({
          value,
          field
        });
      }).compact();
    }
  },
  primaryGroup(group) {
    if (group) {
      return `group-${group}`;
    }
  },
  currentParentRoute: readOnly("router.currentRoute.parent.name"),
  userNotificationLevel: computed("currentUser.ignored_ids", "model.ignored", "model.muted", {
    get() {
      if (this.get("model.ignored")) {
        return "changeToIgnored";
      } else if (this.get("model.muted")) {
        return "changeToMuted";
      } else {
        return "changeToNormal";
      }
    },
    set(key, value) {
      return value;
    }
  }),
  get displayTopLevelAdminButton() {
    if (!this.currentUser?.staff) {
      return false;
    }
    return this.site.desktopView;
  },
  showSuspensions(event) {
    event?.preventDefault();
    this.adminTools.showActionLogs(this, {
      target_user: this.get("model.username"),
      action_name: "suspend_user"
    });
  },
  actions: {
    collapseProfile() {
      this.set("forceExpand", false);
    },
    expandProfile() {
      this.set("forceExpand", true);
    },
    adminDelete() {
      const userId = this.get("model.id");
      const location = document.location.pathname;
      const performDestroy = block => {
        this.dialog.notice(I18n.t("admin.user.deleting_user"));
        let formData = {
          context: location
        };
        if (block) {
          formData["block_email"] = true;
          formData["block_urls"] = true;
          formData["block_ip"] = true;
        }
        formData["delete_posts"] = true;
        return this.adminTools.deleteUser(userId, formData).then(data => {
          if (data.deleted) {
            document.location = getURL("/admin/users/list/active");
          } else {
            this.dialog.alert(I18n.t("admin.user.delete_failed"));
          }
        }).catch(() => this.dialog.alert(I18n.t("admin.user.delete_failed")));
      };
      this.dialog.alert({
        title: I18n.t("admin.user.delete_confirm_title"),
        message: I18n.t("admin.user.delete_confirm"),
        class: "delete-user-modal",
        buttons: [{
          label: I18n.t("admin.user.delete_dont_block"),
          class: "btn-primary",
          action: () => {
            return performDestroy(false);
          }
        }, {
          icon: "exclamation-triangle",
          label: I18n.t("admin.user.delete_and_block"),
          class: "btn-danger",
          action: () => {
            return performDestroy(true);
          }
        }, {
          label: I18n.t("composer.cancel")
        }]
      });
    },
    updateNotificationLevel(params) {
      return this.model.updateNotificationLevel(params);
    }
  }
}, [["method", "viewingSelf", [discourseComputed("model.username")]], ["method", "canExpandProfile", [discourseComputed("viewingSelf", "model.profile_hidden")]], ["method", "hasProfileBackgroundUrl", [discourseComputed("model.profileBackgroundUrl")]], ["method", "collapsedInfo", [discourseComputed("model.profile_hidden", "isSummaryRoute", "viewingSelf", "forceExpand")]], ["method", "isNotSuspendedOrIsStaff", [discourseComputed("model.suspended", "currentUser.staff")]], ["method", "removeNoFollow", [discourseComputed("model.trust_level")]], ["method", "showBookmarks", [discourseComputed("viewingSelf", "currentUser.admin")]], ["method", "showDrafts", [discourseComputed("viewingSelf")]], ["method", "showRead", [discourseComputed("viewingSelf")]], ["method", "showPrivateMessages", [discourseComputed("viewingSelf", "currentUser.admin", "currentUser.can_send_private_messages")]], ["method", "showActivityTab", [discourseComputed("viewingSelf", "currentUser.admin")]], ["method", "showNotificationsTab", [discourseComputed("viewingSelf", "currentUser.admin")]], ["method", "nameFirst", [discourseComputed("model.name")]], ["method", "showBadges", [discourseComputed("model.badge_count")]], ["method", "canInviteToForum", [discourseComputed()]], ["method", "publicUserFields", [discourseComputed("model.user_fields.@each.value")]], ["method", "primaryGroup", [discourseComputed("model.primary_group_name")]], ["method", "showSuspensions", [action]]]));
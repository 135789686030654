/* import __COLOCATED_TEMPLATE__ from './categories-only.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-only.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  showMuted: false,
  mutedToggleIcon(showMutedCategories, filteredCategoriesLength) {
    if (filteredCategoriesLength === 0) {
      return;
    }
    if (showMutedCategories) {
      return "minus";
    }
    return "plus";
  },
  showMutedCategories(showMuted, filteredCategoriesLength) {
    return showMuted || filteredCategoriesLength === 0;
  },
  filteredCategories(categories, categoriesLength) {
    if (!categories || categoriesLength === 0) {
      return [];
    }
    return categories.filter(cat => !cat.isHidden);
  },
  mutedCategories(categories, categoriesLength) {
    if (!categories || categoriesLength === 0) {
      return [];
    }

    // hide in single category pages
    if (categories.firstObject.parent_category_id) {
      return [];
    }
    return categories.filterBy("hasMuted");
  },
  toggleShowMuted(event) {
    event?.preventDefault();
    this.toggleProperty("showMuted");
  }
}, [["method", "mutedToggleIcon", [discourseComputed("showMutedCategories", "filteredCategories.length")]], ["method", "showMutedCategories", [discourseComputed("showMuted", "filteredCategories.length")]], ["method", "filteredCategories", [discourseComputed("categories", "categories.length")]], ["method", "mutedCategories", [discourseComputed("categories", "categories.length")]], ["method", "toggleShowMuted", [action]]])));
import { htmlSafe } from "@ember/template";
import { autoUpdatingRelativeAge } from "discourse/lib/formatter";
import { registerRawHelper } from "discourse-common/lib/helpers";
registerRawHelper("age-with-tooltip", ageWithTooltip);
export default function ageWithTooltip(dt) {
  let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return htmlSafe(autoUpdatingRelativeAge(new Date(dt), {
    customTitle: params.customTitle,
    title: true,
    addAgo: params.addAgo || false,
    ...(params.defaultFormat && {
      defaultFormat: params.defaultFormat
    })
  }));
}
/* eslint-disable ember/no-private-routing-service */
import { getOwner } from "@ember/application";
import { A } from "@ember/array";
import Helper from "@ember/component/helper";
import { assert, runInDebug } from "@ember/debug";
import { computed, get } from "@ember/object";
import { join } from "@ember/runloop";
function getCurrentRouteInfos(router) {
  let routerLib = router._routerMicrolib || router.router;
  return routerLib.currentRouteInfos;
}
function getRoutes(router) {
  return A(getCurrentRouteInfos(router)).mapBy("_route").reverse();
}
function getRouteWithAction(router, actionName) {
  let action;
  let handler = A(getRoutes(router)).find(route => {
    let actions = route.actions || route._actions;
    action = actions[actionName];
    return typeof action === "function";
  });
  return {
    action,
    handler
  };
}
function routeAction(actionName, router) {
  for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    params[_key - 2] = arguments[_key];
  }
  (false && !(router) && assert("[ember-route-action-helper] Unable to lookup router", router));
  runInDebug(() => {
    let {
      handler
    } = getRouteWithAction(router, actionName);
    (false && !(handler) && assert(`[ember-route-action-helper] Unable to find action ${actionName}`, handler));
  });
  return function () {
    let {
      action,
      handler
    } = getRouteWithAction(router, actionName);
    for (var _len2 = arguments.length, invocationArgs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      invocationArgs[_key2] = arguments[_key2];
    }
    let args = params.concat(invocationArgs);
    return join(handler, action, ...args);
  };
}
export default class RouteAction extends Helper {
  get router() {
    return getOwner(this).lookup("router:main");
  }
  static #_ = (() => dt7948.n(this.prototype, "router", [computed]))();
  compute(_ref) {
    let [actionName, ...params] = _ref;
    return routeAction(actionName, get(this, "router"), ...params);
  }
}
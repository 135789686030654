import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import DiscourseURL from "discourse/lib/url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class BootstrapModeNotice extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  routeToAdminGuide() {
    DiscourseURL.routeTo(`/t/-/${this.siteSettings.admin_quick_start_topic_id}`);
  }
  static #_2 = (() => dt7948.n(this.prototype, "routeToAdminGuide", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DButton
        @action={{this.routeToAdminGuide}}
        @label="bootstrap_mode"
        class="btn-default bootstrap-mode"
      />
    
  */
  {
    "id": "HNaVdZ+o",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-default bootstrap-mode\"]],[[\"@action\",\"@label\"],[[30,0,[\"routeToAdminGuide\"]],\"bootstrap_mode\"]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/bootstrap-mode-notice.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this))();
}
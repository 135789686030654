/* import __COLOCATED_TEMPLATE__ from './user-flag-percentage.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./user-flag-percentage.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  showPercentage(percentage) {
    return percentage.total >= 3;
  },
  percentage(agreed, disagreed, ignored) {
    let total = agreed + disagreed + ignored;
    let result = {
      total
    };
    if (total > 0) {
      result.agreed = Math.round(agreed / total * 100);
      result.disagreed = Math.round(disagreed / total * 100);
      result.ignored = Math.round(ignored / total * 100);
    }
    let highest = Math.max(agreed, disagreed, ignored);
    if (highest === agreed) {
      result.icon = "thumbs-up";
      result.className = "agreed";
      result.label = `${result.agreed}%`;
    } else if (highest === disagreed) {
      result.icon = "thumbs-down";
      result.className = "disagreed";
      result.label = `${result.disagreed}%`;
    } else {
      result.icon = "external-link-alt";
      result.className = "ignored";
      result.label = `${result.ignored}%`;
    }
    result.title = I18n.t("review.user_percentage.summary", {
      agreed: I18n.t("review.user_percentage.agreed", {
        count: result.agreed
      }),
      disagreed: I18n.t("review.user_percentage.disagreed", {
        count: result.disagreed
      }),
      ignored: I18n.t("review.user_percentage.ignored", {
        count: result.ignored
      }),
      count: total
    });
    return result;
  }
}, [["method", "showPercentage", [discourseComputed("percentage")]], ["method", "percentage", [discourseComputed("agreed", "disagreed", "ignored")]]])));
/* import __COLOCATED_TEMPLATE__ from './topics.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { userPath } from "discourse/lib/url";
import I18n from "discourse-i18n";
import TEMPLATE from "./topics.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DiscoveryTopics extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "composer", [service]))();
  #composer = (() => (dt7948.i(this, "composer"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "loadingNew", [tracked]))();
  #loadingNew = (() => (dt7948.i(this, "loadingNew"), void 0))();
  get redirectedReason() {
    return this.currentUser?.user_option.redirected_to_top?.reason;
  }
  get order() {
    return this.args.model.get("params.order");
  }
  get ascending() {
    return this.args.model.get("params.ascending") === "true";
  }
  get hasTopics() {
    return this.args.model.get("topics.length") > 0;
  }
  get allLoaded() {
    return !this.args.model.get("more_topics_url");
  }
  get latest() {
    return filterTypeForMode(this.args.model.filter) === "latest";
  }
  get top() {
    return filterTypeForMode(this.args.model.filter) === "top";
  }
  get hot() {
    return filterTypeForMode(this.args.model.filter) === "hot";
  }
  get new() {
    return filterTypeForMode(this.args.model.filter) === "new";
  }

  // Show newly inserted topics
  async showInserted(event) {
    event?.preventDefault();
    if (this.args.model.loadingBefore) {
      return; // Already loading
    }
    const {
      topicTrackingState
    } = this;
    try {
      const topicIds = [...topicTrackingState.newIncoming];
      await this.args.model.loadBefore(topicIds, true);
      topicTrackingState.clearIncoming(topicIds);
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_8 = (() => dt7948.n(this.prototype, "showInserted", [action]))();
  get showTopicsAndRepliesToggle() {
    return this.new && this.currentUser?.new_new_view_enabled;
  }
  get newRepliesCount() {
    this.topicTrackingState.get("messageCount"); // Autotrack this

    if (this.currentUser?.new_new_view_enabled) {
      return this.topicTrackingState.countUnread({
        categoryId: this.args.category?.id,
        noSubcategories: this.args.noSubcategories,
        tagId: this.args.tag?.id
      });
    } else {
      return 0;
    }
  }
  get newTopicsCount() {
    this.topicTrackingState.get("messageCount"); // Autotrack this

    if (this.currentUser?.new_new_view_enabled) {
      return this.topicTrackingState.countNew({
        categoryId: this.args.category?.id,
        noSubcategories: this.args.noSubcategories,
        tagId: this.args.tag?.id
      });
    } else {
      return 0;
    }
  }
  get showTopicPostBadges() {
    return !this.new || this.currentUser?.new_new_view_enabled;
  }
  get footerMessage() {
    const topicsLength = this.args.model.get("topics.length");
    if (!this.allLoaded) {
      return;
    }
    const {
      category,
      tag
    } = this.args;
    if (category) {
      return I18n.t("topics.bottom.category", {
        category: category.get("name")
      });
    } else if (tag) {
      return I18n.t("topics.bottom.tag", {
        tag: tag.id
      });
    } else {
      const split = (this.args.model.get("filter") || "").split("/");
      if (topicsLength === 0) {
        return I18n.t("topics.none." + split[0], {
          category: split[1]
        });
      } else {
        return I18n.t("topics.bottom." + split[0], {
          category: split[1]
        });
      }
    }
  }
  get footerEducation() {
    const topicsLength = this.args.model.get("topics.length");
    if (!this.allLoaded || topicsLength > 0 || !this.currentUser) {
      return;
    }
    const segments = (this.args.model.get("filter") || "").split("/");
    let tab = segments[segments.length - 1];
    if (tab !== "new" && tab !== "unread") {
      return;
    }
    if (tab === "new" && this.currentUser.new_new_view_enabled) {
      tab = "new_new";
    }
    return I18n.t("topics.none.educate." + tab, {
      userPrefsUrl: userPath(`${this.currentUser.get("username_lower")}/preferences/tracking`)
    });
  }
  get renderNewListHeaderControls() {
    return this.site.mobileView && this.showTopicsAndRepliesToggle && !this.args.bulkSelectEnabled;
  }
  get expandGloballyPinned() {
    return !this.expandAllPinned;
  }
  get expandAllPinned() {
    return this.args.tag || this.args.category;
  }
}
setComponentTemplate(TEMPLATE, DiscoveryTopics);
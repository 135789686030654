import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import i18n from "discourse-common/helpers/i18n";
import AllTagsSectionLink from "../common/all-tags-section-link";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export default class SidebarAnonymousTagsSection extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  get displaySection() {
    return this.site.anonymous_default_navigation_menu_tags?.length > 0 || this.site.navigation_menu_site_top_tags?.length > 0;
  }
  get sectionLinks() {
    return (this.site.anonymous_default_navigation_menu_tags || this.site.navigation_menu_site_top_tags).map(tag1 => {
      return new TagSectionLink({
        tag: tag1,
        topicTrackingState: this.topicTrackingState
      });
    });
  }
  static #_4 = (() => dt7948.n(this.prototype, "sectionLinks", [cached]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.displaySection}}
        <Section
          @sectionName="tags"
          @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
          @collapsable={{@collapsable}}
        >
  
          {{#each this.sectionLinks as |sectionLink|}}
            <SectionLink
              @route={{sectionLink.route}}
              @content={{sectionLink.text}}
              @title={{sectionLink.title}}
              @currentWhen={{sectionLink.currentWhen}}
              @prefixType={{sectionLink.prefixType}}
              @prefixValue={{sectionLink.prefixValue}}
              @prefixColor={{sectionLink.prefixColor}}
              @models={{sectionLink.models}}
              data-tag-name={{sectionLink.tagName}}
            />
          {{/each}}
  
          <AllTagsSectionLink />
        </Section>
      {{/if}}
    
  */
  {
    "id": "O+dMf9tZ",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"displaySection\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@collapsable\"],[\"tags\",[28,[32,1],[\"sidebar.sections.tags.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"          \"],[8,[32,2],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@content\",\"@title\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@models\"],[[30,2,[\"route\"]],[30,2,[\"text\"]],[30,2,[\"title\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"models\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[32,3],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/anonymous/tags-section.js",
    "scope": () => [Section, i18n, SectionLink, AllTagsSectionLink],
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import { longDate, relativeAge } from "discourse/lib/formatter";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class RelativeDate extends Component {
  get datetime() {
    return new Date(this.args.date);
  }
  get title() {
    return longDate(this.datetime);
  }
  get time() {
    return this.datetime.getTime();
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <span
        class="relative-date"
        title={{this.title}}
        data-time={{this.time}}
        data-format="tiny"
      >
        {{relativeAge this.datetime}}
      </span>
    
  */
  {
    "id": "D86FUnxh",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"relative-date\"],[15,\"title\",[30,0,[\"title\"]]],[15,\"data-time\",[30,0,[\"time\"]]],[14,\"data-format\",\"tiny\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,0,[\"datetime\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/relative-date.js",
    "scope": () => [relativeAge],
    "isStrictMode": true
  }), this))();
}
import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "topic.topic_status_update.title"}}
  @flash={{this.flash}}
  @closeModal={{@closeModal}}
  autoFocus="false"
  id="topic-timer-modal"
  class="edit-topic-timer-modal"
>
  <:body>
    {{#if this.topicTimer}}
      <EditTopicTimerForm
        @topic={{@model.topic}}
        @topicTimer={{this.topicTimer}}
        @timerTypes={{this.publicTimerTypes}}
        @onChangeStatusType={{this.onChangeStatusType}}
        @onChangeInput={{this.onChangeInput}}
      />
    {{/if}}
  </:body>
  <:footer>
    <DButton
      class="btn-primary"
      @disabled={{this.saveDisabled}}
      @label="topic.topic_status_update.save"
      @action={{this.saveTimer}}
      @isLoading={{this.loading}}
    />
    {{#if this.topicTimer.execute_at}}
      <DButton
        class="btn-danger"
        @action={{this.removeTimer}}
        @label="topic.topic_status_update.remove"
      />
    {{/if}}
  </:footer>
</DModal>
*/
{
  "id": "2YFL8FAK",
  "block": "[[[8,[39,0],[[24,\"autoFocus\",\"false\"],[24,1,\"topic-timer-modal\"],[24,0,\"edit-topic-timer-modal\"]],[[\"@title\",\"@flash\",\"@closeModal\"],[[28,[37,1],[\"topic.topic_status_update.title\"],null],[30,0,[\"flash\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"topicTimer\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@topic\",\"@topicTimer\",\"@timerTypes\",\"@onChangeStatusType\",\"@onChangeInput\"],[[30,2,[\"topic\"]],[30,0,[\"topicTimer\"]],[30,0,[\"publicTimerTypes\"]],[30,0,[\"onChangeStatusType\"]],[30,0,[\"onChangeInput\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@label\",\"@action\",\"@isLoading\"],[[30,0,[\"saveDisabled\"]],\"topic.topic_status_update.save\",[30,0,[\"saveTimer\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"topicTimer\",\"execute_at\"]],[[[1,\"      \"],[8,[39,4],[[24,0,\"btn-danger\"]],[[\"@action\",\"@label\"],[[30,0,[\"removeTimer\"]],\"topic.topic_status_update.remove\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"if\",\"edit-topic-timer-form\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/edit-topic-timer.hbs",
  "isStrictMode": false
});
/* import __COLOCATED_TEMPLATE__ from './reviewable-score.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { gt } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-score.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  showStatus: gt("rs.status", 0),
  title(title, targetCreatedBy) {
    if (title && targetCreatedBy) {
      return title.replace(/{{username}}|%{username}/, targetCreatedBy.username);
    }
    return title;
  }
}, [["method", "title", [discourseComputed("rs.score_type.title", "reviewable.target_created_by")]]])));
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DeferredRender extends Component {
  static #_ = (() => dt7948.g(this.prototype, "shouldRender", [tracked], function () {
    return false;
  }))();
  #shouldRender = (() => (dt7948.i(this, "shouldRender"), void 0))();
  constructor() {
    super(...arguments);
    runAfterFramePaint(() => this.shouldRender = true);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldRender}}
        {{yield}}
      {{/if}}
    
  */
  {
    "id": "Ze/rFA8f",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/deferred-render.js",
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './horizontal-overflow-nav.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./horizontal-overflow-nav.hbs";
import { setComponentTemplate } from "@ember/component";
export default class HorizontalOverflowNav extends Component {
  static #_ = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "hasScroll", [tracked]))();
  #hasScroll = (() => (dt7948.i(this, "hasScroll"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "hideRightScroll", [tracked], function () {
    return false;
  }))();
  #hideRightScroll = (() => (dt7948.i(this, "hideRightScroll"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "hideLeftScroll", [tracked], function () {
    return true;
  }))();
  #hideLeftScroll = (() => (dt7948.i(this, "hideLeftScroll"), void 0))();
  scrollInterval;
  scrollToActive(element) {
    const activeElement = element.querySelector("a.active");
    activeElement?.scrollIntoView({
      block: "nearest",
      inline: "center"
    });
  }
  static #_5 = (() => dt7948.n(this.prototype, "scrollToActive", [bind]))();
  checkScroll(event) {
    if (this.site.mobileView) {
      return;
    }
    this.watchScroll(event);
    this.hasScroll = event.target.scrollWidth > event.target.offsetWidth;
  }
  static #_6 = (() => dt7948.n(this.prototype, "checkScroll", [bind]))();
  stopScroll() {
    clearInterval(this.scrollInterval);
  }
  static #_7 = (() => dt7948.n(this.prototype, "stopScroll", [bind]))();
  watchScroll(event) {
    if (this.site.mobileView) {
      return;
    }
    if (event.target.offsetWidth + event.target.scrollLeft === event.target.scrollWidth) {
      this.hideRightScroll = true;
      clearInterval(this.scrollInterval);
    } else {
      this.hideRightScroll = false;
    }
    if (event.target.scrollLeft === 0) {
      this.hideLeftScroll = true;
      clearInterval(this.scrollInterval);
    } else {
      this.hideLeftScroll = false;
    }
  }
  static #_8 = (() => dt7948.n(this.prototype, "watchScroll", [bind]))();
  scrollDrag(event) {
    if (this.site.mobileView || !this.hasScroll) {
      return;
    }
    event.preventDefault();
    const navPills = event.target.closest(".nav-pills");
    const position = {
      left: navPills.scrollLeft,
      // current scroll
      x: event.clientX // mouse position
    };
    const mouseDragScroll = function (e) {
      let mouseChange = e.clientX - position.x;
      navPills.scrollLeft = position.left - mouseChange;
    };
    navPills.querySelectorAll("a").forEach(a => {
      a.style.cursor = "grabbing";
    });
    const removeDragScroll = function () {
      document.removeEventListener("mousemove", mouseDragScroll);
      navPills.querySelectorAll("a").forEach(a => {
        a.style.cursor = "pointer";
      });
    };
    document.addEventListener("mousemove", mouseDragScroll);
    document.addEventListener("mouseup", removeDragScroll, {
      once: true
    });
  }
  static #_9 = (() => dt7948.n(this.prototype, "scrollDrag", [bind]))();
  horizontalScroll(event) {
    // Do nothing if it is not left mousedown
    if (event.which !== 1) {
      return;
    }
    let scrollSpeed = 175;
    let siblingTarget = event.target.previousElementSibling;
    if (event.target.dataset.direction === "left") {
      scrollSpeed = scrollSpeed * -1;
      siblingTarget = event.target.nextElementSibling;
    }
    siblingTarget.scrollLeft += scrollSpeed;
    this.scrollInterval = setInterval(function () {
      siblingTarget.scrollLeft += scrollSpeed;
    }, 50);
  }
  static #_10 = (() => dt7948.n(this.prototype, "horizontalScroll", [action]))();
}
setComponentTemplate(TEMPLATE, HorizontalOverflowNav);
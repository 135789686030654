import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import { htmlSafe } from "@ember/template";
import { eq } from "truth-helpers";
import { isHex } from "discourse/components/sidebar/section-link";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarSectionLinkPrefix extends Component {
  get prefixValue() {
    if (!this.args.prefixType && !this.args.prefixValue) {
      return;
    }
    switch (this.args.prefixType) {
      case "span":
        let hexValues1 = this.args.prefixValue;
        hexValues1 = hexValues1.reduce((acc1, color1) => {
          const hexCode1 = isHex(color1);
          if (hexCode1) {
            acc1.push(`#${hexCode1} 50%`);
          }
          return acc1;
        }, []);
        if (hexValues1.length === 1) {
          hexValues1.push(hexValues1[0]);
        }
        return hexValues1.join(", ");
      default:
        return this.args.prefixValue;
    }
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if @prefixType}}
        <span
          style={{if @prefixColor (htmlSafe (concat "color: " @prefixColor))}}
          class={{concatClass
            "sidebar-section-link-prefix"
            @prefixType
            @prefixCSSClass
          }}
        >
          {{#if (eq @prefixType "image")}}
            <img src={{this.prefixValue}} class="prefix-image" />
          {{else if (eq @prefixType "text")}}
            <span class="prefix-text">
              {{this.prefixValue}}
            </span>
          {{else if (eq @prefixType "icon")}}
            {{icon this.prefixValue class="prefix-icon"}}
          {{else if (eq @prefixType "span")}}
            <span
              style={{htmlSafe
                (concat
                  "background: linear-gradient(90deg, " this.prefixValue ")"
                )
              }}
              class="prefix-span"
            ></span>
          {{/if}}
  
          {{#if @prefixBadge}}
            {{icon @prefixBadge class="prefix-badge"}}
          {{/if}}
        </span>
      {{/if}}
    
  */
  {
    "id": "MFj2/bac",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,1],[15,5,[52,[30,2],[28,[32,0],[[28,[32,1],[\"color: \",[30,2]],null]],null]]],[15,0,[28,[32,2],[\"sidebar-section-link-prefix\",[30,1],[30,3]],null]],[12],[1,\"\\n\"],[41,[28,[32,3],[[30,1],\"image\"],null],[[[1,\"          \"],[10,\"img\"],[15,\"src\",[30,0,[\"prefixValue\"]]],[14,0,\"prefix-image\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,3],[[30,1],\"text\"],null],[[[1,\"          \"],[10,1],[14,0,\"prefix-text\"],[12],[1,\"\\n            \"],[1,[30,0,[\"prefixValue\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,3],[[30,1],\"icon\"],null],[[[1,\"          \"],[1,[28,[32,4],[[30,0,[\"prefixValue\"]]],[[\"class\"],[\"prefix-icon\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[32,3],[[30,1],\"span\"],null],[[[1,\"          \"],[10,1],[15,5,[28,[32,0],[[28,[32,1],[\"background: linear-gradient(90deg, \",[30,0,[\"prefixValue\"]],\")\"],null]],null]],[14,0,\"prefix-span\"],[12],[13],[1,\"\\n        \"]],[]],null]],[]]]],[]]]],[]]],[1,\"\\n\"],[41,[30,4],[[[1,\"          \"],[1,[28,[32,4],[[30,4]],[[\"class\"],[\"prefix-badge\"]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@prefixType\",\"@prefixColor\",\"@prefixCSSClass\",\"@prefixBadge\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/section-link-prefix.js",
    "scope": () => [htmlSafe, concat, concatClass, eq, icon],
    "isStrictMode": true
  }), this))();
}
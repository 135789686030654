/* import __COLOCATED_TEMPLATE__ from './second-factor-backup-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import { debounce } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-backup-edit.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SecondFactorBackupEdit extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "errorMessage", [tracked]))();
  #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "successMessage", [tracked]))();
  #successMessage = (() => (dt7948.i(this, "successMessage"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "backupEnabled", [tracked], function () {
    return this.args.model.secondFactor.second_factor_backup_enabled;
  }))();
  #backupEnabled = (() => (dt7948.i(this, "backupEnabled"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "remainingCodes", [tracked], function () {
    return this.args.model.secondFactor.second_factor_remaining_backup_codes;
  }))();
  #remainingCodes = (() => (dt7948.i(this, "remainingCodes"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "backupCodes", [tracked]))();
  #backupCodes = (() => (dt7948.i(this, "backupCodes"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "secondFactorMethod", [tracked], function () {
    return SECOND_FACTOR_METHODS.TOTP;
  }))();
  #secondFactorMethod = (() => (dt7948.i(this, "secondFactorMethod"), void 0))();
  copyBackupCode(successful) {
    if (successful) {
      this.successMessage = I18n.t("user.second_factor_backup.copied_to_clipboard");
    } else {
      this.errorMessage = I18n.t("user.second_factor_backup.copy_to_clipboard_error");
    }
    this._hideCopyMessage();
  }
  static #_8 = (() => dt7948.n(this.prototype, "copyBackupCode", [action]))();
  generateSecondFactorCodes() {
    this.loading = true;
    this.args.model.secondFactor.generateSecondFactorCodes().then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.args.model.markDirty();
      this.errorMessage = null;
      this.backupCodes = response.backup_codes;
      this.args.model.setBackupEnabled(true);
      this.backupEnabled = true;
      this.remainingCodes = response.backup_codes.length;
      this.args.model.setCodesRemaining(this.remainingCodes);
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => this.loading = false);
  }
  static #_9 = (() => dt7948.n(this.prototype, "generateSecondFactorCodes", [action]))();
  _hideCopyMessage() {
    this.successMessage = null;
    this.errorMessage = null;
  }
  static #_10 = (() => dt7948.n(this.prototype, "_hideCopyMessage", [debounce(2000)]))();
}
setComponentTemplate(TEMPLATE, SecondFactorBackupEdit);
import Helper from "@ember/component/helper";
import { service } from "@ember/service";
export default class HideApplicationFooter extends Helper {
  static #_ = (() => dt7948.g(this.prototype, "footer", [service]))();
  #footer = (() => (dt7948.i(this, "footer"), void 0))();
  constructor() {
    super(...arguments);
    this.footer.registerHider(this);
  }
  compute() {}
}
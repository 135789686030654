/* import __COLOCATED_TEMPLATE__ from './bread-crumbs.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { filter } from "@ember/object/computed";
import deprecated from "discourse-common/lib/deprecated";
import discourseComputed from "discourse-common/utils/decorators";

//  A breadcrumb including category drop downs
import TEMPLATE from "./bread-crumbs.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNameBindings: ["hidden:hidden", ":category-breadcrumb"],
  tagName: "ol",
  editingCategory: false,
  editingCategoryTab: null,
  categoryBreadcrumbs(category, filteredCategories, noSubcategories) {
    const ancestors = category?.ancestors || [];
    const parentCategories = [undefined, ...ancestors];
    const categories = [...ancestors, undefined];
    return parentCategories.map((x, i) => [x, categories[i]]).map(record => {
      const [parentCategory, subCategory] = record;
      const options = filteredCategories.filter(c => c.get("parentCategory.id") === (parentCategory && parentCategory.id));
      return {
        category: subCategory,
        parentCategory,
        options,
        isSubcategory: !!parentCategory,
        noSubcategories: !subCategory && noSubcategories,
        hasOptions: !parentCategory || parentCategory.has_children
      };
    });
  },
  showTagsSection(taggingEnabled, editingCategory) {
    return taggingEnabled && !editingCategory;
  },
  parentCategory(category) {
    deprecated("The parentCategory property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.parentCategory"
    });
    return category && category.parentCategory;
  },
  parentCategories: filter("categories", function (c) {
    deprecated("The parentCategories property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.parentCategories"
    });
    if (c.id === this.site.get("uncategorized_category_id") && !this.siteSettings.allow_uncategorized_topics) {
      // Don't show "uncategorized" if allow_uncategorized_topics setting is false.
      return false;
    }
    return !c.get("parentCategory");
  }),
  parentCategoriesSorted(parentCategories) {
    deprecated("The parentCategoriesSorted property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.parentCategoriesSorted"
    });
    if (this.siteSettings.fixed_category_positions) {
      return parentCategories;
    }
    return parentCategories.sortBy("totalTopicCount").reverse();
  },
  hidden(category) {
    return this.site.mobileView && !category;
  },
  firstCategory(category, parentCategory) {
    deprecated("The firstCategory property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.firstCategory"
    });
    return parentCategory || category;
  },
  secondCategory(category, parentCategory) {
    deprecated("The secondCategory property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.secondCategory"
    });
    return parentCategory && category;
  },
  childCategories(firstCategory, hideSubcategories) {
    deprecated("The childCategories property of the bread-crumbs component is deprecated", {
      id: "discourse.breadcrumbs.childCategories"
    });
    if (hideSubcategories) {
      return [];
    }
    if (!firstCategory) {
      return [];
    }
    return this.categories.filter(c => c.get("parentCategory") === firstCategory);
  }
}, [["method", "categoryBreadcrumbs", [discourseComputed("category", "categories", "noSubcategories")]], ["method", "showTagsSection", [discourseComputed("siteSettings.tagging_enabled", "editingCategory")]], ["method", "parentCategory", [discourseComputed("category")]], ["method", "parentCategoriesSorted", [discourseComputed("parentCategories")]], ["method", "hidden", [discourseComputed("category")]], ["method", "firstCategory", [discourseComputed("category", "parentCategory")]], ["method", "secondCategory", [discourseComputed("category", "parentCategory")]], ["method", "childCategories", [discourseComputed("firstCategory", "hideSubcategories")]]])));
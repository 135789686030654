/* import __COLOCATED_TEMPLATE__ from './reviewable-field-category.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import TEMPLATE from "./reviewable-field-category.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, class extends Component {
  static #_ = (() => dt7948.g(this.prototype, "categoryId", [tracked], function () {
    return this.args.value;
  }))();
  #categoryId = (() => (dt7948.i(this, "categoryId"), void 0))();
  onChange(category) {
    this.categoryId = category;
    this.args.categoryChanged?.(category);
  }
  static #_2 = (() => dt7948.n(this.prototype, "onChange", [action]))();
});
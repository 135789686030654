/* import __COLOCATED_TEMPLATE__ from './anonymous-topic-footer-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { getTopicFooterButtons } from "discourse/lib/register-topic-footer-button";
import TEMPLATE from "./anonymous-topic-footer-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  elementId: "topic-footer-buttons",
  attributeBindings: ["role"],
  role: "region",
  allButtons: getTopicFooterButtons(),
  get buttons() {
    return this.allButtons.filterBy("anonymousOnly", true).sortBy("priority").reverse();
  }
}, [["method", "buttons", [computed("allButtons.[]")]]])));
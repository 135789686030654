/* import __COLOCATED_TEMPLATE__ from './user-status-picker.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action, computed } from "@ember/object";
import { scheduleOnce } from "@ember/runloop";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";
import TEMPLATE from "./user-status-picker.hbs";
export default class UserStatusPicker extends Component {
  tagName = "";
  isFocused = false;
  emojiPickerIsActive = false;
  didInsertElement() {
    super.didInsertElement(...arguments);
    if (!this.status) {
      this.set("status", {});
    }
    document.querySelector(".user-status-description")?.focus();
  }
  get emojiHtml() {
    const emoji = escapeExpression(`:${this.status.emoji}:`);
    return emojiUnescape(emoji);
  }
  static #_ = (() => dt7948.n(this.prototype, "emojiHtml", [computed("status.emoji")]))();
  focusEmojiButton() {
    document.querySelector(".btn-emoji")?.focus();
  }
  blur() {
    this.set("isFocused", false);
  }
  static #_2 = (() => dt7948.n(this.prototype, "blur", [action]))();
  emojiSelected(emoji) {
    this.set("status.emoji", emoji);
    this.set("emojiPickerIsActive", false);
    scheduleOnce("afterRender", this, this.focusEmojiButton);
  }
  static #_3 = (() => dt7948.n(this.prototype, "emojiSelected", [action]))();
  focus() {
    this.set("isFocused", true);
  }
  static #_4 = (() => dt7948.n(this.prototype, "focus", [action]))();
  onEmojiPickerOutsideClick() {
    this.set("emojiPickerIsActive", false);
  }
  static #_5 = (() => dt7948.n(this.prototype, "onEmojiPickerOutsideClick", [action]))();
  setDefaultEmoji() {
    if (!this.status.emoji) {
      this.set("status.emoji", "speech_balloon");
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "setDefaultEmoji", [action]))();
  toggleEmojiPicker(event) {
    event.stopPropagation();
    this.set("emojiPickerIsActive", !this.emojiPickerIsActive);
  }
  static #_7 = (() => dt7948.n(this.prototype, "toggleEmojiPicker", [action]))();
}
setComponentTemplate(TEMPLATE, UserStatusPicker);
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";
import I18n from "discourse-i18n";
export default class extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "user", [controller]))();
  #user = (() => (dt7948.i(this, "user"), void 0))();
  get viewingSelf() {
    return this.user.viewingSelf;
  }
  get newLinkText() {
    return this.#linkText("new");
  }
  static #_2 = (() => dt7948.n(this.prototype, "newLinkText", [computed("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "pmTopicTrackingState.isTracking")]))();
  get unreadLinkText() {
    return this.#linkText("unread");
  }
  static #_3 = (() => dt7948.n(this.prototype, "unreadLinkText", [computed("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "pmTopicTrackingState.isTracking")]))();
  get navigationControlsButton() {
    return document.getElementById("navigation-controls__button");
  }
  #linkText(type) {
    const count = this.pmTopicTrackingState?.lookupCount(type, {
      inboxFilter: "group",
      groupName: this.group.name
    });
    if (count === 0) {
      return I18n.t(`user.messages.${type}`);
    } else {
      return I18n.t(`user.messages.${type}_with_count`, {
        count
      });
    }
  }
  changeGroupNotificationLevel(notificationLevel) {
    this.group.setNotification(notificationLevel, this.get("user.model.id"));
  }
  static #_4 = (() => dt7948.n(this.prototype, "changeGroupNotificationLevel", [action]))();
}
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { array, hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarEditNavigationMenuTagsModal from "discourse/components/sidebar/edit-navigation-menu/tags-modal";
import { hasDefaultSidebarTags } from "discourse/lib/sidebar/helpers";
import PMTagSectionLink from "discourse/lib/sidebar/user/tags-section/pm-tag-section-link";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import i18n from "discourse-common/helpers/i18n";
import AllTagsSectionLink from "../common/all-tags-section-link";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export default class SidebarUserTagsSection extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "pmTopicTrackingState", [service]))();
  #pmTopicTrackingState = (() => (dt7948.i(this, "pmTopicTrackingState"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  constructor() {
    super(...arguments);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this.sectionLinks.forEach(sectionLink1 => {
        if (sectionLink1.refreshCounts) {
          sectionLink1.refreshCounts();
        }
      });
    });
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
  }
  get sectionLinks() {
    const links1 = [];
    let tags1;
    if (this.currentUser.sidebarTags.length > 0) {
      tags1 = this.currentUser.sidebarTags;
    } else {
      tags1 = this.site.navigation_menu_site_top_tags || [];
    }
    for (const tag1 of tags1) {
      if (tag1.pm_only) {
        links1.push(new PMTagSectionLink({
          tag: tag1,
          currentUser: this.currentUser
        }));
      } else {
        links1.push(new TagSectionLink({
          tag: tag1,
          topicTrackingState: this.topicTrackingState,
          currentUser: this.currentUser
        }));
      }
    }
    return links1;
  }
  static #_8 = (() => dt7948.n(this.prototype, "sectionLinks", [cached]))();
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarTags;
  }
  get hasDefaultSidebarTags() {
    return hasDefaultSidebarTags(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuTagsModal);
  }
  static #_9 = (() => dt7948.n(this.prototype, "showModal", [action]))();
  static #_10 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <Section
        @sectionName="tags"
        @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
        @headerActions={{array
          (hash
            action=this.showModal
            title=(i18n "sidebar.sections.tags.header_action_title")
          )
        }}
        @headerActionsIcon="pencil-alt"
        @collapsable={{@collapsable}}
      >
        {{#each this.sectionLinks as |sectionLink|}}
          <SectionLink
            @route={{sectionLink.route}}
            @title={{sectionLink.title}}
            @content={{sectionLink.text}}
            @currentWhen={{sectionLink.currentWhen}}
            @prefixType={{sectionLink.prefixType}}
            @prefixValue={{sectionLink.prefixValue}}
            @prefixColor={{sectionLink.prefixColor}}
            @badgeText={{sectionLink.badgeText}}
            @models={{sectionLink.models}}
            @suffixCSSClass={{sectionLink.suffixCSSClass}}
            @suffixValue={{sectionLink.suffixValue}}
            @suffixType={{sectionLink.suffixType}}
            data-tag-name={{sectionLink.tagName}}
          />
        {{/each}}
  
        <AllTagsSectionLink />
  
        {{#if this.shouldDisplayDefaultConfig}}
          <SectionLink
            @linkName="configure-default-navigation-menu-tags"
            @content={{i18n "sidebar.sections.tags.configure_defaults"}}
            @prefixType="icon"
            @prefixValue="wrench"
            @route="adminSiteSettingsCategory"
            @model="sidebar"
            @query={{hash filter="default_navigation_menu_tags"}}
          />
        {{/if}}
      </Section>
    
  */
  {
    "id": "B9hA1kcS",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@headerActions\",\"@headerActionsIcon\",\"@collapsable\"],[\"tags\",[28,[32,1],[\"sidebar.sections.tags.header_link_text\"],null],[28,[32,2],[[28,[32,3],null,[[\"action\",\"title\"],[[30,0,[\"showModal\"]],[28,[32,1],[\"sidebar.sections.tags.header_action_title\"],null]]]]],null],\"pencil-alt\",[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"        \"],[8,[32,4],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@title\",\"@content\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@badgeText\",\"@models\",\"@suffixCSSClass\",\"@suffixValue\",\"@suffixType\"],[[30,2,[\"route\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"badgeText\"]],[30,2,[\"models\"]],[30,2,[\"suffixCSSClass\"]],[30,2,[\"suffixValue\"]],[30,2,[\"suffixType\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"],[8,[32,5],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplayDefaultConfig\"]],[[[1,\"        \"],[8,[32,4],null,[[\"@linkName\",\"@content\",\"@prefixType\",\"@prefixValue\",\"@route\",\"@model\",\"@query\"],[\"configure-default-navigation-menu-tags\",[28,[32,1],[\"sidebar.sections.tags.configure_defaults\"],null],\"icon\",\"wrench\",\"adminSiteSettingsCategory\",\"sidebar\",[28,[32,3],null,[[\"filter\"],[\"default_navigation_menu_tags\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/tags-section.js",
    "scope": () => [Section, i18n, array, hash, SectionLink, AllTagsSectionLink],
    "isStrictMode": true
  }), this))();
}
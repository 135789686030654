import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import AssociateAccountConfirm from "discourse/components/modal/associate-account-confirm";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import DiscourseRoute from "discourse/routes/discourse";
export default class AssociateAccount extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  beforeModel(transition) {
    if (!this.currentUser) {
      cookie("destination_url", transition.intent.url);
      return this.router.replaceWith("login");
    }
    const params = this.paramsFor("associate-account");
    this.redirectToAccount(params);
  }
  async redirectToAccount(params) {
    await this.router.replaceWith(`preferences.account`, this.currentUser).followRedirects();
    next(() => this.showAssociateAccount(params));
  }
  static #_4 = (() => dt7948.n(this.prototype, "redirectToAccount", [action]))();
  async showAssociateAccount(params) {
    try {
      const model = await ajax(`/associate/${encodeURIComponent(params.token)}.json`);
      this.modal.show(AssociateAccountConfirm, {
        model
      });
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "showAssociateAccount", [action]))();
}
/* import __COLOCATED_TEMPLATE__ from './types.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import { logSearchLinkClick } from "discourse/lib/search";
import DiscourseURL from "discourse/lib/url";
import TEMPLATE from "./types.hbs";
import { setComponentTemplate } from "@ember/component";
export default class Types extends Component {
  static #_ = (() => dt7948.g(this.prototype, "search", [service]))();
  #search = (() => (dt7948.i(this, "search"), void 0))();
  get filteredResultTypes() {
    // return only topic result types
    if (this.args.topicResultsOnly) {
      return this.args.resultTypes.filter(resultType => resultType.type === "topic");
    }

    // return all result types minus topics
    return this.args.resultTypes.filter(resultType => resultType.type !== "topic");
  }
  onClick(_ref, event) {
    let {
      resultType,
      result
    } = _ref;
    logSearchLinkClick({
      searchLogId: this.args.searchLogId,
      searchResultId: result.id,
      searchResultType: resultType.type
    });
    if (wantsNewWindow(event)) {
      return;
    }
    event.preventDefault();
    this.routeToSearchResult(event.currentTarget.href);
  }
  static #_2 = (() => dt7948.n(this.prototype, "onClick", [action]))();
  onKeydown(_ref2, event) {
    let {
      resultType,
      result
    } = _ref2;
    if (event.key === "Escape") {
      this.args.closeSearchMenu();
      event.preventDefault();
      return false;
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      logSearchLinkClick({
        searchLogId: this.args.searchLogId,
        searchResultId: result.id,
        searchResultType: resultType.type
      });
      this.routeToSearchResult(event.target.href);
      return false;
    }
    this.search.handleResultInsertion(event);
    this.search.handleArrowUpOrDown(event);
  }
  static #_3 = (() => dt7948.n(this.prototype, "onKeydown", [action]))();
  routeToSearchResult(href) {
    DiscourseURL.routeTo(href);
    this.args.closeSearchMenu();
  }
  static #_4 = (() => dt7948.n(this.prototype, "routeToSearchResult", [action]))();
}
setComponentTemplate(TEMPLATE, Types);
/* import __COLOCATED_TEMPLATE__ from './change-category.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./change-category.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChangeCategory extends Component {
  categoryId = 0;
  async changeCategory() {
    await this.args.forEachPerformed({
      type: "change_category",
      category_id: this.categoryId
    }, t => t.set("category_id", this.categoryId));
  }
  static #_ = (() => dt7948.n(this.prototype, "changeCategory", [action]))();
}
setComponentTemplate(TEMPLATE, ChangeCategory);
/* import __COLOCATED_TEMPLATE__ from './categories-boxes.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "section",
  classNameBindings: [":category-boxes", "anyLogos:with-logos:no-logos", "hasSubcategories:with-subcategories"],
  lockIcon: "lock",
  anyLogos() {
    return this.categories.any(c => !isEmpty(c.get("uploaded_logo.url")));
  },
  hasSubcategories() {
    return this.categories.any(c => !isEmpty(c.get("subcategories")));
  }
}, [["method", "anyLogos", [discourseComputed("categories.[].uploaded_logo.url")]], ["method", "hasSubcategories", [discourseComputed("categories.[].subcategories")]]])));
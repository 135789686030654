import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action } from "@ember/object";
import Badge from "discourse/models/badge";
import UserBadge from "discourse/models/user-badge";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(dt7948.p({
  application: controller(),
  queryParams: ["username"],
  noMoreBadges: false,
  userBadges: null,
  hiddenSetTitle: true,
  filteredList(userBadgesAll) {
    return userBadgesAll.filterBy("badge.allow_title", true);
  },
  selectableUserBadges(filteredList) {
    return [EmberObject.create({
      id: 0,
      badge: Badge.create({
        name: I18n.t("badges.none")
      })
    }), ...filteredList.uniqBy("badge.name")];
  },
  user(username) {
    if (username) {
      return this.userBadges[0].get("user");
    }
  },
  grantCount(username, modelCount, userCount) {
    return username ? userCount : modelCount;
  },
  othersCount(modelCount, userCount) {
    return modelCount - userCount;
  },
  canSelectTitle(hasTitleBadges, hasBadge) {
    return this.siteSettings.enable_badges && hasTitleBadges && hasBadge;
  },
  canLoadMore(noMoreBadges, grantCount, userBadgeLength) {
    if (noMoreBadges) {
      return false;
    }
    return grantCount > (userBadgeLength || 0);
  },
  canShowOthers(user, grantCount) {
    return !!user && grantCount > 1;
  },
  loadMore() {
    if (!this.canLoadMore) {
      return;
    }
    if (this.loadingMore) {
      return;
    }
    this.set("loadingMore", true);
    const userBadges = this.userBadges;
    UserBadge.findByBadgeId(this.get("model.id"), {
      offset: userBadges.length,
      username: this.username
    }).then(result => {
      userBadges.pushObjects(result);
      if (userBadges.length === 0) {
        this.set("noMoreBadges", true);
      }
    }).finally(() => {
      this.set("loadingMore", false);
    });
  },
  toggleSetUserTitle() {
    return this.toggleProperty("hiddenSetTitle");
  }
}, [["method", "filteredList", [discourseComputed("userBadgesAll")]], ["method", "selectableUserBadges", [discourseComputed("filteredList")]], ["method", "user", [discourseComputed("username")]], ["method", "grantCount", [discourseComputed("username", "model.grant_count", "userBadges.grant_count")]], ["method", "othersCount", [discourseComputed("model.grant_count", "userBadges.grant_count")]], ["method", "canSelectTitle", [discourseComputed("model.allow_title", "model.has_badge", "model")]], ["method", "canLoadMore", [discourseComputed("noMoreBadges", "grantCount", "userBadges.length")]], ["method", "canShowOthers", [discourseComputed("user", "model.grant_count")]], ["method", "loadMore", [action]], ["method", "toggleSetUserTitle", [action]]]));
/* import __COLOCATED_TEMPLATE__ from './edit-category-security.hbs'; */
import { not } from "@ember/object/computed";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import PermissionType from "discourse/models/permission-type";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./edit-category-security.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("security", dt7948.p({
  selectedGroup: null,
  noGroupSelected: not("selectedGroup"),
  everyonePermission(permissions) {
    return permissions.findBy("group_name", "everyone");
  },
  everyoneGrantedFull() {
    return this.everyonePermission && this.everyonePermission.permission_type === PermissionType.FULL;
  },
  minimumPermission(everyonePermission) {
    return everyonePermission ? everyonePermission.permission_type : PermissionType.READONLY;
  },
  actions: {
    onSelectGroup(group_name) {
      this.category.addPermission({
        group_name,
        permission_type: this.minimumPermission
      });
    }
  }
}, [["method", "everyonePermission", [discourseComputed("category.permissions.@each.permission_type")]], ["method", "everyoneGrantedFull", [discourseComputed("category.permissions.@each.permission_type")]], ["method", "minimumPermission", [discourseComputed("everyonePermission")]]])));
/* import __COLOCATED_TEMPLATE__ from './raw-email.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Post from "discourse/models/post";
import TEMPLATE from "./raw-email.hbs";
import { setComponentTemplate } from "@ember/component";
export default class RawEmailComponent extends Component {
  static #_ = (() => dt7948.g(this.prototype, "rawEmail", [tracked], function () {
    return this.args.model.rawEmail || "";
  }))();
  #rawEmail = (() => (dt7948.i(this, "rawEmail"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "textPart", [tracked], function () {
    return "";
  }))();
  #textPart = (() => (dt7948.i(this, "textPart"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "htmlPart", [tracked], function () {
    return "";
  }))();
  #htmlPart = (() => (dt7948.i(this, "htmlPart"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "tab", [tracked], function () {
    return "raw";
  }))();
  #tab = (() => (dt7948.i(this, "tab"), void 0))();
  constructor() {
    super(...arguments);
    if (this.args.model.id) {
      this.loadRawEmail(this.args.model.id);
    }
  }
  async loadRawEmail(postId) {
    const result = await Post.loadRawEmail(postId);
    this.rawEmail = result.raw_email;
    this.textPart = result.text_part;
    this.htmlPart = result.html_part;
  }
  static #_5 = (() => dt7948.n(this.prototype, "loadRawEmail", [action]))();
  displayRaw() {
    this.tab = "raw";
  }
  static #_6 = (() => dt7948.n(this.prototype, "displayRaw", [action]))();
  displayTextPart() {
    this.tab = "text_part";
  }
  static #_7 = (() => dt7948.n(this.prototype, "displayTextPart", [action]))();
  displayHtmlPart() {
    this.tab = "html_part";
  }
  static #_8 = (() => dt7948.n(this.prototype, "displayHtmlPart", [action]))();
}
setComponentTemplate(TEMPLATE, RawEmailComponent);
import Component from "@glimmer/component";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ScoreValue extends Component {
  get numericValue() {
    return parseFloat(Math.abs(this.args.value)).toFixed(1);
  }
  get isNegative() {
    return this.args.value < 0;
  }
  get explanationTitle() {
    return I18n.t(`review.explain.${this.args.label}.title`);
  }
  get explanationContent() {
    return I18n.t(`review.explain.${this.args.label}.name`);
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if @value}}
        <span class="op">{{if this.isNegative "-" "+"}}</span>
        <span class="score-value">
          <span class="score-number">{{this.numericValue}}</span>
          {{#if @label}}
            <span title={{this.explanationTitle}} class="score-value-type">
              {{this.explanationContent}}
            </span>
          {{/if}}
        </span>
      {{/if}}
    
  */
  {
    "id": "0/NcLGRO",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,1],[14,0,\"op\"],[12],[1,[52,[30,0,[\"isNegative\"]],\"-\",\"+\"]],[13],[1,\"\\n      \"],[10,1],[14,0,\"score-value\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"score-number\"],[12],[1,[30,0,[\"numericValue\"]]],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[10,1],[15,\"title\",[30,0,[\"explanationTitle\"]]],[14,0,\"score-value-type\"],[12],[1,\"\\n            \"],[1,[30,0,[\"explanationContent\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@value\",\"@label\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/score-value.js",
    "isStrictMode": true
  }), this))();
}
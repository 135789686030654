/* import __COLOCATED_TEMPLATE__ from './group-add-members.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { extractError } from "discourse/lib/ajax-error";
import { emailValid } from "discourse/lib/utilities";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-add-members.hbs";
import { setComponentTemplate } from "@ember/component";
export default class GroupAddMembers extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "setOwner", [tracked], function () {
    return false;
  }))();
  #setOwner = (() => (dt7948.i(this, "setOwner"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "notifyUsers", [tracked], function () {
    return false;
  }))();
  #notifyUsers = (() => (dt7948.i(this, "notifyUsers"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "usernamesAndEmails", [tracked], function () {
    return [];
  }))();
  #usernamesAndEmails = (() => (dt7948.i(this, "usernamesAndEmails"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "flash", [tracked]))();
  #flash = (() => (dt7948.i(this, "flash"), void 0))();
  get title() {
    return I18n.t("groups.add_members.title", {
      group_name: this.args.model.fullName || this.args.model.name
    });
  }
  get usernames() {
    return this.usernamesAndEmails.reject(emailValid).join(",");
  }
  get emails() {
    return this.usernamesAndEmails.filter(emailValid).join(",");
  }
  setUsernamesAndEmails(usernamesAndEmails) {
    this.usernamesAndEmails = usernamesAndEmails;
    if (this.emails) {
      if (!this.usernames) {
        this.notifyUsers = false;
      }
      this.setOwner = false;
    }
  }
  static #_8 = (() => dt7948.n(this.prototype, "setUsernamesAndEmails", [action]))();
  async addMembers() {
    if (isEmpty(this.usernamesAndEmails)) {
      return;
    }
    this.loading = true;
    try {
      if (this.setOwner) {
        await this.args.model.addOwners(this.usernames, true, this.notifyUsers);
      } else {
        await this.args.model.addMembers(this.usernames, true, this.notifyUsers, this.emails);
      }
      this.router.transitionTo("group.members", this.args.model.name, {
        queryParams: {
          ...(this.usernames && {
            filter: this.usernames
          })
        }
      });
      this.args.closeModal();
    } catch (e) {
      this.flash = extractError(e);
    } finally {
      this.loading = false;
    }
  }
  static #_9 = (() => dt7948.n(this.prototype, "addMembers", [action]))();
}
setComponentTemplate(TEMPLATE, GroupAddMembers);
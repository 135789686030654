/* import __COLOCATED_TEMPLATE__ from './composer-toggles.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./composer-toggles.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  toggleTitle(composeState) {
    return composeState === "draft" || composeState === "saving" ? "composer.abandon" : "composer.collapse";
  },
  toggleToolbarTitle(showToolbar) {
    return showToolbar ? "composer.hide_toolbar" : "composer.show_toolbar";
  },
  fullscreenTitle(composeState) {
    return composeState === "draft" ? "composer.open" : composeState === "fullscreen" ? "composer.exit_fullscreen" : "composer.enter_fullscreen";
  },
  toggleIcon(composeState) {
    return composeState === "draft" || composeState === "saving" ? "times" : "chevron-down";
  },
  fullscreenIcon(composeState) {
    return composeState === "draft" ? "chevron-up" : composeState === "fullscreen" ? "discourse-compress" : "discourse-expand";
  },
  showFullScreenButton(disableTextarea) {
    if (this.site.mobileView) {
      return false;
    }
    return !disableTextarea;
  }
}, [["method", "toggleTitle", [discourseComputed("composeState")]], ["method", "toggleToolbarTitle", [discourseComputed("showToolbar")]], ["method", "fullscreenTitle", [discourseComputed("composeState")]], ["method", "toggleIcon", [discourseComputed("composeState")]], ["method", "fullscreenIcon", [discourseComputed("composeState")]], ["method", "showFullScreenButton", [discourseComputed("disableTextarea")]]])));
/* import __COLOCATED_TEMPLATE__ from './history.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { categoryBadgeHTML } from "discourse/helpers/category-link";
import { sanitizeAsync } from "discourse/lib/text";
import Category from "discourse/models/category";
import Post from "discourse/models/post";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import TEMPLATE from "./history.hbs";
import { setComponentTemplate } from "@ember/component";
function customTagArray(val) {
  if (!val) {
    return [];
  }
  if (!Array.isArray(val)) {
    val = [val];
  }
  return val;
}
export default class History extends Component {
  static #_ = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "loading", [tracked]))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "postRevision", [tracked]))();
  #postRevision = (() => (dt7948.i(this, "postRevision"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "viewMode", [tracked], function () {
    return this.site?.mobileView ? "inline" : "side_by_side";
  }))();
  #viewMode = (() => (dt7948.i(this, "viewMode"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "bodyDiff", [tracked]))();
  #bodyDiff = (() => (dt7948.i(this, "bodyDiff"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "initialLoad", [tracked], function () {
    return true;
  }))();
  #initialLoad = (() => (dt7948.i(this, "initialLoad"), void 0))();
  constructor() {
    super(...arguments);
    this.refresh(this.args.model.postId, this.args.model.postVersion);
  }
  get loadFirstDisabled() {
    return this.loading || this.postRevision?.current_revision <= this.postRevision?.first_revision;
  }
  get loadPreviousDisabled() {
    return this.loading || !this.postRevision.previous_revision || !this.postRevision.previous_revision && this.postRevision.current_revision <= this.postRevision.previous_revision;
  }
  get loadNextDisabled() {
    return this.loading || this.postRevision?.current_revision >= this.postRevision?.next_revision;
  }
  get loadLastDisabled() {
    return this.loading || this.postRevision?.current_revision >= this.postRevision?.next_revision;
  }
  get displayRevisions() {
    return this.postRevision?.version_count > 2;
  }
  get modalTitleKey() {
    return this.args.model.post.version > 100 ? "history_capped_revisions" : "history";
  }
  get previousVersion() {
    return this.postRevision?.current_version ? this.postRevision.current_version - 1 : null;
  }
  get revisionsText() {
    return I18n.t("post.revisions.controls.comparing_previous_to_current_out_of_total", {
      previous: this.previousVersion,
      icon: iconHTML("arrows-alt-h"),
      current: this.postRevision?.current_version,
      total: this.postRevision?.version_count
    });
  }
  get titleDiff() {
    let mode = this.viewMode;
    if (mode === "side_by_side_markdown") {
      mode = "side_by_side";
    }
    return this.postRevision?.title_changes?.[mode];
  }
  get bodyDiffHTML() {
    return this.postRevision?.body_changes?.[this.viewMode];
  }
  async calculateBodyDiff(_, bodyDiff) {
    let html = bodyDiff;
    if (this.viewMode !== "side_by_side_markdown") {
      const opts = {
        features: {
          editHistory: true,
          historyOneboxes: true
        },
        allowListed: {
          editHistory: {
            custom: (tag, attr) => attr === "class"
          },
          historyOneboxes: ["header", "article", "div[style]"]
        }
      };
      html = await sanitizeAsync(html, opts);
    }
    this.bodyDiff = html;
  }
  static #_10 = (() => dt7948.n(this.prototype, "calculateBodyDiff", [action]))();
  get previousTagChanges() {
    const previousArray = customTagArray(this.postRevision.tags_changes.previous);
    const currentSet = new Set(customTagArray(this.postRevision.tags_changes.current));
    return previousArray.map(name => ({
      name,
      deleted: !currentSet.has(name)
    }));
  }
  get currentTagChanges() {
    const previousSet = new Set(customTagArray(this.postRevision.tags_changes.previous));
    const currentArray = customTagArray(this.postRevision.tags_changes.current);
    return currentArray.map(name => ({
      name,
      inserted: !previousSet.has(name)
    }));
  }
  get createdAtDate() {
    return moment(this.postRevision.created_at).format("LLLL");
  }
  get displayEdit() {
    return !!(this.postRevision?.can_edit && this.args.model.editPost && this.postRevision?.last_revision === this.postRevision?.current_revision);
  }
  get revertToRevisionText() {
    if (this.previousVersion) {
      return I18n.t("post.revisions.controls.revert", {
        revision: this.previousVersion
      });
    }
  }
  refresh(postId, postVersion) {
    this.loading = true;
    Post.loadRevision(postId, postVersion).then(result => {
      this.postRevision = result;
      this.loading = false;
      this.initialLoad = false;
    });
  }
  hide(postId, postVersion) {
    Post.hideRevision(postId, postVersion).then(() => this.refresh(postId, postVersion));
  }
  show(postId, postVersion) {
    Post.showRevision(postId, postVersion).then(() => this.refresh(postId, postVersion));
  }
  async revert(post, postVersion) {
    try {
      const result = await post.revertToRevision(postVersion);
      this.refresh(post.id, postVersion);
      if (result.topic) {
        post.set("topic.slug", result.topic.slug);
        post.set("topic.title", result.topic.title);
        post.set("topic.fancy_title", result.topic.fancy_title);
      }
      if (result.category_id) {
        post.set("topic.category", await Category.asyncFindById(result.category_id));
      }
      this.args.closeModal();
    } catch (e) {
      if (e.jqXHR.responseJSON?.errors?.[0]) {
        this.dialog.alert(e.jqXHR.responseJSON.errors[0]);
      }
    }
  }
  get editButtonLabel() {
    return `post.revisions.controls.${this.postRevision.wiki ? "edit_wiki" : "edit_post"}`;
  }
  get hiddenClasses() {
    if (this.viewMode === "inline") {
      return this.postRevision?.previous_hidden || this.postRevision?.current_hidden ? "hidden-revision-either" : null;
    } else {
      let result = [];
      if (this.postRevision?.previous_hidden) {
        result.push("hidden-revision-previous");
      }
      if (this.postRevision?.current_hidden) {
        result.push("hidden-revision-current");
      }
      return result.join(" ");
    }
  }
  get previousCategory() {
    if (this.postRevision?.category_id_changes?.previous) {
      let category = Category.findById(this.postRevision.category_id_changes.previous);
      return categoryBadgeHTML(category, {
        allowUncategorized: true,
        extraClasses: "diff-del"
      });
    }
  }
  get currentCategory() {
    if (this.postRevision?.category_id_changes?.current) {
      let category = Category.findById(this.postRevision.category_id_changes.current);
      return categoryBadgeHTML(category, {
        allowUncategorized: true,
        extraClasses: "diff-ins"
      });
    }
  }
  displayInline(event) {
    event?.preventDefault();
    this.viewMode = "inline";
  }
  static #_11 = (() => dt7948.n(this.prototype, "displayInline", [action]))();
  displaySideBySide(event) {
    event?.preventDefault();
    this.viewMode = "side_by_side";
  }
  static #_12 = (() => dt7948.n(this.prototype, "displaySideBySide", [action]))();
  displaySideBySideMarkdown(event) {
    event?.preventDefault();
    this.viewMode = "side_by_side_markdown";
  }
  static #_13 = (() => dt7948.n(this.prototype, "displaySideBySideMarkdown", [action]))();
  loadFirstVersion() {
    this.refresh(this.postRevision.post_id, this.postRevision.first_revision);
  }
  static #_14 = (() => dt7948.n(this.prototype, "loadFirstVersion", [action]))();
  loadPreviousVersion() {
    this.refresh(this.postRevision.post_id, this.postRevision.previous_revision);
  }
  static #_15 = (() => dt7948.n(this.prototype, "loadPreviousVersion", [action]))();
  loadNextVersion() {
    this.refresh(this.postRevision.post_id, this.postRevision.next_revision);
  }
  static #_16 = (() => dt7948.n(this.prototype, "loadNextVersion", [action]))();
  loadLastVersion() {
    return this.refresh(this.postRevision.post_id, this.postRevision.last_revision);
  }
  static #_17 = (() => dt7948.n(this.prototype, "loadLastVersion", [action]))();
  hideVersion() {
    this.hide(this.postRevision.post_id, this.postRevision.current_revision);
  }
  static #_18 = (() => dt7948.n(this.prototype, "hideVersion", [action]))();
  permanentlyDeleteVersions() {
    this.dialog.yesNoConfirm({
      message: I18n.t("post.revisions.controls.destroy_confirm"),
      didConfirm: () => {
        Post.permanentlyDeleteRevisions(this.postRevision.post_id).then(() => {
          this.args.closeModal();
        });
      }
    });
  }
  static #_19 = (() => dt7948.n(this.prototype, "permanentlyDeleteVersions", [action]))();
  showVersion() {
    this.show(this.postRevision.post_id, this.postRevision.current_revision);
  }
  static #_20 = (() => dt7948.n(this.prototype, "showVersion", [action]))();
  editPost() {
    this.args.model.editPost(this.args.model.post);
    this.args.closeModal();
  }
  static #_21 = (() => dt7948.n(this.prototype, "editPost", [action]))();
  revertToVersion() {
    this.revert(this.args.model.post, this.postRevision.current_revision);
  }
  static #_22 = (() => dt7948.n(this.prototype, "revertToVersion", [action]))();
}
setComponentTemplate(TEMPLATE, History);
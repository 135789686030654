/* import __COLOCATED_TEMPLATE__ from './tag-drop-link.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import DiscourseURL from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./tag-drop-link.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "a",
  classNameBindings: [":tag-badge-wrapper", ":badge-wrapper", ":bullet", "tagClass"],
  attributeBindings: ["href"],
  href(tagId, category) {
    let path;
    if (category) {
      path = "/tags" + category.path + "/" + tagId;
    } else {
      path = "/tag/" + tagId;
    }
    return getURL(path);
  },
  tagClass(tagId) {
    return "tag-" + tagId;
  },
  click(e) {
    e.preventDefault();
    DiscourseURL.routeTo(this.href);
    return true;
  }
}, [["method", "href", [discourseComputed("tagId", "category")]], ["method", "tagClass", [discourseComputed("tagId")]]])));
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { buildPermissionDescription } from "discourse/models/permission-type";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class GroupPermissions extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  titleToken() {
    return I18n.t("groups.permissions.title");
  }
  model() {
    let group = this.modelFor("group");
    return ajax(`/g/${group.name}/permissions`).then(permissions => {
      permissions.forEach(permission => {
        permission.description = buildPermissionDescription(permission.permission_type);
      });
      return {
        permissions
      };
    }).catch(() => {
      this.router.transitionTo("group.members", group);
    });
  }
  setupController(controller, model) {
    this.controllerFor("group-permissions").setProperties({
      model
    });
    this.controllerFor("group").set("showing", "permissions");
  }
}
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ActivationEmailForm extends Component {
  newEmailChanged(event1) {
    this.args.updateNewEmail(event1.target.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "newEmailChanged", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <p>{{i18n "login.provide_new_email"}}</p>
      <input
        {{on "input" this.newEmailChanged}}
        value={{@email}}
        type="text"
        class="activate-new-email"
      />
    
  */
  {
    "id": "OVNeBgY3",
    "block": "[[[1,\"\\n    \"],[10,2],[12],[1,[28,[32,0],[\"login.provide_new_email\"],null]],[13],[1,\"\\n    \"],[11,\"input\"],[16,2,[30,1]],[24,0,\"activate-new-email\"],[24,4,\"text\"],[4,[32,1],[\"input\",[30,0,[\"newEmailChanged\"]]],null],[12],[13],[1,\"\\n  \"]],[\"@email\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/activation-email-form.js",
    "scope": () => [i18n, on],
    "isStrictMode": true
  }), this))();
}
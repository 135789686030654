import EmberObject from "@ember/object";
import Mixin from "@ember/object/mixin";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Mixin.create(dt7948.p({
  rejectedPasswords: null,
  init() {
    this._super(...arguments);
    this.set("rejectedPasswords", []);
    this.set("rejectedPasswordsMessages", new Map());
  },
  passwordInstructions() {
    return I18n.t("user.password.instructions", {
      count: this.passwordMinLength
    });
  },
  passwordMinLength(isDeveloper, admin) {
    return isDeveloper || admin ? this.siteSettings.min_admin_password_length : this.siteSettings.min_password_length;
  },
  passwordValidation(password, passwordRequired, rejectedPasswords, accountUsername, accountEmail, passwordMinLength, forceValidationReason) {
    const failedAttrs = {
      failed: true,
      ok: false,
      element: document.querySelector("#new-account-password")
    };
    if (!passwordRequired) {
      return EmberObject.create({
        ok: true
      });
    }
    if (rejectedPasswords.includes(password)) {
      return EmberObject.create(Object.assign(failedAttrs, {
        reason: this.rejectedPasswordsMessages.get(password) || I18n.t("user.password.common")
      }));
    }

    // If blank, fail without a reason
    if (isEmpty(password)) {
      return EmberObject.create(Object.assign(failedAttrs, {
        message: I18n.t("user.password.required"),
        reason: forceValidationReason ? I18n.t("user.password.required") : null
      }));
    }

    // If too short
    if (password.length < passwordMinLength) {
      return EmberObject.create(Object.assign(failedAttrs, {
        reason: I18n.t("user.password.too_short", {
          count: passwordMinLength
        })
      }));
    }
    if (!isEmpty(accountUsername) && password === accountUsername) {
      return EmberObject.create(Object.assign(failedAttrs, {
        reason: I18n.t("user.password.same_as_username")
      }));
    }
    if (!isEmpty(accountEmail) && password === accountEmail) {
      return EmberObject.create(Object.assign(failedAttrs, {
        reason: I18n.t("user.password.same_as_email")
      }));
    }

    // Looks good!
    return EmberObject.create({
      ok: true,
      reason: I18n.t("user.password.ok")
    });
  }
}, [["method", "passwordInstructions", [discourseComputed("passwordMinLength")]], ["method", "passwordMinLength", [discourseComputed("isDeveloper", "admin")]], ["method", "passwordValidation", [discourseComputed("accountPassword", "passwordRequired", "rejectedPasswords.[]", "accountUsername", "accountEmail", "passwordMinLength", "forceValidationReason")]]]));
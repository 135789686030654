/* import __COLOCATED_TEMPLATE__ from './discourse-banner.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { readOnly } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./discourse-banner.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  hide: false,
  banner: readOnly("site.banner"),
  content(bannerHtml) {
    const newDiv = document.createElement("div");
    newDiv.innerHTML = bannerHtml;
    newDiv.querySelectorAll("[id^='heading--']").forEach(el => {
      el.removeAttribute("id");
    });
    return newDiv.innerHTML;
  },
  visible(dismissedBannerKey, bannerKey, hide) {
    dismissedBannerKey = dismissedBannerKey || this.keyValueStore.get("dismissed_banner_key");
    if (bannerKey) {
      bannerKey = parseInt(bannerKey, 10);
    }
    if (dismissedBannerKey) {
      dismissedBannerKey = parseInt(dismissedBannerKey, 10);
    }
    return !hide && bannerKey && dismissedBannerKey !== bannerKey;
  },
  dismiss() {
    if (this.currentUser) {
      this.currentUser.dismissBanner(this.get("banner.key"));
    } else {
      this.set("hide", true);
      this.keyValueStore.set({
        key: "dismissed_banner_key",
        value: this.get("banner.key")
      });
    }
  },
  didInsertElement() {
    this._super(...arguments);
    this.appEvents.trigger("decorate-non-stream-cooked-element", this.element);
  }
}, [["method", "content", [discourseComputed("banner.html")]], ["method", "visible", [discourseComputed("currentUser.dismissed_banner_key", "banner.key", "hide")]], ["method", "dismiss", [action]]])));
/* import __COLOCATED_TEMPLATE__ from './change-timestamp.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";

// Modal related to changing the timestamp of posts
import TEMPLATE from "./change-timestamp.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChangeTimestamp extends Component {
  static #_ = (() => dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  }))();
  #saving = (() => (dt7948.i(this, "saving"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "date", [tracked], function () {
    return moment().format("YYYY-MM-DD");
  }))();
  #date = (() => (dt7948.i(this, "date"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "time", [tracked]))();
  #time = (() => (dt7948.i(this, "time"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "flash", [tracked]))();
  #flash = (() => (dt7948.i(this, "flash"), void 0))();
  get createdAt() {
    return moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm:ss");
  }
  get validTimestamp() {
    return moment().diff(this.createdAt, "minutes") < 0;
  }
  get buttonDisabled() {
    return this.saving || this.validTimestamp || isEmpty(this.date);
  }
  async changeTimestamp() {
    this.saving = true;
    try {
      await Topic.changeTimestamp(this.args.model.topic.id, this.createdAt.unix());
      this.args.closeModal();
      next(() => DiscourseURL.routeTo(this.args.model.topic.url));
    } catch {
      this.flash = I18n.t("topic.change_timestamp.error");
    } finally {
      this.saving = false;
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "changeTimestamp", [action]))();
}
setComponentTemplate(TEMPLATE, ChangeTimestamp);
/* import __COLOCATED_TEMPLATE__ from './second-factor-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { MAX_SECOND_FACTOR_NAME_LENGTH } from "discourse/models/user";
import TEMPLATE from "./second-factor-edit.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SecondFactorEdit extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  maxSecondFactorNameLength = (() => MAX_SECOND_FACTOR_NAME_LENGTH)();
  editSecondFactor() {
    this.loading = true;
    this.args.model.user.updateSecondFactor(this.args.model.secondFactor.id, this.args.model.secondFactor.name, false, this.args.model.secondFactor.method).then(response => {
      if (response.error) {
        return;
      }
      this.args.model.markDirty();
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => {
      this.loading = false;
      this.args.closeModal();
    });
  }
  static #_2 = (() => dt7948.n(this.prototype, "editSecondFactor", [action]))();
}
setComponentTemplate(TEMPLATE, SecondFactorEdit);
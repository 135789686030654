/* import __COLOCATED_TEMPLATE__ from './explain-reviewable.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import TEMPLATE from "./explain-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ExplainReviewable extends Component {
  static #_ = (() => dt7948.g(this.prototype, "store", [service]))();
  #store = (() => (dt7948.i(this, "store"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return true;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "reviewableExplanation", [tracked], function () {
    return null;
  }))();
  #reviewableExplanation = (() => (dt7948.i(this, "reviewableExplanation"), void 0))();
  constructor() {
    super(...arguments);
    this.loadExplanation();
  }
  async loadExplanation() {
    try {
      const result = await this.store.find("reviewable-explanation", this.args.model.reviewable.id);
      this.reviewableExplanation = result;
    } finally {
      this.loading = false;
    }
  }
  static #_4 = (() => dt7948.n(this.prototype, "loadExplanation", [action]))();
}
setComponentTemplate(TEMPLATE, ExplainReviewable);
import Component from "@ember/component";
import { htmlSafe } from "@ember/template";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Component.extend(dt7948.p({
  tagName: "button",
  attributeBindings: ["style", "title"],
  classNameBindings: [":colorpicker", "isUsed:used-color:unused-color"],
  isUsed(color, usedColors) {
    return (usedColors || []).includes(color.toUpperCase());
  },
  title(isUsed) {
    return isUsed ? I18n.t("category.already_used") : null;
  },
  style(color) {
    return htmlSafe(`background-color: #${color};`);
  },
  click(e) {
    e.preventDefault();
    this.selectColor(this.color);
  }
}, [["method", "isUsed", [discourseComputed("color", "usedColors")]], ["method", "title", [discourseComputed("isUsed")]], ["method", "style", [discourseComputed("color")]]]));
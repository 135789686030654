/* import __COLOCATED_TEMPLATE__ from './edit-user-directory-columns.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { reload } from "discourse/helpers/page-reloader";
import { ajax } from "discourse/lib/ajax";
import { extractError, popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./edit-user-directory-columns.hbs";
import { setComponentTemplate } from "@ember/component";
const UP = "up";
const DOWN = "down";
export default class EditUserDirectoryColumns extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return true;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "columns", [tracked]))();
  #columns = (() => (dt7948.i(this, "columns"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "labelKey", [tracked]))();
  #labelKey = (() => (dt7948.i(this, "labelKey"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "flash", [tracked]))();
  #flash = (() => (dt7948.i(this, "flash"), void 0))();
  constructor() {
    super(...arguments);
    this.setupColumns();
  }
  async setupColumns() {
    try {
      const response = await ajax("edit-directory-columns.json");
      this.loading = false;
      this.columns = response.directory_columns.sort((a, b) => a.position > b.position ? 1 : -1).map(c => ({
        ...c,
        enabled: Boolean(c.enabled)
      }));
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "setupColumns", [action]))();
  async save() {
    this.loading = true;
    this.flash = null;
    const data = {
      directory_columns: this.columns.map(c => ({
        id: c.id,
        enabled: c.enabled,
        position: c.position
      }))
    };
    try {
      await ajax("edit-directory-columns.json", {
        type: "PUT",
        data
      });
      reload();
    } catch (e) {
      this.loading = false;
      this.flash = extractError(e);
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "save", [action]))();
  resetToDefault() {
    const resetColumns = this.columns.sort((a, b) => {
      const a1 = a.automatic_position || (a.user_field?.position || 0) + 1000;
      const b1 = b.automatic_position || (b.user_field?.position || 0) + 1000;
      if (a1 === b1) {
        return a.name.localeCompare(b.name);
      } else {
        return a1 > b1 ? 1 : -1;
      }
    }).map((column, index) => ({
      ...column,
      position: column.automatic_position || index + 1,
      enabled: column.type === "automatic"
    }));
    this.columns = resetColumns;
  }
  static #_7 = (() => dt7948.n(this.prototype, "resetToDefault", [action]))();
  moveUp(column) {
    this._moveColumn(UP, column);
  }
  static #_8 = (() => dt7948.n(this.prototype, "moveUp", [action]))();
  moveDown(column) {
    this._moveColumn(DOWN, column);
  }
  static #_9 = (() => dt7948.n(this.prototype, "moveDown", [action]))();
  _moveColumn(direction, column) {
    if (direction === UP && column.position === 1 || direction === DOWN && column.position === this.columns.length) {
      return;
    }
    const positionOnClick = column.position;
    const newPosition = direction === UP ? positionOnClick - 1 : positionOnClick + 1;
    const previousColumn = this.columns.find(c => c.position === newPosition);
    column.position = newPosition;
    previousColumn.position = positionOnClick;
    this.columns = this.columns.sort((a, b) => a.position > b.position ? 1 : -1);
  }
}
setComponentTemplate(TEMPLATE, EditUserDirectoryColumns);
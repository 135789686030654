/* import __COLOCATED_TEMPLATE__ from './avatar-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { isBlank } from "@ember/utils";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./avatar-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, dt7948.p({
  type: "avatar",
  tagName: "span",
  imageIsNotASquare: false,
  customAvatarUploaded() {
    return !this.uploading && !isBlank(this.uploadedAvatarId);
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  uploadDone(upload) {
    this.setProperties({
      imageIsNotASquare: upload.width !== upload.height,
      uploadedAvatarTemplate: upload.url,
      uploadedAvatarId: upload.id
    });
    this.done();
  },
  data(user_id) {
    return {
      user_id
    };
  },
  uploadLabel() {
    return this.uploading ? `${I18n.t("uploading")} ${this.uploadProgress}%` : I18n.t("upload");
  },
  chooseImage() {
    this.fileInputEl.click();
  }
}, [["method", "customAvatarUploaded", [discourseComputed("uploading", "uploadedAvatarId")]], ["method", "data", [discourseComputed("user_id")]], ["method", "uploadLabel", [discourseComputed("uploading", "uploadProgress")]], ["method", "chooseImage", [action]]])));
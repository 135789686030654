import EmberObject from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend(dt7948.p({
  topicsActive() {
    return this.current === "topics";
  },
  repliesActive() {
    return this.current === "replies";
  },
  allActive() {
    return !this.topicsActive && !this.repliesActive;
  },
  repliesButtonLabel() {
    if (this.newRepliesCount > 0) {
      return I18n.t("filters.new.replies_with_count", {
        count: this.newRepliesCount
      });
    } else {
      return I18n.t("filters.new.replies");
    }
  },
  topicsButtonLabel() {
    if (this.newTopicsCount > 0) {
      return I18n.t("filters.new.topics_with_count", {
        count: this.newTopicsCount
      });
    } else {
      return I18n.t("filters.new.topics");
    }
  },
  staticLabel() {
    if (this.noStaticLabel) {
      return null;
    }
    if (this.newTopicsCount > 0 && this.newRepliesCount > 0) {
      return null;
    }
    if (this.newTopicsCount > 0) {
      return this.topicsButtonLabel;
    } else {
      return this.repliesButtonLabel;
    }
  }
}, [["method", "topicsActive", [discourseComputed]], ["method", "repliesActive", [discourseComputed]], ["method", "allActive", [discourseComputed]], ["method", "repliesButtonLabel", [discourseComputed]], ["method", "topicsButtonLabel", [discourseComputed]], ["method", "staticLabel", [discourseComputed]]]));
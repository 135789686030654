import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import PluginOutlet from "discourse/components/plugin-outlet";
import routeAction from "discourse/helpers/route-action";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarFooter extends Component {
  static #_ = (() => dt7948.g(this.prototype, "capabilities", [service]))();
  #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get showManageSectionsButton() {
    return this.currentUser && !this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
  get showToggleMobileButton() {
    return this.site.mobileView || this.siteSettings.enable_mobile_theme && this.capabilities.touch;
  }
  get showKeyboardShortcutsButton() {
    return this.site.desktopView;
  }
  manageSections() {
    this.modal.show(SidebarSectionForm);
  }
  static #_7 = (() => dt7948.n(this.prototype, "manageSections", [action]))();
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="sidebar-footer-wrapper">
        <div class="sidebar-footer-container">
          <div class="sidebar-footer-actions">
            <PluginOutlet @name="sidebar-footer-actions" />
  
            {{#if this.showManageSectionsButton}}
              <DButton
                @icon="plus"
                @action={{this.manageSections}}
                @title="sidebar.sections.custom.add"
                class="btn-flat sidebar-footer-actions-button add-section"
              />
            {{/if}}
  
            {{#if this.showToggleMobileButton}}
              <DButton
                @action={{routeAction "toggleMobileView"}}
                @title={{if this.site.mobileView "desktop_view" "mobile_view"}}
                @icon={{if this.site.mobileView "desktop" "mobile-alt"}}
                class="btn-flat sidebar-footer-actions-button sidebar-footer-actions-toggle-mobile-view"
              />
            {{/if}}
  
            {{#if this.showKeyboardShortcutsButton}}
              <DButton
                @action={{routeAction "showKeyboardShortcutsHelp"}}
                @title="keyboard_shortcuts_help.title"
                @icon="keyboard"
                class="btn-flat sidebar-footer-actions-button sidebar-footer-actions-keyboard-shortcuts"
              />
            {{/if}}
          </div>
        </div>
      </div>
    
  */
  {
    "id": "CIWUi/C+",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-footer-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"sidebar-footer-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-footer-actions\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@name\"],[\"sidebar-footer-actions\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showManageSectionsButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button add-section\"]],[[\"@icon\",\"@action\",\"@title\"],[\"plus\",[30,0,[\"manageSections\"]],\"sidebar.sections.custom.add\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showToggleMobileButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button sidebar-footer-actions-toggle-mobile-view\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[32,2],[\"toggleMobileView\"],null],[52,[30,0,[\"site\",\"mobileView\"]],\"desktop_view\",\"mobile_view\"],[52,[30,0,[\"site\",\"mobileView\"]],\"desktop\",\"mobile-alt\"]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showKeyboardShortcutsButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button sidebar-footer-actions-keyboard-shortcuts\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[32,2],[\"showKeyboardShortcutsHelp\"],null],\"keyboard_shortcuts_help.title\",\"keyboard\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/footer.js",
    "scope": () => [PluginOutlet, DButton, routeAction],
    "isStrictMode": true
  }), this))();
}
import Helper from "@ember/component/helper";
import { scheduleOnce } from "@ember/runloop";
import { service } from "@ember/service";
export default class HideApplicationHeaderButtons extends Helper {
  static #_ = (() => dt7948.g(this.prototype, "header", [service]))();
  #header = (() => (dt7948.i(this, "header"), void 0))();
  registerHider(buttons) {
    this.header.registerHider(this, buttons);
  }
  compute(_ref) {
    let [...buttons] = _ref;
    scheduleOnce("afterRender", this, this.registerHider, buttons);
  }
}
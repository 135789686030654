import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
export default class extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "q", [tracked], function () {
    return "";
  }))();
  #q = (() => (dt7948.i(this, "q"), void 0))();
  queryParams = (() => ["q"])();
  updateTopicsListQueryParams(queryString) {
    this.q = queryString;
  }
  static #_2 = (() => dt7948.n(this.prototype, "updateTopicsListQueryParams", [action]))();
}
/* import __COLOCATED_TEMPLATE__ from './second-factor-edit-security-key.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { MAX_SECOND_FACTOR_NAME_LENGTH } from "discourse/models/user";
import TEMPLATE from "./second-factor-edit-security-key.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SecondFactorEditSecurityKey extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  maxSecondFactorNameLength = (() => MAX_SECOND_FACTOR_NAME_LENGTH)();
  editSecurityKey() {
    this.loading = true;
    this.args.model.user.updateSecurityKey(this.args.model.securityKey.id, this.args.model.securityKey.name, false).then(response => {
      if (response.error) {
        return;
      }
      this.args.model.markDirty();
    }).catch(error => {
      this.args.model.onError(error);
    }).finally(() => {
      this.loading = false;
      this.args.closeModal();
    });
  }
  static #_2 = (() => dt7948.n(this.prototype, "editSecurityKey", [action]))();
}
setComponentTemplate(TEMPLATE, SecondFactorEditSecurityKey);
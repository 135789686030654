/* import __COLOCATED_TEMPLATE__ from './feature-topic-on-profile.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./feature-topic-on-profile.hbs";
import { setComponentTemplate } from "@ember/component";
export default class FeatureTopicOnProfile extends Component {
  static #_ = (() => dt7948.g(this.prototype, "newFeaturedTopic", [tracked], function () {
    return null;
  }))();
  #newFeaturedTopic = (() => (dt7948.i(this, "newFeaturedTopic"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  }))();
  #saving = (() => (dt7948.i(this, "saving"), void 0))();
  get noTopicSelected() {
    return !this.newFeaturedTopic;
  }
  async save() {
    try {
      this.saving = true;
      await ajax(`/u/${this.args.model.user.username}/feature-topic`, {
        type: "PUT",
        data: {
          topic_id: this.newFeaturedTopic.id
        }
      });
      this.args.model.setFeaturedTopic(this.newFeaturedTopic);
      this.args.closeModal();
    } catch (error) {
      popupAjaxError(error);
    } finally {
      this.saving = false;
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "save", [action]))();
  newTopicSelected(topic) {
    this.newFeaturedTopic = topic;
  }
  static #_4 = (() => dt7948.n(this.prototype, "newTopicSelected", [action]))();
}
setComponentTemplate(TEMPLATE, FeatureTopicOnProfile);
/* import __COLOCATED_TEMPLATE__ from './login-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isWebauthnSupported } from "discourse/lib/webauthn";
import { findAll } from "discourse/models/login-method";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./login-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  elementId: "login-buttons",
  classNameBindings: ["hidden"],
  hidden(buttonsCount, showLoginWithEmailLink, showPasskeysButton) {
    return buttonsCount === 0 && !showLoginWithEmailLink && !showPasskeysButton;
  },
  buttons() {
    return findAll();
  },
  showPasskeysButton() {
    return this.siteSettings.enable_local_logins && this.siteSettings.enable_passkeys && this.context === "login" && isWebauthnSupported();
  }
}, [["method", "hidden", [discourseComputed("buttons.length", "showLoginWithEmailLink", "showPasskeysButton")]], ["method", "buttons", [discourseComputed]], ["method", "showPasskeysButton", [discourseComputed]]])));
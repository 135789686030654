/* import __COLOCATED_TEMPLATE__ from './auth-token.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { userPath } from "discourse/lib/url";
import TEMPLATE from "./auth-token.hbs";
import { setComponentTemplate } from "@ember/component";
export default class AuthTokenComponent extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "expanded", [tracked], function () {
    return false;
  }))();
  #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "latestPost", [tracked], function () {
    return null;
  }))();
  #latestPost = (() => (dt7948.i(this, "latestPost"), void 0))();
  constructor() {
    super(...arguments);
    this.fetchActivity();
  }
  async fetchActivity() {
    const posts = await ajax(userPath(`${this.currentUser.username_lower}/activity.json`));
    if (posts.length > 0) {
      this.latestPost = posts[0];
    }
  }
  static #_4 = (() => dt7948.n(this.prototype, "fetchActivity", [action]))();
  toggleExpanded(event) {
    event?.preventDefault();
    this.expanded = !this.expanded;
  }
  static #_5 = (() => dt7948.n(this.prototype, "toggleExpanded", [action]))();
}
setComponentTemplate(TEMPLATE, AuthTokenComponent);
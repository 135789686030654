/* import __COLOCATED_TEMPLATE__ from './category-permission-row.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { alias, equal } from "@ember/object/computed";
import PermissionType from "discourse/models/permission-type";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./category-permission-row.hbs";
const EVERYONE = "everyone";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["permission-row", "row-body"],
  canCreate: equal("type", PermissionType.FULL),
  everyonePermissionType: alias("everyonePermission.permission_type"),
  canReply(value) {
    return value === PermissionType.CREATE_POST || value === PermissionType.FULL;
  },
  canReplyIcon() {
    return this.canReply ? "check-square" : "far-square";
  },
  canCreateIcon() {
    return this.canCreate ? "check-square" : "far-square";
  },
  replyGranted() {
    return this.type <= PermissionType.CREATE_POST ? "reply-granted" : "";
  },
  createGranted() {
    return this.type === PermissionType.FULL ? "create-granted" : "";
  },
  inheritFromEveryone() {
    if (this.group_name === EVERYONE) {
      return;
    }

    // groups cannot have a lesser permission than "everyone"
    if (this.everyonePermissionType < this.type) {
      this.updatePermission(this.everyonePermissionType);
    }
  },
  replyDisabled(everyonePermissionType) {
    if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType <= PermissionType.CREATE_POST) {
      return true;
    }
    return false;
  },
  replyTooltip(replyDisabled) {
    return replyDisabled ? I18n.t("category.permissions.inherited") : I18n.t("category.permissions.toggle_reply");
  },
  createDisabled(everyonePermissionType) {
    if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType === PermissionType.FULL) {
      return true;
    }
    return false;
  },
  createTooltip(createDisabled) {
    return createDisabled ? I18n.t("category.permissions.inherited") : I18n.t("category.permissions.toggle_full");
  },
  updatePermission(type) {
    this.category.updatePermission(this.group_name, type);
  },
  removeRow(event) {
    event?.preventDefault();
    this.category.removePermission(this.group_name);
  },
  actions: {
    setPermissionReply() {
      if (this.type <= PermissionType.CREATE_POST) {
        this.updatePermission(PermissionType.READONLY);
      } else {
        this.updatePermission(PermissionType.CREATE_POST);
      }
    },
    setPermissionFull() {
      if (this.group_name !== EVERYONE && this.everyonePermissionType === PermissionType.FULL) {
        return;
      }
      if (this.type === PermissionType.FULL) {
        this.updatePermission(PermissionType.CREATE_POST);
      } else {
        this.updatePermission(PermissionType.FULL);
      }
    }
  }
}, [["method", "canReply", [discourseComputed("type")]], ["method", "canReplyIcon", [discourseComputed("type")]], ["method", "canCreateIcon", [discourseComputed("type")]], ["method", "replyGranted", [discourseComputed("type")]], ["method", "createGranted", [discourseComputed("type")]], ["method", "inheritFromEveryone", [observes("everyonePermissionType")]], ["method", "replyDisabled", [discourseComputed("everyonePermissionType", "type")]], ["method", "replyTooltip", [discourseComputed("replyDisabled")]], ["method", "createDisabled", [discourseComputed("everyonePermissionType", "type")]], ["method", "createTooltip", [discourseComputed("createDisabled")]], ["method", "removeRow", [action]]])));
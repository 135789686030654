import Component from "@glimmer/component";
import { service } from "@ember/service";
import ApiSections from "../api-sections";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import MessagesSection from "./messages-section";
import TagsSection from "./tags-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarUserSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="sidebar-sections">
        <CustomSections @collapsable={{@collapsableSections}} />
        <CategoriesSection @collapsable={{@collapsableSections}} />
  
        {{#if this.currentUser.display_sidebar_tags}}
          <TagsSection @collapsable={{@collapsableSections}} />
        {{/if}}
  
        {{#if this.currentUser.can_send_private_messages}}
          <MessagesSection @collapsable={{@collapsableSections}} />
        {{/if}}
  
        {{#unless @hideApiSections}}
          <ApiSections @collapsable={{@collapsableSections}} />
        {{/unless}}
      </div>
    
  */
  {
    "id": "czksMkoJ",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-sections\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"display_sidebar_tags\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"can_send_private_messages\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,2]],[[[1,\"        \"],[8,[32,4],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\",\"@hideApiSections\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/sections.js",
    "scope": () => [CustomSections, CategoriesSection, TagsSection, MessagesSection, ApiSections],
    "isStrictMode": true
  }), this))();
}
import { service } from "@ember/service";
import UserMenuItemsList from "discourse/components/user-menu/items-list";
import { ajax } from "discourse/lib/ajax";
import UserMenuReviewableItem from "discourse/lib/user-menu/reviewable-item";
import UserMenuReviewable from "discourse/models/user-menu-reviewable";
import getUrl from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
export default class UserMenuReviewablesList extends UserMenuItemsList {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  get showAllHref() {
    return getUrl("/review");
  }
  get showAllTitle() {
    return I18n.t("user_menu.reviewable.view_all");
  }
  get itemsCacheKey() {
    return "pending-reviewables";
  }
  fetchItems() {
    return ajax("/review/user-menu-list").then(data => {
      this.currentUser.updateReviewableCount(data.reviewable_count);
      return data.reviewables.map(item => {
        return new UserMenuReviewableItem({
          reviewable: UserMenuReviewable.create(item),
          currentUser: this.currentUser,
          siteSettings: this.siteSettings,
          site: this.site
        });
      });
    });
  }
}
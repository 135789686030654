/* import __COLOCATED_TEMPLATE__ from './backup-codes.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { slugify, toAsciiPrintable } from "discourse/lib/utilities";
import discourseComputed from "discourse-common/utils/decorators";

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
import TEMPLATE from "./backup-codes.hbs";
function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
    return String.fromCharCode("0x" + p1);
  }));
}
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["backup-codes"],
  backupCodes: null,
  click(event) {
    if (event.target.id === "backupCodes") {
      this._selectAllBackupCodes();
    }
  },
  didRender() {
    this._super(...arguments);
    const backupCodes = this.element.querySelector("#backupCodes");
    if (backupCodes) {
      backupCodes.style.height = backupCodes.scrollHeight;
    }
  },
  base64BackupCode: b64EncodeUnicode,
  formattedBackupCodes(backupCodes) {
    if (!backupCodes) {
      return null;
    }
    return backupCodes.join("\n").trim();
  },
  siteTitleSlug() {
    const title = this.siteSettings.title;
    const convertedTitle = toAsciiPrintable(title, "discourse");
    return slugify(convertedTitle);
  },
  actions: {
    copyToClipboard() {
      this._selectAllBackupCodes();
      this.copyBackupCode(document.execCommand("copy"));
    }
  },
  _selectAllBackupCodes() {
    const textArea = this.element.querySelector("#backupCodes");
    textArea.focus();
    textArea.setSelectionRange(0, this.formattedBackupCodes.length);
  }
}, [["field", "base64BackupCode", [discourseComputed("formattedBackupCodes")]], ["method", "formattedBackupCodes", [discourseComputed("backupCodes")]], ["method", "siteTitleSlug", [discourseComputed()]]])));
import Component from "@glimmer/component";
import { getOwner } from "@ember/application";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import UserTipContainer from "discourse/components/user-tip-container";
import escape from "discourse-common/lib/escape";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import DTooltipInstance from "float-kit/lib/d-tooltip-instance";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserTip extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "userTips", [service]))();
  #userTips = (() => (dt7948.i(this, "userTips"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "tooltip", [service]))();
  #tooltip = (() => (dt7948.i(this, "tooltip"), void 0))();
  registerTip = (() => modifier(() => {
    const tip1 = {
      id: this.args.id,
      priority: this.args.priority ?? 0
    };
    this.userTips.addAvailableTip(tip1);
    return () => {
      this.userTips.removeAvailableTip(tip1);
    };
  }))();
  tip = (() => modifier(element1 => {
    let instance1;
    schedule("afterRender", () => {
      const trigger1 = this.args.triggerSelector && document.querySelector(this.args.triggerSelector);
      let buttonText1 = escape(I18n.t(this.args.buttonLabel || "user_tips.button"));
      if (this.args.buttonIcon) {
        buttonText1 = `${iconHTML(this.args.buttonIcon)} ${buttonText1}`;
      }
      instance1 = new DTooltipInstance(getOwner(this), {
        identifier: "user-tip",
        interactive: true,
        closeOnScroll: false,
        closeOnClickOutside: true,
        placement: this.args.placement,
        component: UserTipContainer,
        data: {
          id: this.args.id,
          titleText: escape(this.args.titleText),
          contentHtml: this.args.contentHtml || null,
          contentText: this.args.contentText ? escape(this.args.contentText) : null,
          buttonText: buttonText1,
          buttonSkipText: I18n.t("user_tips.skip"),
          showSkipButton: this.args.showSkipButton
        }
      });
      instance1.trigger = trigger1 || element1;
      instance1.detachedTrigger = true;
      this.tooltip.show(instance1);
      if (this.shouldRenderTip) {
        // mark tooltip directly as seen so that
        // refreshing, clicking outside, etc. won't show it again
        this.userTips.markAsSeen(this.args.id);
      }
    });
    return () => {
      instance1?.destroy();
    };
  }))();
  get shouldRenderTip() {
    return this.userTips.shouldRender(this.args.id);
  }
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div {{this.registerTip}}>
        {{#if this.shouldRenderTip}}
          <span {{this.tip}}></span>
        {{/if}}
      </div>
    
  */
  {
    "id": "BWjr9u13",
    "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"registerTip\"]],null,null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRenderTip\"]],[[[1,\"        \"],[11,1],[4,[30,0,[\"tip\"]],null,null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-tip.js",
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { eq } from "truth-helpers";
import DAG from "discourse/lib/dag";
import getURL from "discourse-common/lib/get-url";
import Dropdown from "./dropdown";
import UserDropdown from "./user-dropdown";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let headerIcons;
resetHeaderIcons();
function resetHeaderIcons() {
  headerIcons = new DAG({
    defaultPosition: {
      before: "search"
    }
  });
  headerIcons.add("search");
  headerIcons.add("hamburger", undefined, {
    after: "search"
  });
  headerIcons.add("user-menu", undefined, {
    after: "hamburger"
  });
}
export function headerIconsDAG() {
  return headerIcons;
}
export function clearExtraHeaderIcons() {
  resetHeaderIcons();
}
export default class Icons extends Component {
  static #_ = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "header", [service]))();
  #header = (() => (dt7948.i(this, "header"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "search", [service]))();
  #search = (() => (dt7948.i(this, "search"), void 0))();
  get showHamburger() {
    // NOTE: In this scenario, we are forcing the sidebar on admin users,
    // so we need to still show the hamburger menu to be able to
    // access the legacy hamburger forum menu.
    if (this.args.sidebarEnabled && this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu) {
      return true;
    }
    return !this.args.sidebarEnabled || this.site.mobileView;
  }
  get hideSearchButton() {
    return this.header.headerButtonsHidden.includes("search");
  }
  toggleHamburger() {
    if (this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu) {
      this.args.toggleNavigationMenu("hamburger");
    } else {
      this.args.toggleNavigationMenu();
    }
  }
  static #_7 = (() => dt7948.n(this.prototype, "toggleHamburger", [action]))();
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <ul class="icons d-header-icons">
        {{#each (headerIcons.resolve) as |entry|}}
          {{#if (eq entry.key "search")}}
            {{#unless this.hideSearchButton}}
              <Dropdown
                @title="search.title"
                @icon="search"
                @iconId={{@searchButtonId}}
                @onClick={{@toggleSearchMenu}}
                @active={{this.search.visible}}
                @href={{getURL "/search"}}
                @className="search-dropdown"
                @targetSelector=".search-menu-panel"
              />
            {{/unless}}
          {{else if (eq entry.key "hamburger")}}
            {{#if this.showHamburger}}
              <Dropdown
                @title="hamburger_menu"
                @icon="bars"
                @iconId="toggle-hamburger-menu"
                @active={{this.header.hamburgerVisible}}
                @onClick={{this.toggleHamburger}}
                @className="hamburger-dropdown"
              />
            {{/if}}
          {{else if (eq entry.key "user-menu")}}
            {{#if this.currentUser}}
              <UserDropdown
                @active={{this.header.userVisible}}
                @toggleUserMenu={{@toggleUserMenu}}
              />
            {{/if}}
          {{else if entry.value}}
            <entry.value />
          {{/if}}
        {{/each}}
      </ul>
    
  */
  {
    "id": "fX8WXBDv",
    "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"icons d-header-icons\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0,[\"resolve\"]],null,null]],null]],null],null,[[[41,[28,[32,1],[[30,1,[\"key\"]],\"search\"],null],[[[41,[51,[30,0,[\"hideSearchButton\"]]],[[[1,\"            \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@onClick\",\"@active\",\"@href\",\"@className\",\"@targetSelector\"],[\"search.title\",\"search\",[30,2],[30,3],[30,0,[\"search\",\"visible\"]],[28,[32,3],[\"/search\"],null],\"search-dropdown\",\".search-menu-panel\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"hamburger\"],null],[[[41,[30,0,[\"showHamburger\"]],[[[1,\"            \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@active\",\"@onClick\",\"@className\"],[\"hamburger_menu\",\"bars\",\"toggle-hamburger-menu\",[30,0,[\"header\",\"hamburgerVisible\"]],[30,0,[\"toggleHamburger\"]],\"hamburger-dropdown\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"user-menu\"],null],[[[41,[30,0,[\"currentUser\"]],[[[1,\"            \"],[8,[32,4],null,[[\"@active\",\"@toggleUserMenu\"],[[30,0,[\"header\",\"userVisible\"]],[30,4]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,1,[\"value\"]],[[[1,\"          \"],[8,[30,1,[\"value\"]],null,null,null],[1,\"\\n        \"]],[]],null]],[]]]],[]]]],[]]]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"entry\",\"@searchButtonId\",\"@toggleSearchMenu\",\"@toggleUserMenu\"],false,[\"each\",\"-track-array\",\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/icons.js",
    "scope": () => [headerIcons, eq, Dropdown, getURL, UserDropdown],
    "isStrictMode": true
  }), this))();
}
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { gt } from "@ember/object/computed";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed, { debounce, observes } from "discourse-common/utils/decorators";
export default Controller.extend(dt7948.p({
  queryParams: ["order", "asc", "filter"],
  order: null,
  asc: true,
  filter: null,
  filterInput: null,
  loading: false,
  isBulk: false,
  showActions: false,
  bulkSelection: null,
  get canLoadMore() {
    return this.get("model.members")?.length < this.get("model.user_count");
  },
  filterInputChanged() {
    this._setFilter();
  },
  _setFilter() {
    this.set("filter", this.filterInput);
  },
  _filtersChanged() {
    this.reloadMembers(true);
  },
  reloadMembers(refresh) {
    if (this.loading || !this.model) {
      return;
    }
    if (!refresh && !this.canLoadMore) {
      return;
    }
    this.set("loading", true);
    this.model.reloadMembers(this.memberParams, refresh).finally(() => {
      this.set("loading", false);
      if (this.refresh) {
        this.set("bulkSelection", []);
      }
    });
  },
  memberParams(order, asc, filter) {
    return {
      order,
      asc,
      filter
    };
  },
  hasMembers: gt("model.members.length", 0),
  canManageGroup(model) {
    return this.currentUser && this.currentUser.canManageGroup(model);
  },
  filterPlaceholder() {
    if (this.currentUser && this.currentUser.admin) {
      return "groups.members.filter_placeholder_admin";
    } else {
      return "groups.members.filter_placeholder";
    }
  },
  emptyMessageKey(filter, members, canSeeMembers) {
    if (!canSeeMembers) {
      return "groups.members.forbidden";
    } else if (filter) {
      return "groups.members.no_filter_matches";
    } else {
      return "groups.empty.members";
    }
  },
  loadMore() {
    this.reloadMembers();
  },
  toggleActions() {
    this.toggleProperty("showActions");
  },
  actOnGroup(member, actionId) {
    switch (actionId) {
      case "removeMember":
        this.removeMember(member);
        break;
      case "makeOwner":
        this.makeOwner(member.username);
        break;
      case "removeOwner":
        this.removeOwner(member);
        break;
      case "makePrimary":
        member.setPrimaryGroup(this.model.id).then(() => member.set("primary", true));
        break;
      case "removePrimary":
        member.setPrimaryGroup(null).then(() => member.set("primary", false));
        break;
    }
  },
  actOnSelection(selection, actionId) {
    if (!selection || selection.length === 0) {
      return;
    }
    switch (actionId) {
      case "removeMembers":
        return ajax(`/groups/${this.model.id}/members.json`, {
          type: "DELETE",
          data: {
            user_ids: selection.mapBy("id").join(",")
          }
        }).then(() => {
          this.model.reloadMembers(this.memberParams, true);
          this.set("isBulk", false);
        });
      case "makeOwners":
        return ajax(`/groups/${this.model.id}/owners.json`, {
          type: "PUT",
          data: {
            usernames: selection.mapBy("username").join(",")
          }
        }).then(() => {
          selection.forEach(s => s.set("owner", true));
          this.set("isBulk", false);
        });
      case "removeOwners":
        return ajax(`/admin/groups/${this.model.id}/owners.json`, {
          type: "DELETE",
          data: {
            group: {
              usernames: selection.map(u => u.username).join(",")
            }
          }
        }).then(() => {
          selection.forEach(s => s.set("owner", false));
          this.set("isBulk", false);
        });
      case "setPrimary":
      case "unsetPrimary":
        const primary = actionId === "setPrimary";
        return ajax(`/admin/groups/${this.model.id}/primary.json`, {
          type: "PUT",
          data: {
            group: {
              usernames: selection.map(u => u.username).join(",")
            },
            primary
          }
        }).then(() => {
          selection.forEach(s => s.set("primary", primary));
          this.set("isBulk", false);
        });
    }
  },
  removeMember(user) {
    this.model.removeMember(user, this.memberParams);
  },
  makeOwner(username) {
    this.model.addOwners(username);
  },
  removeOwner(user) {
    this.model.removeOwner(user);
  },
  addMembers() {
    if (this.usernames && this.usernames.length > 0) {
      this.model.addMembers(this.usernames).then(() => this.set("usernames", [])).catch(popupAjaxError);
    }
  },
  toggleBulkSelect() {
    this.setProperties({
      isBulk: !this.isBulk,
      bulkSelection: []
    });
  },
  bulkSelectAll() {
    document.querySelectorAll("input.bulk-select:not(:checked)").forEach(checkbox => {
      if (!checkbox.checked) {
        checkbox.click();
      }
    });
  },
  bulkClearAll() {
    document.querySelectorAll("input.bulk-select:checked").forEach(checkbox => {
      if (checkbox.checked) {
        checkbox.click();
      }
    });
  },
  selectMember(member, e) {
    this.set("bulkSelection", this.bulkSelection || []);
    if (e.target.checked) {
      this.bulkSelection.pushObject(member);
    } else {
      this.bulkSelection.removeObject(member);
    }
  }
}, [["method", "filterInputChanged", [observes("filterInput")]], ["method", "_setFilter", [debounce(500)]], ["method", "_filtersChanged", [observes("order", "asc", "filter")]], ["method", "memberParams", [discourseComputed("order", "asc", "filter")]], ["method", "canManageGroup", [discourseComputed("model")]], ["method", "filterPlaceholder", [discourseComputed]], ["method", "emptyMessageKey", [discourseComputed("filter", "members", "model.can_see_members")]], ["method", "loadMore", [action]], ["method", "toggleActions", [action]], ["method", "actOnGroup", [action]], ["method", "actOnSelection", [action]], ["method", "removeMember", [action]], ["method", "makeOwner", [action]], ["method", "removeOwner", [action]], ["method", "addMembers", [action]], ["method", "toggleBulkSelect", [action]], ["method", "bulkSelectAll", [action]], ["method", "bulkClearAll", [action]], ["method", "selectMember", [action]]]));
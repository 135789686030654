/* import __COLOCATED_TEMPLATE__ from './change-post-notice.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { cook } from "discourse/lib/text";
import TEMPLATE from "./change-post-notice.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChangePostNoticeModal extends Component {
  static #_ = (() => dt7948.g(this.prototype, "post", [tracked], function () {
    return this.args.model.post;
  }))();
  #post = (() => (dt7948.i(this, "post"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "notice", [tracked], function () {
    return this.args.model.post.notice?.raw ?? "";
  }))();
  #notice = (() => (dt7948.i(this, "notice"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  }))();
  #saving = (() => (dt7948.i(this, "saving"), void 0))();
  resolve = (() => this.args.model.resolve)();
  reject = (() => this.args.model.reject)();
  get disabled() {
    return this.saving || isEmpty(this.notice) || this.notice === this.post.notice?.raw;
  }
  saveNotice() {
    this.setNotice(this.notice);
  }
  static #_4 = (() => dt7948.n(this.prototype, "saveNotice", [action]))();
  deleteNotice() {
    this.setNotice();
  }
  static #_5 = (() => dt7948.n(this.prototype, "deleteNotice", [action]))();
  setNotice(notice) {
    const {
      resolve,
      reject
    } = this;
    this.saving = true;
    this.resolve = null;
    this.reject = null;
    this.post.updatePostField("notice", notice).then(() => {
      if (notice) {
        return cook(notice, {
          features: {
            onebox: false
          }
        });
      }
    }).then(cooked => this.post.set("notice", cooked ? {
      type: "custom",
      raw: notice,
      cooked: cooked.toString()
    } : null)).then(resolve, reject).finally(() => this.args.closeModal());
  }
  static #_6 = (() => dt7948.n(this.prototype, "setNotice", [action]))();
}
setComponentTemplate(TEMPLATE, ChangePostNoticeModal);
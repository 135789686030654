/* import __COLOCATED_TEMPLATE__ from './edit-category-settings.hbs'; */
import { action } from "@ember/object";
import { and, empty } from "@ember/object/computed";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import { setting } from "discourse/lib/computed";
import { SEARCH_PRIORITIES } from "discourse/lib/constants";
import Group from "discourse/models/group";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./edit-category-settings.hbs";
import { setComponentTemplate } from "@ember/component";
const categorySortCriteria = [];
export function addCategorySortCriteria(criteria) {
  categorySortCriteria.push(criteria);
}
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("settings", dt7948.p({
  emailInEnabled: setting("email_in"),
  showPositionInput: setting("fixed_category_positions"),
  isParentCategory(isParent, parentCategoryId) {
    return isParent || !parentCategoryId;
  },
  showSubcategoryListStyle: and("category.show_subcategory_list", "isParentCategory"),
  isDefaultSortOrder: empty("category.sort_order"),
  availableSubcategoryListStyles() {
    return [{
      name: I18n.t("category.subcategory_list_styles.rows"),
      value: "rows"
    }, {
      name: I18n.t("category.subcategory_list_styles.rows_with_featured_topics"),
      value: "rows_with_featured_topics"
    }, {
      name: I18n.t("category.subcategory_list_styles.boxes"),
      value: "boxes"
    }, {
      name: I18n.t("category.subcategory_list_styles.boxes_with_featured_topics"),
      value: "boxes_with_featured_topics"
    }];
  },
  groupFinder(term) {
    return Group.findAll({
      term,
      ignore_automatic: true
    });
  },
  availableViews() {
    return [{
      name: I18n.t("filters.latest.title"),
      value: "latest"
    }, {
      name: I18n.t("filters.top.title"),
      value: "top"
    }];
  },
  availableTopPeriods() {
    return ["all", "yearly", "quarterly", "monthly", "weekly", "daily"].map(p => {
      return {
        name: I18n.t(`filters.top.${p}.title`),
        value: p
      };
    });
  },
  availableListFilters() {
    return ["all", "none"].map(p => {
      return {
        name: I18n.t(`category.list_filters.${p}`),
        value: p
      };
    });
  },
  searchPrioritiesOptions() {
    const options = [];
    Object.entries(SEARCH_PRIORITIES).forEach(entry => {
      const [name, value] = entry;
      options.push({
        name: I18n.t(`category.search_priority.options.${name}`),
        value
      });
    });
    return options;
  },
  availableSorts() {
    return ["likes", "op_likes", "views", "posts", "activity", "posters", "category", "created"].concat(categorySortCriteria).map(s => ({
      name: I18n.t("category.sort_options." + s),
      value: s
    })).sort((a, b) => a.name.localeCompare(b.name));
  },
  sortAscendingOption(sortAscending) {
    if (sortAscending === "false") {
      return false;
    }
    if (sortAscending === "true") {
      return true;
    }
    return sortAscending;
  },
  sortAscendingOptions() {
    return [{
      name: I18n.t("category.sort_ascending"),
      value: true
    }, {
      name: I18n.t("category.sort_descending"),
      value: false
    }];
  },
  hiddenRelativeIntervals() {
    return ["mins"];
  },
  onAutoCloseDurationChange(minutes) {
    let hours = minutes ? minutes / 60 : null;
    this.set("category.auto_close_hours", hours);
  },
  onDefaultSlowModeDurationChange(minutes) {
    let seconds = minutes ? minutes * 60 : null;
    this.set("category.default_slow_mode_seconds", seconds);
  }
}, [["method", "isParentCategory", [discourseComputed("category.isParent", "category.parent_category_id")]], ["method", "availableSubcategoryListStyles", [discourseComputed]], ["method", "availableViews", [discourseComputed]], ["method", "availableTopPeriods", [discourseComputed]], ["method", "availableListFilters", [discourseComputed]], ["method", "searchPrioritiesOptions", [discourseComputed]], ["method", "availableSorts", [discourseComputed]], ["method", "sortAscendingOption", [discourseComputed("category.sort_ascending")]], ["method", "sortAscendingOptions", [discourseComputed]], ["method", "hiddenRelativeIntervals", [discourseComputed]], ["method", "onAutoCloseDurationChange", [action]], ["method", "onDefaultSlowModeDurationChange", [action]]])));
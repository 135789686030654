/* import __COLOCATED_TEMPLATE__ from './pwa-install-banner.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed, { bind, on } from "discourse-common/utils/decorators";
import TEMPLATE from "./pwa-install-banner.hbs";
const USER_DISMISSED_PROMPT_KEY = "dismissed-pwa-install-banner";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  deferredInstallPromptEvent: null,
  _onInstallPrompt(event) {
    // Prevent Chrome 76+ from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later
    this.set("deferredInstallPromptEvent", event);
  },
  _registerListener() {
    window.addEventListener("beforeinstallprompt", this._onInstallPrompt);
  },
  _unregisterListener() {
    window.removeEventListener("beforeinstallprompt", this._onInstallPrompt);
  },
  bannerDismissed: {
    set(value) {
      this.keyValueStore.set({
        key: USER_DISMISSED_PROMPT_KEY,
        value
      });
      return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
    },
    get() {
      return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
    }
  },
  showPWAInstallBanner(deferredInstallPromptEvent, bannerDismissed) {
    return this.capabilities.isAndroid && this.get("currentUser.trust_level") > 0 && deferredInstallPromptEvent &&
    // Pass the browser engagement checks
    !window.matchMedia("(display-mode: standalone)").matches &&
    // Not be in the installed PWA already
    !this.capabilities.isAppWebview &&
    // not launched via official app
    !bannerDismissed // Have not a previously dismissed install banner
    ;
  },
  actions: {
    turnOn() {
      this.set("bannerDismissed", true);
      this.deferredInstallPromptEvent.prompt();
    },
    dismiss() {
      this.set("bannerDismissed", true);
    }
  }
}, [["method", "_onInstallPrompt", [bind]], ["method", "_registerListener", [on("didInsertElement")]], ["method", "_unregisterListener", [on("willDestroyElement")]], ["field", "bannerDismissed", [discourseComputed]], ["method", "showPWAInstallBanner", [discourseComputed("deferredInstallPromptEvent", "bannerDismissed")]]])));
import { action } from "@ember/object";
import Service from "@ember/service";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
const LoginService = dt7948.c(class LoginService extends Service {
  async externalLogin(loginMethod) {
    let {
      signup = false,
      setLoggingIn = null
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      setLoggingIn?.(true);
      await loginMethod.doLogin({
        signup
      });
    } catch {
      setLoggingIn?.(false);
    }
  }
  static #_ = (() => dt7948.n(this.prototype, "externalLogin", [action]))();
}, [disableImplicitInjections]);
export default LoginService;
import { tracked } from "@glimmer/tracking";
import { setOwner } from "@ember/application";
import { service } from "@ember/service";
import AboutSectionLink from "discourse/lib/sidebar/common/community-section/about-section-link";
import BadgesSectionLink from "discourse/lib/sidebar/common/community-section/badges-section-link";
import EverythingSectionLink from "discourse/lib/sidebar/common/community-section/everything-section-link";
import FAQSectionLink from "discourse/lib/sidebar/common/community-section/faq-section-link";
import GroupsSectionLink from "discourse/lib/sidebar/common/community-section/groups-section-link";
import UsersSectionLink from "discourse/lib/sidebar/common/community-section/users-section-link";
import { customSectionLinks, secondaryCustomSectionLinks } from "discourse/lib/sidebar/custom-community-section-links";
import SectionLink from "discourse/lib/sidebar/section-link";
import AdminSectionLink from "discourse/lib/sidebar/user/community-section/admin-section-link";
import MyPostsSectionLink from "discourse/lib/sidebar/user/community-section/my-posts-section-link";
import ReviewSectionLink from "discourse/lib/sidebar/user/community-section/review-section-link";
const SPECIAL_LINKS_MAP = {
  "/latest": EverythingSectionLink,
  "/about": AboutSectionLink,
  "/u": UsersSectionLink,
  "/faq": FAQSectionLink,
  "/my/activity": MyPostsSectionLink,
  "/review": ReviewSectionLink,
  "/badges": BadgesSectionLink,
  "/admin": AdminSectionLink,
  "/g": GroupsSectionLink
};
export default class CommunitySection {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "links", [tracked]))();
  #links = (() => (dt7948.i(this, "links"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "moreLinks", [tracked]))();
  #moreLinks = (() => (dt7948.i(this, "moreLinks"), void 0))();
  hideSectionHeader = true;
  constructor(_ref) {
    let {
      section,
      owner
    } = _ref;
    setOwner(this, owner);
    this.section = section;
    this.slug = section.slug;
    this.callbackId = this.topicTrackingState?.onStateChange(() => {
      this.links.forEach(link => {
        if (link.onTopicTrackingStateChange) {
          link.onTopicTrackingStateChange();
        }
      });
    });
    this.apiLinks = customSectionLinks.concat(secondaryCustomSectionLinks).map(link => this.#initializeSectionLink(link, {
      inMoreDrawer: true
    }));
    this.links = this.section.links.reduce((filtered, link) => {
      if (link.segment === "primary") {
        const generatedLink = this.#generateLink(link);
        if (generatedLink) {
          filtered.push(generatedLink);
        }
      }
      return filtered;
    }, []);
    this.moreLinks = this.section.links.reduce((filtered, link) => {
      if (link.segment === "secondary") {
        const generatedLink = this.#generateLink(link, true);
        if (generatedLink) {
          filtered.push(generatedLink);
        }
      }
      return filtered;
    }, []).concat(this.apiLinks);
  }
  teardown() {
    if (this.callbackId) {
      this.topicTrackingState.offStateChange(this.callbackId);
    }
    [...this.links, ...this.moreLinks].forEach(sectionLink => {
      sectionLink.teardown?.();
    });
  }
  #generateLink(link) {
    let inMoreDrawer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const sectionLinkClass = SPECIAL_LINKS_MAP[link.value];
    if (sectionLinkClass) {
      return this.#initializeSectionLink(sectionLinkClass, inMoreDrawer, link.name, link.icon);
    } else {
      return new SectionLink(link, this, this.router);
    }
  }
  #initializeSectionLink(sectionLinkClass, inMoreDrawer, overridenName, overridenIcon) {
    if (this.router.isDestroying) {
      return;
    }
    return new sectionLinkClass({
      topicTrackingState: this.topicTrackingState,
      currentUser: this.currentUser,
      appEvents: this.appEvents,
      router: this.router,
      siteSettings: this.siteSettings,
      inMoreDrawer,
      overridenName,
      overridenIcon
    });
  }
}
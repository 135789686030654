import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { keyValueStore as pushNotificationKeyValueStore } from "discourse/lib/push-notifications";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const userDismissedPromptKey = "dismissed-prompt";
export default class NotificationConsentBanner extends Component {
  static #_ = (() => dt7948.g(this.prototype, "capabilities", [service]))();
  #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "desktopNotifications", [service]))();
  #desktopNotifications = (() => (dt7948.i(this, "desktopNotifications"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "bannerDismissed", [tracked]))();
  #bannerDismissed = (() => (dt7948.i(this, "bannerDismissed"), void 0))();
  constructor() {
    super(...arguments);
    this.bannerDismissed = pushNotificationKeyValueStore.getItem(userDismissedPromptKey);
  }
  setBannerDismissed(value1) {
    pushNotificationKeyValueStore.setItem(userDismissedPromptKey, value1);
    this.bannerDismissed = pushNotificationKeyValueStore.getItem(userDismissedPromptKey);
  }
  get showNotificationPromptBanner() {
    return this.siteSettings.push_notifications_prompt && !this.desktopNotifications.isNotSupported && this.currentUser && this.capabilities.isPwa && Notification.permission !== "denied" && Notification.permission !== "granted" && !this.desktopNotifications.isEnabled && !this.bannerDismissed;
  }
  turnon() {
    this.desktopNotifications.enable();
    this.setBannerDismissed(true);
  }
  static #_6 = (() => dt7948.n(this.prototype, "turnon", [action]))();
  dismiss() {
    this.setBannerDismissed(false);
  }
  static #_7 = (() => dt7948.n(this.prototype, "dismiss", [action]))();
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.showNotificationPromptBanner}}
        <div class="row">
          <div class="consent_banner alert alert-info">
            <span>
              {{i18n "user.desktop_notifications.consent_prompt"}}
              <DButton
                @display="link"
                @action={{this.turnon}}
                @label="user.desktop_notifications.enable"
              />
            </span>
            <DButton
              @icon="times"
              @action={{this.dismiss}}
              @title="banner.close"
              class="btn-transparent close"
            />
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "A+Yy3kFq",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showNotificationPromptBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"consent_banner alert alert-info\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"user.desktop_notifications.consent_prompt\"],null]],[1,\"\\n            \"],[8,[32,1],null,[[\"@display\",\"@action\",\"@label\"],[\"link\",[30,0,[\"turnon\"]],\"user.desktop_notifications.enable\"]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,0,[\"dismiss\"]],\"banner.close\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/notification-consent-banner.js",
    "scope": () => [i18n, DButton],
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import TopicEntrance from "discourse/components/topic-list/topic-entrance";
import element from "discourse/helpers/element";
import number from "discourse/helpers/number";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class PostsCountColumn extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  get ratio() {
    const likes1 = parseFloat(this.args.topic.like_count);
    const posts1 = parseFloat(this.args.topic.posts_count);
    if (posts1 < 10) {
      return 0;
    }
    return (likes1 || 0) / posts1;
  }
  get title() {
    return I18n.messageFormat("posts_likes_MF", {
      count: this.args.topic.replyCount,
      ratio: this.ratioText
    }).trim();
  }
  get ratioText() {
    if (this.ratio > this.siteSettings.topic_post_like_heat_high) {
      return "high";
    }
    if (this.ratio > this.siteSettings.topic_post_like_heat_medium) {
      return "med";
    }
    if (this.ratio > this.siteSettings.topic_post_like_heat_low) {
      return "low";
    }
    return "";
  }
  get likesHeat() {
    if (this.ratioText?.length) {
      return `heatmap-${this.ratioText}`;
    }
  }
  get wrapperElement() {
    return element(this.args.tagName ?? "td");
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <this.wrapperElement
        class="num posts-map posts {{this.likesHeat}} topic-list-data"
      >
        <TopicEntrance
          @topic={{@topic}}
          @title={{this.title}}
          @triggerClass="btn-link posts-map badge-posts {{this.likesHeat}}"
        >
          <PluginOutlet @name="topic-list-before-reply-count" />
          {{number @topic.replyCount noTitle="true"}}
        </TopicEntrance>
      </this.wrapperElement>
    
  */
  {
    "id": "Mvx+8HDh",
    "block": "[[[1,\"\\n    \"],[8,[30,0,[\"wrapperElement\"]],[[16,0,[29,[\"num posts-map posts \",[30,0,[\"likesHeat\"]],\" topic-list-data\"]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[32,0],null,[[\"@topic\",\"@title\",\"@triggerClass\"],[[30,1],[30,0,[\"title\"]],[29,[\"btn-link posts-map badge-posts \",[30,0,[\"likesHeat\"]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@name\"],[\"topic-list-before-reply-count\"]],null],[1,\"\\n        \"],[1,[28,[32,2],[[30,1,[\"replyCount\"]]],[[\"noTitle\"],[\"true\"]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/posts-count-column.js",
    "scope": () => [TopicEntrance, PluginOutlet, number],
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './group-default-notifications.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-default-notifications.hbs";
import { setComponentTemplate } from "@ember/component";
export default class GroupDefaultNotifications extends Component {
  updateExistingUsers() {
    this.args.model.setUpdateExistingUsers(true);
    this.args.closeModal();
  }
  static #_ = (() => dt7948.n(this.prototype, "updateExistingUsers", [action]))();
  cancel() {
    this.args.model.setUpdateExistingUsers(false);
    this.args.closeModal();
  }
  static #_2 = (() => dt7948.n(this.prototype, "cancel", [action]))();
}
setComponentTemplate(TEMPLATE, GroupDefaultNotifications);
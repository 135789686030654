/* import __COLOCATED_TEMPLATE__ from './convert-to-public-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { extractError } from "discourse/lib/ajax-error";
import TEMPLATE from "./convert-to-public-topic.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ConvertToPublicTopic extends Component {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "publicCategoryId", [tracked]))();
  #publicCategoryId = (() => (dt7948.i(this, "publicCategoryId"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  }))();
  #saving = (() => (dt7948.i(this, "saving"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "flash", [tracked]))();
  #flash = (() => (dt7948.i(this, "flash"), void 0))();
  async makePublic() {
    const {
      topic
    } = this.args.model;
    try {
      this.saving = true;
      await topic.convertTopic("public", {
        categoryId: this.publicCategoryId
      });
      topic.set("archetype", "regular");
      topic.set("category_id", this.publicCategoryId);
      this.appEvents.trigger("header:show-topic", topic);
      this.args.closeModal();
    } catch (e) {
      this.flash = extractError(e);
    } finally {
      this.saving = false;
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "makePublic", [action]))();
}
setComponentTemplate(TEMPLATE, ConvertToPublicTopic);
/* import __COLOCATED_TEMPLATE__ from './choose-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty, isPresent } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { searchForTerm } from "discourse/lib/search";
import { INPUT_DELAY } from "discourse-common/config/environment";
import { debounce } from "discourse-common/utils/decorators";

// args:
// topicChangedCallback
//
// optional:
// currentTopicId
// additionalFilters
// label
// loadOnInit
import TEMPLATE from "./choose-topic.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChooseTopic extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return true;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "topics", [tracked]))();
  #topics = (() => (dt7948.i(this, "topics"), void 0))();
  topicTitle;
  constructor() {
    super(...arguments);
    if (this.args.loadOnInit && isPresent(this.args.additionalFilters)) {
      this.initialSearch();
    }
  }
  async initialSearch() {
    try {
      const results = await searchForTerm(this.args.additionalFilters);
      if (!results?.posts?.length) {
        return;
      }
      this.topics = results.posts.mapBy("topic").filter(t => t.id !== this.args.currentTopicId);
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
  async search(title) {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    if (isEmpty(title) && isEmpty(this.args.additionalFilters)) {
      this.topics = null;
      this.loading = false;
      return;
    }
    const titleWithFilters = [title, this.args.additionalFilters].filter(Boolean).join(" ");
    let searchParams;
    if (isPresent(title)) {
      searchParams = {
        typeFilter: "topic",
        restrictToArchetype: "regular",
        searchForId: true
      };
    }
    try {
      const results = await searchForTerm(titleWithFilters, searchParams);

      // search term changed after the request was fired but before we
      // got a response, ignore results.
      if (title !== this.topicTitle) {
        return;
      }
      if (!results?.posts?.length) {
        this.topics = null;
        return;
      }
      this.topics = results.posts.mapBy("topic").filter(t => t.id !== this.args.currentTopicId);
      if (this.topics.length === 1) {
        this.chooseTopic(this.topics[0]);
      }
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "search", [debounce(INPUT_DELAY)]))();
  async onTopicTitleChange(event) {
    this.topicTitle = event.target.value;
    this.loading = true;
    await this.search(this.topicTitle);
  }
  static #_4 = (() => dt7948.n(this.prototype, "onTopicTitleChange", [action]))();
  ignoreEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "ignoreEnter", [action]))();
  chooseTopic(topic) {
    this.args.topicChangedCallback(topic);
  }
  static #_6 = (() => dt7948.n(this.prototype, "chooseTopic", [action]))();
}
setComponentTemplate(TEMPLATE, ChooseTopic);
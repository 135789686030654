import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class Post extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  model(params) {
    return this.store.find("post", params.id);
  }
  afterModel(post) {
    this.router.transitionTo(post.url);
  }
}
/* import __COLOCATED_TEMPLATE__ from './uppy-image-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { or } from "@ember/object/computed";
import { next } from "@ember/runloop";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import $ from "jquery";
import lightbox, { cleanupLightboxes, setupLightboxes } from "discourse/lib/lightbox";
import { authorizesOneOrMoreExtensions } from "discourse/lib/uploads";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./uppy-image-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, dt7948.p({
  classNames: ["image-uploader"],
  disabled: or("notAllowed", "uploading", "processing"),
  experimentalLightboxEnabled(experimentalLightboxEnabled) {
    return experimentalLightboxEnabled;
  },
  disabledReason(disabled, notAllowed) {
    if (disabled && notAllowed) {
      return I18n.t("post.errors.no_uploads_authorized");
    }
  },
  notAllowed() {
    return !authorizesOneOrMoreExtensions(this.currentUser?.staff, this.siteSettings);
  },
  showingPlaceholder(imageUrl, placeholderUrl) {
    return !imageUrl && placeholderUrl;
  },
  placeholderStyle(url) {
    if (isEmpty(url)) {
      return htmlSafe("");
    }
    return htmlSafe(`background-image: url(${url})`);
  },
  imageCDNURL(url) {
    if (isEmpty(url)) {
      return htmlSafe("");
    }
    return getURLWithCDN(url);
  },
  backgroundStyle(url) {
    return htmlSafe(`background-image: url(${url})`);
  },
  imageBaseName(imageUrl) {
    if (isEmpty(imageUrl)) {
      return;
    }
    return imageUrl.split("/").slice(-1)[0];
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  _uppyReady() {
    this._onPreProcessComplete(() => {
      this.set("processing", false);
    });
  },
  uploadDone(upload) {
    this.setProperties({
      imageFilesize: upload.human_filesize,
      imageFilename: upload.original_filename,
      imageWidth: upload.width,
      imageHeight: upload.height
    });

    // the value of the property used for imageUrl should be set
    // in this callback. this should be done in cases where imageUrl
    // is bound to a computed property of the parent component.
    if (this.onUploadDone) {
      this.onUploadDone(upload);
    } else {
      this.set("imageUrl", upload.url);
    }
  },
  _applyLightbox() {
    if (this.experimentalLightboxEnabled) {
      setupLightboxes({
        container: this.element,
        selector: ".lightbox"
      });
    } else {
      next(() => lightbox(this.element, this.siteSettings));
    }
  },
  _closeOnRemoval() {
    if (this.experimentalLightboxEnabled) {
      cleanupLightboxes();
    } else {
      if ($.magnificPopup?.instance) {
        $.magnificPopup.instance.close();
      }
    }
  },
  toggleLightbox() {
    $(this.element.querySelector("a.lightbox"))?.magnificPopup("open");
  },
  actions: {
    trash() {
      // uppy needs to be reset to allow for more uploads
      this._reset();

      // the value of the property used for imageUrl should be cleared
      // in this callback. this should be done in cases where imageUrl
      // is bound to a computed property of the parent component.
      if (this.onUploadDeleted) {
        this.onUploadDeleted();
      } else {
        this.setProperties({
          imageUrl: null
        });
      }
    }
  }
}, [["method", "experimentalLightboxEnabled", [discourseComputed("siteSettings.enable_experimental_lightbox")]], ["method", "disabledReason", [discourseComputed("disabled", "notAllowed")]], ["method", "notAllowed", [discourseComputed("currentUser.staff", "siteSettings.{authorized_extensions,authorized_extensions_for_staff}")]], ["method", "showingPlaceholder", [discourseComputed("imageUrl", "placeholderUrl")]], ["method", "placeholderStyle", [discourseComputed("placeholderUrl")]], ["method", "imageCDNURL", [discourseComputed("imageUrl")]], ["method", "backgroundStyle", [discourseComputed("imageCDNURL")]], ["method", "imageBaseName", [discourseComputed("imageUrl")]], ["method", "_applyLightbox", [on("didRender")]], ["method", "_closeOnRemoval", [on("willDestroyElement")]], ["method", "toggleLightbox", [action]]])));
/* import __COLOCATED_TEMPLATE__ from './d-lightbox.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { ANIMATION_DURATION, KEYBOARD_SHORTCUTS, LAYOUT_TYPES, LIGHTBOX_APP_EVENT_NAMES, LIGHTBOX_ELEMENT_ID, SWIPE_DIRECTIONS, TITLE_ELEMENT_ID } from "discourse/lib/lightbox/constants";
import { createDownloadLink, getSwipeDirection, openImageInNewTab, preloadItemImages, scrollParentToElementCenter, setCarouselScrollPosition, setSiteThemeColor } from "discourse/lib/lightbox/helpers";
import discourseLater from "discourse-common/lib/later";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./d-lightbox.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DLightbox extends Component {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "items", [tracked], function () {
    return [];
  }))();
  #items = (() => (dt7948.i(this, "items"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "isVisible", [tracked], function () {
    return false;
  }))();
  #isVisible = (() => (dt7948.i(this, "isVisible"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "isLoading", [tracked], function () {
    return false;
  }))();
  #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "currentIndex", [tracked], function () {
    return 0;
  }))();
  #currentIndex = (() => (dt7948.i(this, "currentIndex"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "currentItem", [tracked], function () {
    return {};
  }))();
  #currentItem = (() => (dt7948.i(this, "currentItem"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "isZoomed", [tracked], function () {
    return false;
  }))();
  #isZoomed = (() => (dt7948.i(this, "isZoomed"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "isRotated", [tracked], function () {
    return false;
  }))();
  #isRotated = (() => (dt7948.i(this, "isRotated"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "isFullScreen", [tracked], function () {
    return false;
  }))();
  #isFullScreen = (() => (dt7948.i(this, "isFullScreen"), void 0))();
  static #_10 = (() => dt7948.g(this.prototype, "rotationAmount", [tracked], function () {
    return 0;
  }))();
  #rotationAmount = (() => (dt7948.i(this, "rotationAmount"), void 0))();
  static #_11 = (() => dt7948.g(this.prototype, "hasCarousel", [tracked], function () {
    return true;
  }))();
  #hasCarousel = (() => (dt7948.i(this, "hasCarousel"), void 0))();
  static #_12 = (() => dt7948.g(this.prototype, "hasExpandedTitle", [tracked], function () {
    return false;
  }))();
  #hasExpandedTitle = (() => (dt7948.i(this, "hasExpandedTitle"), void 0))();
  options = (() => ({}))();
  callbacks = (() => ({}))();
  willClose = false;
  elementId = (() => LIGHTBOX_ELEMENT_ID)();
  titleElementId = (() => TITLE_ELEMENT_ID)();
  animationDuration = (() => ANIMATION_DURATION)();
  scrollPosition = 0;
  get layoutType() {
    return window.innerWidth > window.innerHeight ? LAYOUT_TYPES.HORIZONTAL : LAYOUT_TYPES.VERTICAL;
  }
  get CSSVars() {
    const base = "--d-lightbox-image";
    const variables = [`${base}-animation-duration: ${this.animationDuration}ms;`];
    if (!this.currentItem) {
      return htmlSafe(variables.join(""));
    }
    const {
      width,
      height,
      aspectRatio,
      dominantColor,
      fullsizeURL
    } = this.currentItem;
    variables.push(`${base}-rotation: ${this.rotationAmount}deg`, `${base}-width: ${width}px`, `${base}-height: ${height}px`, `${base}-aspect-ratio: ${aspectRatio}`, `${base}-dominant-color: #${dominantColor}`, `${base}-full-size-url: url(${encodeURI(fullsizeURL)})`);
    return htmlSafe(variables.filter(Boolean).join(";"));
  }
  get HTMLClassList() {
    const base = "d-lightbox";
    const classNames = [base];
    if (!this.isVisible) {
      return classNames.join("");
    }
    classNames.push(this.layoutType && `is-${this.layoutType}`, this.isVisible && `is-visible`, this.isLoading ? `is-loading` : `is-finished-loading`, this.isFullScreen && `is-fullscreen`, this.isZoomed && `is-zoomed`, this.isRotated && `is-rotated`, this.canZoom && `can-zoom`, this.hasExpandedTitle && `has-expanded-title`, this.hasCarousel && `has-carousel`, this.hasLoadingError && `has-loading-error`, this.willClose && `will-close`, this.isRotated && this.rotationAmount && `is-rotated-${this.rotationAmount}`);
    return classNames.filter(Boolean).join(" ");
  }
  get shouldDisplayMainImageArrows() {
    return !this.options.isMobile && this.canNavigate && !this.hasCarousel && !this.isZoomed && !this.isRotated;
  }
  get shouldDisplayCarousel() {
    return this.hasCarousel && this.totalItemCount >= this.options.minCarouselItemCount && !this.isZoomed && !this.isRotated;
  }
  get shouldDisplayCarouselArrows() {
    return !this.options.isMobile && this.totalItemCount >= this.options.minCarouselArrowItemCount;
  }
  get shouldDisplayTitle() {
    return !this.hasLoadingError && !this.isZoomed && !this.isRotated;
  }
  get totalItemCount() {
    return this.items?.length || 0;
  }
  get counterIndex() {
    return this.currentIndex ? this.currentIndex + 1 : 1;
  }
  get canNavigate() {
    return this.items?.length > 1;
  }
  get canZoom() {
    return !this.hasLoadingError && this.currentItem?.canZoom;
  }
  get canRotate() {
    return !this.hasLoadingError;
  }
  get canDownload() {
    return !this.hasLoadingError && this.options.canDownload;
  }
  get canFullscreen() {
    return !this.hasLoadingError;
  }
  get hasLoadingError() {
    return this.currentItem?.hasLoadingError;
  }
  get nextButtonIcon() {
    return this.options.isRTL ? "chevron-left" : "chevron-right";
  }
  get previousButtonIcon() {
    return this.options.isRTL ? "chevron-right" : "chevron-left";
  }
  get zoomButtonIcon() {
    return this.isZoomed ? "search-minus" : "search-plus";
  }
  registerAppEventListeners() {
    this.appEvents.on(LIGHTBOX_APP_EVENT_NAMES.OPEN, this.open);
    this.appEvents.on(LIGHTBOX_APP_EVENT_NAMES.CLOSE, this.close);
  }
  static #_13 = (() => dt7948.n(this.prototype, "registerAppEventListeners", [bind]))();
  deregisterAppEventListeners() {
    this.appEvents.off(LIGHTBOX_APP_EVENT_NAMES.OPEN, this.open);
    this.appEvents.off(LIGHTBOX_APP_EVENT_NAMES.CLOSE, this.close);
  }
  static #_14 = (() => dt7948.n(this.prototype, "deregisterAppEventListeners", [bind]))();
  open(_ref) {
    let {
      items,
      startingIndex,
      callbacks,
      options
    } = _ref;
    this.options = options;
    this.items = items;
    this.currentIndex = startingIndex;
    this.callbacks = callbacks;
    this.isLoading = true;
    this.isVisible = true;
    this.scrollPosition = window.scrollY;
    this.#setCurrentItem(this.currentIndex);
    if (this.options.zoomOnOpen && this.currentItem?.canZoom && !this.currentItem?.isZoomed) {
      this.toggleZoom();
    }
    this.callbacks.onOpen?.({
      items: this.items,
      currentItem: this.currentItem
    });
  }
  static #_15 = (() => dt7948.n(this.prototype, "open", [bind]))();
  close() {
    this.willClose = true;
    discourseLater(this.cleanup, this.animationDuration);
    this.callbacks.onClose?.();
  }
  static #_16 = (() => dt7948.n(this.prototype, "close", [bind]))();
  async #setCurrentItem(index) {
    this.#onBeforeItemChange();
    this.currentIndex = (index + this.totalItemCount) % this.totalItemCount;
    this.currentItem = await preloadItemImages(this.items[this.currentIndex]);
    this.#onAfterItemChange();
  }
  #onBeforeItemChange() {
    this.callbacks.onItemWillChange?.({
      currentItem: this.currentItem
    });
    this.isLoading = true;
    this.isZoomed = false;
    this.isRotated = false;
  }
  #onAfterItemChange() {
    this.isLoading = false;
    if (this.currentItem.dominantColor) {
      setSiteThemeColor(this.currentItem.dominantColor);
    }
    setCarouselScrollPosition({
      behavior: "smooth"
    });
    this.callbacks.onItemDidChange?.({
      currentItem: this.currentItem
    });
    const nextItem = this.items[this.currentIndex + 1];
    return nextItem ? preloadItemImages(nextItem) : false;
  }
  centerZoomedBackgroundPosition(zoomedImageContainer) {
    return this.options.isMobile ? scrollParentToElementCenter({
      element: zoomedImageContainer,
      isRTL: this.options.isRTL
    }) : false;
  }
  static #_17 = (() => dt7948.n(this.prototype, "centerZoomedBackgroundPosition", [bind]))();
  zoomOnMouseover(event) {
    const zoomedImageContainer = event.target;
    const offsetX = event.offsetX;
    const offsetY = event.offsetY;
    const x = offsetX / zoomedImageContainer.offsetWidth * 100;
    const y = offsetY / zoomedImageContainer.offsetHeight * 100;
    zoomedImageContainer.style.backgroundPosition = x + "% " + y + "%";
  }
  toggleZoom() {
    if (this.isLoading || !this.canZoom) {
      return;
    }
    this.isZoomed = !this.isZoomed;
    document.querySelector(".d-lightbox__close-button")?.focus();
  }
  static #_18 = (() => dt7948.n(this.prototype, "toggleZoom", [bind]))();
  rotateImage() {
    this.rotationAmount = (this.rotationAmount + 90) % 360;
    this.isRotated = this.rotationAmount !== 0;
  }
  static #_19 = (() => dt7948.n(this.prototype, "rotateImage", [bind]))();
  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    return this.isFullScreen ? document.documentElement.requestFullscreen() : document.exitFullscreen();
  }
  static #_20 = (() => dt7948.n(this.prototype, "toggleFullScreen", [bind]))();
  downloadImage() {
    return createDownloadLink(this.currentItem);
  }
  static #_21 = (() => dt7948.n(this.prototype, "downloadImage", [bind]))();
  openInNewTab() {
    return openImageInNewTab(this.currentItem);
  }
  static #_22 = (() => dt7948.n(this.prototype, "openInNewTab", [bind]))();
  reloadImage() {
    this.#setCurrentItem(this.currentIndex);
  }
  static #_23 = (() => dt7948.n(this.prototype, "reloadImage", [bind]))();
  toggleCarousel() {
    this.hasCarousel = !this.hasCarousel;
    requestAnimationFrame(setCarouselScrollPosition);
  }
  static #_24 = (() => dt7948.n(this.prototype, "toggleCarousel", [bind]))();
  showNextItem() {
    this.#setCurrentItem(this.currentIndex + 1);
  }
  static #_25 = (() => dt7948.n(this.prototype, "showNextItem", [bind]))();
  showPreviousItem() {
    this.#setCurrentItem(this.currentIndex - 1);
  }
  static #_26 = (() => dt7948.n(this.prototype, "showPreviousItem", [bind]))();
  showSelectedImage(event) {
    const targetIndex = event.target.dataset?.lightboxItemIndex;
    return targetIndex ? this.#setCurrentItem(Number(targetIndex)) : false;
  }
  static #_27 = (() => dt7948.n(this.prototype, "showSelectedImage", [bind]))();
  toggleExpandTitle() {
    this.hasExpandedTitle = !this.hasExpandedTitle;
  }
  static #_28 = (() => dt7948.n(this.prototype, "toggleExpandTitle", [bind]))();
  onKeydown(event) {
    if (event.key === KEYBOARD_SHORTCUTS.CLOSE) {
      event.preventDefault();
      event.stopPropagation();
      return this.close();
    }
  }
  static #_29 = (() => dt7948.n(this.prototype, "onKeydown", [bind]))();
  onKeyup(_ref2) {
    let {
      key
    } = _ref2;
    if (KEYBOARD_SHORTCUTS.PREVIOUS.includes(key)) {
      return this.showPreviousItem();
    }
    if (KEYBOARD_SHORTCUTS.NEXT.includes(key)) {
      return this.showNextItem();
    }
    if (key === KEYBOARD_SHORTCUTS.ZOOM) {
      return this.toggleZoom();
    }
    if (key === KEYBOARD_SHORTCUTS.FULLSCREEN) {
      return this.toggleFullScreen();
    }
    if (key === KEYBOARD_SHORTCUTS.ROTATE) {
      return this.rotateImage();
    }
    if (key === KEYBOARD_SHORTCUTS.DOWNLOAD) {
      return this.downloadImage();
    }
    if (key === KEYBOARD_SHORTCUTS.CAROUSEL) {
      return this.toggleCarousel();
    }
    if (key === KEYBOARD_SHORTCUTS.TITLE) {
      return this.toggleExpandTitle();
    }
    if (key === KEYBOARD_SHORTCUTS.NEWTAB) {
      return this.openInNewTab();
    }
  }
  static #_30 = (() => dt7948.n(this.prototype, "onKeyup", [bind]))();
  onTouchstart() {
    let event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Event;
    if (this.isZoomed) {
      return false;
    }
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }
  static #_31 = (() => dt7948.n(this.prototype, "onTouchstart", [bind]))();
  async onTouchend(event) {
    if (this.isZoomed) {
      return false;
    }
    event.stopPropagation();
    const touchendY = event.changedTouches[0].screenY;
    const touchendX = event.changedTouches[0].screenX;
    const swipeDirection = await getSwipeDirection({
      touchstartX: this.touchstartX,
      touchstartY: this.touchstartY,
      touchendX,
      touchendY
    });
    switch (swipeDirection) {
      case SWIPE_DIRECTIONS.LEFT:
        this.options.isRTL ? this.showNextItem() : this.showPreviousItem();
        break;
      case SWIPE_DIRECTIONS.RIGHT:
        this.options.isRTL ? this.showPreviousItem() : this.showNextItem();
        break;
      case SWIPE_DIRECTIONS.DOWN:
        this.close();
        break;
    }
  }
  static #_32 = (() => dt7948.n(this.prototype, "onTouchend", [bind]))();
  cleanup() {
    if (this.isVisible) {
      this.hasCarousel = true;
      this.hasExpandedTitle = false;
      this.isLoading = false;
      this.items = [];
      this.currentIndex = 0;
      this.isZoomed = false;
      this.isRotated = false;
      this.rotationAmount = 0;
      if (this.isFullScreen) {
        this.toggleFullScreen();
        this.isFullScreen = false;
      }
      this.isVisible = false;
      this.willClose = false;
      this.resetScrollPosition();
      this.callbacks.onCleanup?.();
      this.callbacks = {};
      this.options = {};
    }
  }
  static #_33 = (() => dt7948.n(this.prototype, "cleanup", [bind]))();
  resetScrollPosition() {
    if (window.scrollY !== this.scrollPosition) {
      window.scrollTo({
        left: 0,
        top: parseInt(this.scrollPosition, 10),
        behavior: "instant"
      });
    }
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.cleanup();
  }
}
setComponentTemplate(TEMPLATE, DLightbox);
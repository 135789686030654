/* import __COLOCATED_TEMPLATE__ from './date-time-input-range.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./date-time-input-range.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["d-date-time-input-range"],
  from: null,
  to: null,
  onChangeTo: null,
  onChangeFrom: null,
  toTimeFirst: false,
  showToTime: true,
  showFromTime: true,
  clearable: false,
  onChangeRanges(options, value) {
    if (this.onChange) {
      const state = {
        from: this.from,
        to: this.to
      };
      const diff = {};
      if (options.prop === "from") {
        if (this.to && value?.isAfter(this.to)) {
          diff[options.prop] = value;
          diff["to"] = value.clone().add(1, "hour");
        } else {
          diff[options.prop] = value;
        }
      }
      if (options.prop === "to") {
        if (value && value.isBefore(this.from)) {
          diff[options.prop] = this.from.clone().add(1, "hour");
        } else {
          diff[options.prop] = value;
        }
      }
      const newState = {
        ...state,
        ...diff
      };
      this.onChange(newState);
    }
  }
}, [["method", "onChangeRanges", [action]]])));
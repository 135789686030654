import { action } from "@ember/object";
import { service } from "@ember/service";
import CreateInvite from "discourse/components/modal/create-invite";
import GroupAddMembersModal from "discourse/components/modal/group-add-members";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class GroupIndex extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  titleToken() {
    return I18n.t("groups.members.title");
  }
  model(params) {
    this._params = params;
    return this.modelFor("group");
  }
  setupController(controller, model) {
    controller.setProperties({
      model,
      filterInput: this._params.filter,
      showing: "members"
    });
    controller.reloadMembers(true);
  }
  showAddMembersModal() {
    this.modal.show(GroupAddMembersModal, {
      model: this.modelFor("group")
    });
  }
  static #_2 = (() => dt7948.n(this.prototype, "showAddMembersModal", [action]))();
  showInviteModal() {
    const group = this.modelFor("group");
    this.modal.show(CreateInvite, {
      model: {
        groupIds: [group.id]
      }
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "showInviteModal", [action]))();
  didTransition() {
    this.controllerFor("group-index").set("filterInput", this._params.filter);
    return true;
  }
  static #_4 = (() => dt7948.n(this.prototype, "didTransition", [action]))();
}
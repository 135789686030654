/* import __COLOCATED_TEMPLATE__ from './date-time-input.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action, computed } from "@ember/object";
import TEMPLATE from "./date-time-input.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNames: ["d-date-time-input"],
  date: null,
  relativeDate: null,
  showTime: true,
  clearable: false,
  hours: computed("date", "showTime", function () {
    return this.date && this.get("showTime") ? this.date.hours() : null;
  }),
  minutes: computed("date", "showTime", function () {
    return this.date && this.get("showTime") ? this.date.minutes() : null;
  }),
  onClear() {
    this.onChange(null);
  },
  onChangeTime(time) {
    if (this.onChange) {
      const date = this.date ? this.date : this.relativeDate ? this.relativeDate : moment.tz(this.resolvedTimezone);
      this.onChange(moment.tz({
        year: date.year(),
        month: date.month(),
        day: date.date(),
        hours: time.hours,
        minutes: time.minutes
      }, this.resolvedTimezone));
    }
  },
  onChangeDate(date) {
    if (!date) {
      this.onClear();
      return;
    }
    this.onChange?.(moment.tz({
      year: date.year(),
      month: date.month(),
      day: date.date(),
      hours: this.hours || 0,
      minutes: this.minutes || 0
    }, this.resolvedTimezone));
  },
  get resolvedTimezone() {
    return this.timezone || moment.tz.guess();
  }
}, [["method", "onClear", [action]], ["method", "onChangeTime", [action]], ["method", "onChangeDate", [action]], ["method", "resolvedTimezone", [computed("timezone")]]])));
/* import __COLOCATED_TEMPLATE__ from './request-group-membership-form.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import DiscourseURL from "discourse/lib/url";
import I18n from "discourse-i18n";
import TEMPLATE from "./request-group-membership-form.hbs";
import { setComponentTemplate } from "@ember/component";
export default class RequestGroupMembershipForm extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "reason", [tracked], function () {
    return this.args.model.group.membership_request_template;
  }))();
  #reason = (() => (dt7948.i(this, "reason"), void 0))();
  get title() {
    return I18n.t("groups.membership_request.title", {
      group_name: this.args.model.group.name
    });
  }
  get disableSubmit() {
    return this.loading || isEmpty(this.reason);
  }
  async requestMember() {
    this.loading = true;
    try {
      const result = await this.args.model.group.requestMembership(this.reason);
      DiscourseURL.routeTo(result.relative_url);
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "requestMember", [action]))();
}
setComponentTemplate(TEMPLATE, RequestGroupMembershipForm);
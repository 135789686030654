/* import __COLOCATED_TEMPLATE__ from './notification-level.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { empty } from "@ember/object/computed";
import { topicLevels } from "discourse/lib/notification-levels";
import I18n from "discourse-i18n";

// Support for changing the notification level of various topics
import TEMPLATE from "./notification-level.hbs";
import { setComponentTemplate } from "@ember/component";
export default class NotificationLevel extends Component {
  notificationLevelId = null;
  static #_ = (() => dt7948.g(this.prototype, "disabled", [empty("notificationLevelId")]))();
  #disabled = (() => (dt7948.i(this, "disabled"), void 0))();
  get notificationLevels() {
    return topicLevels.map(level => ({
      id: level.id.toString(),
      name: I18n.t(`topic.notifications.${level.key}.title`),
      description: I18n.t(`topic.notifications.${level.key}.description`)
    }));
  }
  changeNotificationLevel() {
    this.args.performAndRefresh({
      type: "change_notification_level",
      notification_level_id: this.notificationLevelId
    });
  }
  static #_2 = (() => dt7948.n(this.prototype, "changeNotificationLevel", [action]))();
}
setComponentTemplate(TEMPLATE, NotificationLevel);
import Component from "@glimmer/component";
import { service } from "@ember/service";
import bodyClass from "discourse/helpers/body-class";
import hideApplicationFooter from "discourse/helpers/hide-application-footer";
import loadingSpinner from "discourse/helpers/loading-spinner";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class LoadingSliderFallbackSpinner extends Component {
  static #_ = (() => dt7948.g(this.prototype, "loadingSlider", [service]))();
  #loadingSlider = (() => (dt7948.i(this, "loadingSlider"), void 0))();
  get shouldDisplay() {
    const {
      mode: mode1,
      loading: loading1,
      stillLoading: stillLoading1
    } = this.loadingSlider;
    return mode1 === "spinner" && loading1 || mode1 === "slider" && stillLoading1;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldDisplay}}
        <div class="route-loading-spinner">{{loadingSpinner}}</div>
        {{bodyClass "has-route-loading-spinner"}}
        {{hideApplicationFooter}}
      {{/if}}
    
  */
  {
    "id": "17XtLotn",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"route-loading-spinner\"],[12],[1,[32,0]],[13],[1,\"\\n      \"],[1,[28,[32,1],[\"has-route-loading-spinner\"],null]],[1,\"\\n      \"],[1,[32,2]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/loading-slider-fallback-spinner.js",
    "scope": () => [loadingSpinner, bodyClass, hideApplicationFooter],
    "isStrictMode": true
  }), this))();
}
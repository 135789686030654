import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { array, hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { hasDefaultSidebarCategories } from "discourse/lib/sidebar/helpers";
import Category from "discourse/models/category";
import i18n from "discourse-common/helpers/i18n";
import { debounce } from "discourse-common/utils/decorators";
import AllCategoriesSectionLink from "../common/all-categories-section-link";
import CommonCategoriesSection from "../common/categories-section";
import EditNavigationMenuCategoriesModal from "../edit-navigation-menu/categories-modal";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export const REFRESH_COUNTS_APP_EVENT_NAME = "sidebar:refresh-categories-section-counts";
export default class SidebarUserCategoriesSection extends CommonCategoriesSection {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  constructor() {
    super(...arguments);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this._refreshCounts();
    });
    this.appEvents.on(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
    this.appEvents.off(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }
  // TopicTrackingState changes or plugins can trigger this function so we debounce to ensure we're not refreshing
  // unnecessarily.
  _refreshCounts() {
    this.sectionLinks.forEach(sectionLink1 => sectionLink1.refreshCounts());
  }
  static #_5 = (() => dt7948.n(this.prototype, "_refreshCounts", [debounce(300)]))();
  get categories() {
    if (this.currentUser.sidebarCategoryIds?.length > 0) {
      return Category.findByIds(this.currentUser.sidebarCategoryIds);
    } else {
      return this.topSiteCategories;
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "categories", [cached]))();
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarCategories;
  }
  get hasDefaultSidebarCategories() {
    return hasDefaultSidebarCategories(this.siteSettings);
  }
  showModal() {
    this.modal.show(EditNavigationMenuCategoriesModal);
  }
  static #_7 = (() => dt7948.n(this.prototype, "showModal", [action]))();
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <Section
        @sectionName="categories"
        @headerLinkText={{i18n "sidebar.sections.categories.header_link_text"}}
        @headerActions={{array
          (hash
            action=this.showModal
            title=(i18n "sidebar.sections.categories.header_action_title")
          )
        }}
        @headerActionsIcon="pencil-alt"
        @collapsable={{@collapsable}}
      >
  
        {{#each this.sectionLinks as |sectionLink|}}
          <SectionLink
            @route={{sectionLink.route}}
            @query={{sectionLink.query}}
            @title={{sectionLink.title}}
            @content={{sectionLink.text}}
            @currentWhen={{sectionLink.currentWhen}}
            @model={{sectionLink.model}}
            @badgeText={{sectionLink.badgeText}}
            @prefixBadge={{sectionLink.prefixBadge}}
            @prefixType={{sectionLink.prefixType}}
            @prefixValue={{sectionLink.prefixValue}}
            @prefixColor={{sectionLink.prefixColor}}
            @suffixCSSClass={{sectionLink.suffixCSSClass}}
            @suffixValue={{sectionLink.suffixValue}}
            @suffixType={{sectionLink.suffixType}}
            data-category-id={{sectionLink.category.id}}
          />
        {{/each}}
  
        <AllCategoriesSectionLink />
  
        {{#if this.shouldDisplayDefaultConfig}}
          <SectionLink
            @linkName="configure-default-navigation-menu-categories"
            @content={{i18n "sidebar.sections.categories.configure_defaults"}}
            @prefixType="icon"
            @prefixValue="wrench"
            @route="adminSiteSettingsCategory"
            @model="sidebar"
            @query={{hash filter="default_navigation_menu_categories"}}
          />
        {{/if}}
      </Section>
    
  */
  {
    "id": "1Ncvs0xK",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@headerActions\",\"@headerActionsIcon\",\"@collapsable\"],[\"categories\",[28,[32,1],[\"sidebar.sections.categories.header_link_text\"],null],[28,[32,2],[[28,[32,3],null,[[\"action\",\"title\"],[[30,0,[\"showModal\"]],[28,[32,1],[\"sidebar.sections.categories.header_action_title\"],null]]]]],null],\"pencil-alt\",[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"        \"],[8,[32,4],[[16,\"data-category-id\",[30,2,[\"category\",\"id\"]]]],[[\"@route\",\"@query\",\"@title\",\"@content\",\"@currentWhen\",\"@model\",\"@badgeText\",\"@prefixBadge\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@suffixCSSClass\",\"@suffixValue\",\"@suffixType\"],[[30,2,[\"route\"]],[30,2,[\"query\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"model\"]],[30,2,[\"badgeText\"]],[30,2,[\"prefixBadge\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"suffixCSSClass\"]],[30,2,[\"suffixValue\"]],[30,2,[\"suffixType\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"],[8,[32,5],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplayDefaultConfig\"]],[[[1,\"        \"],[8,[32,4],null,[[\"@linkName\",\"@content\",\"@prefixType\",\"@prefixValue\",\"@route\",\"@model\",\"@query\"],[\"configure-default-navigation-menu-categories\",[28,[32,1],[\"sidebar.sections.categories.configure_defaults\"],null],\"icon\",\"wrench\",\"adminSiteSettingsCategory\",\"sidebar\",[28,[32,3],null,[[\"filter\"],[\"default_navigation_menu_categories\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/categories-section.js",
    "scope": () => [Section, i18n, array, hash, SectionLink, AllCategoriesSectionLink],
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { array, fn, hash } from "@ember/helper";
import { getOwner } from "@ember/owner";
import { service } from "@ember/service";
import routeAction from "discourse/helpers/route-action";
import GroupMessageSectionLink from "discourse/lib/sidebar/user/messages-section/group-message-section-link";
import PersonalMessageSectionLink from "discourse/lib/sidebar/user/messages-section/personal-message-section-link";
import i18n from "discourse-common/helpers/i18n";
import { bind } from "discourse-common/utils/decorators";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export const INBOX = "inbox";
export const UNREAD = "unread";
const SENT = "sent";
export const NEW = "new";
const ARCHIVE = "archive";
export const PERSONAL_MESSAGES_INBOX_FILTERS = [INBOX, NEW, UNREAD, SENT, ARCHIVE];
export const GROUP_MESSAGES_INBOX_FILTERS = [INBOX, NEW, UNREAD, ARCHIVE];
export default class SidebarUserMessagesSection extends Component {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "pmTopicTrackingState", [service]))();
  #pmTopicTrackingState = (() => (dt7948.i(this, "pmTopicTrackingState"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  _pmTopicTrackingStateKey = "messages-section";
  constructor() {
    super(...arguments);
    this.appEvents.on("page:changed", this, this._refreshSectionLinksDisplayState);
    this.pmTopicTrackingState.onStateChange(this._pmTopicTrackingStateKey, this._refreshSectionLinkCounts);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.appEvents.off("page:changed", this, this._refreshSectionLinksDisplayState);
    this.pmTopicTrackingState.offStateChange(this._pmTopicTrackingStateKey, this._refreshSectionLinkCounts);
  }
  _refreshSectionLinkCounts() {
    for (const sectionLink1 of this.allSectionLinks) {
      sectionLink1.refreshCount();
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "_refreshSectionLinkCounts", [bind]))();
  _refreshSectionLinksDisplayState() {
    const currentRouteName1 = this.router.currentRoute.name;
    const currentRouteParentName1 = this.router.currentRoute.parent.name;
    const currentRouteParentParams1 = this.router.currentRoute.parent.params;
    if (!currentRouteParentName1.includes("userPrivateMessages") && currentRouteParentName1 !== "topic") {
      for (const sectionLink1 of this.allSectionLinks) {
        sectionLink1.collapse();
      }
    } else {
      const attrs1 = {
        currentRouteName: currentRouteName1,
        currentRouteParentParams: currentRouteParentParams1
      };
      if (currentRouteParentName1 === "topic") {
        const topicController1 = getOwner(this).lookup("controller:topic");
        if (topicController1.model.isPrivateMessage) {
          attrs1.privateMessageTopic = topicController1.model;
        }
      }
      for (const sectionLink1 of this.allSectionLinks) {
        sectionLink1.pageChanged(attrs1);
      }
    }
  }
  get personalMessagesSectionLinks() {
    const links1 = [];
    PERSONAL_MESSAGES_INBOX_FILTERS.forEach(type1 => {
      links1.push(new PersonalMessageSectionLink({
        currentUser: this.currentUser,
        type: type1,
        pmTopicTrackingState: this.pmTopicTrackingState
      }));
    });
    return links1;
  }
  static #_6 = (() => dt7948.n(this.prototype, "personalMessagesSectionLinks", [cached]))();
  get groupMessagesSectionLinks() {
    const links1 = [];
    this.currentUser.groupsWithMessages.sort((a1, b1) => a1.name.localeCompare(b1.name)).forEach(group1 => {
      GROUP_MESSAGES_INBOX_FILTERS.forEach(groupMessageLink1 => {
        links1.push(new GroupMessageSectionLink({
          group: group1,
          type: groupMessageLink1,
          currentUser: this.currentUser,
          pmTopicTrackingState: this.pmTopicTrackingState
        }));
      });
    });
    return links1;
  }
  static #_7 = (() => dt7948.n(this.prototype, "groupMessagesSectionLinks", [cached]))();
  get allSectionLinks() {
    return [...this.groupMessagesSectionLinks, ...this.personalMessagesSectionLinks];
  }
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <Section
        @sectionName="messages"
        @headerActionIcon="plus"
        @headerActions={{array
          (hash
            action=(fn (routeAction "composePrivateMessage") null null)
            title=(i18n "sidebar.sections.messages.header_action_title")
          )
        }}
        @headerActionsIcon="plus"
        @headerLinkText={{i18n "sidebar.sections.messages.header_link_text"}}
        @collapsable={{@collapsable}}
      >
        {{#each
          this.personalMessagesSectionLinks
          as |personalMessageSectionLink|
        }}
          {{#if personalMessageSectionLink.shouldDisplay}}
            <SectionLink
              @linkName={{personalMessageSectionLink.name}}
              @linkClass={{personalMessageSectionLink.class}}
              @route={{personalMessageSectionLink.route}}
              @model={{personalMessageSectionLink.model}}
              @prefixType={{personalMessageSectionLink.prefixType}}
              @prefixValue={{personalMessageSectionLink.prefixValue}}
              @currentWhen={{personalMessageSectionLink.currentWhen}}
              @content={{personalMessageSectionLink.text}}
            />
          {{/if}}
        {{/each}}
  
        {{#each this.groupMessagesSectionLinks as |groupMessageSectionLink|}}
          {{#if groupMessageSectionLink.shouldDisplay}}
            <SectionLink
              @linkName={{groupMessageSectionLink.name}}
              @linkClass={{groupMessageSectionLink.class}}
              @route={{groupMessageSectionLink.route}}
              @prefixType={{groupMessageSectionLink.prefixType}}
              @prefixValue={{groupMessageSectionLink.prefixValue}}
              @models={{groupMessageSectionLink.models}}
              @currentWhen={{groupMessageSectionLink.currentWhen}}
              @content={{groupMessageSectionLink.text}}
            />
          {{/if}}
        {{/each}}
      </Section>
    
  */
  {
    "id": "VXgdSHDn",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerActionIcon\",\"@headerActions\",\"@headerActionsIcon\",\"@headerLinkText\",\"@collapsable\"],[\"messages\",\"plus\",[28,[32,1],[[28,[32,2],null,[[\"action\",\"title\"],[[28,[32,3],[[28,[32,4],[\"composePrivateMessage\"],null],null,null],null],[28,[32,5],[\"sidebar.sections.messages.header_action_title\"],null]]]]],null],\"plus\",[28,[32,5],[\"sidebar.sections.messages.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"personalMessagesSectionLinks\"]]],null]],null],null,[[[41,[30,2,[\"shouldDisplay\"]],[[[1,\"          \"],[8,[32,6],null,[[\"@linkName\",\"@linkClass\",\"@route\",\"@model\",\"@prefixType\",\"@prefixValue\",\"@currentWhen\",\"@content\"],[[30,2,[\"name\"]],[30,2,[\"class\"]],[30,2,[\"route\"]],[30,2,[\"model\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"currentWhen\"]],[30,2,[\"text\"]]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"groupMessagesSectionLinks\"]]],null]],null],null,[[[41,[30,3,[\"shouldDisplay\"]],[[[1,\"          \"],[8,[32,6],null,[[\"@linkName\",\"@linkClass\",\"@route\",\"@prefixType\",\"@prefixValue\",\"@models\",\"@currentWhen\",\"@content\"],[[30,3,[\"name\"]],[30,3,[\"class\"]],[30,3,[\"route\"]],[30,3,[\"prefixType\"]],[30,3,[\"prefixValue\"]],[30,3,[\"models\"]],[30,3,[\"currentWhen\"]],[30,3,[\"text\"]]]],null],[1,\"\\n\"]],[]],null]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[\"@collapsable\",\"personalMessageSectionLink\",\"groupMessageSectionLink\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/messages-section.js",
    "scope": () => [Section, array, hash, fn, routeAction, i18n, SectionLink],
    "isStrictMode": true
  }), this))();
}
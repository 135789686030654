/* import __COLOCATED_TEMPLATE__ from './user-summary-topics-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";

// should be kept in sync with 'UserSummary::MAX_SUMMARY_RESULTS'
import TEMPLATE from "./user-summary-topics-list.hbs";
const MAX_SUMMARY_RESULTS = 6;
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  hasMore(length) {
    return length >= MAX_SUMMARY_RESULTS;
  }
}, [["method", "hasMore", [discourseComputed("items.length")]]])));
/* import __COLOCATED_TEMPLATE__ from './related-messages.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./related-messages.hbs";
import { setComponentTemplate } from "@ember/component";
export default class RelatedMessages extends Component {
  static #_ = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [service]))();
  #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  listId = "related-Messages";
  get hidden() {
    return this.moreTopicsPreferenceTracking.selectedTab !== this.listId;
  }
  static #_3 = (() => dt7948.n(this.prototype, "hidden", [computed("moreTopicsPreferenceTracking.selectedTab")]))();
  registerList() {
    this.moreTopicsPreferenceTracking.registerTopicList({
      name: I18n.t("related_messages.pill"),
      id: this.listId
    });
  }
  static #_4 = (() => dt7948.n(this.prototype, "registerList", [action]))();
  removeList() {
    this.moreTopicsPreferenceTracking.removeTopicList(this.listId);
  }
  static #_5 = (() => dt7948.n(this.prototype, "removeList", [action]))();
  get targetUser() {
    const topic = this.args.topic;
    if (!topic || !topic.isPrivateMessage) {
      return;
    }
    const allowedUsers = topic.details.allowed_users;
    if (topic.relatedMessages && topic.relatedMessages.length >= 5 && allowedUsers.length === 2 && topic.details.allowed_groups.length === 0 && allowedUsers.find(u => u.username === this.currentUser.username)) {
      return allowedUsers.find(u => u.username !== this.currentUser.username);
    }
  }
  get searchLink() {
    return getURL(`/search?expanded=true&q=%40${this.targetUser.username}%20in%3Apersonal-direct`);
  }
}
setComponentTemplate(TEMPLATE, RelatedMessages);
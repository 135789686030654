import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { fn } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SwitchPanelButtons extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "isSwitching", [tracked], function () {
    return false;
  }))();
  #isSwitching = (() => (dt7948.i(this, "isSwitching"), void 0))();
  async switchPanel(panel1) {
    this.isSwitching = true;
    this.sidebarState.currentPanel.lastKnownURL = this.router.currentURL;
    const destination1 = panel1?.switchButtonDefaultUrl || panel1?.lastKnownURL;
    if (!destination1) {
      return;
    }
    try {
      await this.router.transitionTo(destination1).followRedirects();
      this.sidebarState.setPanel(panel1.key);
    } catch (e1) {
      if (e1.name !== "TransitionAborted") {
        throw e1;
      }
    } finally {
      this.isSwitching = false;
    }
  }
  static #_4 = (() => dt7948.n(this.prototype, "switchPanel", [action]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#each @buttons as |button|}}
        <DButton
          @action={{fn this.switchPanel button}}
          @icon={{button.switchButtonIcon}}
          @disabled={{this.isSwitching}}
          @translatedLabel={{button.switchButtonLabel}}
          data-key={{button.key}}
          class="btn-default sidebar__panel-switch-button"
        />
      {{/each}}
    
  */
  {
    "id": "pA0yonLl",
    "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[32,0],[[16,\"data-key\",[30,2,[\"key\"]]],[24,0,\"btn-default sidebar__panel-switch-button\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[28,[32,1],[[30,0,[\"switchPanel\"]],[30,2]],null],[30,2,[\"switchButtonIcon\"]],[30,0,[\"isSwitching\"]],[30,2,[\"switchButtonLabel\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[\"@buttons\",\"button\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/switch-panel-buttons.js",
    "scope": () => [DButton, fn],
    "isStrictMode": true
  }), this))();
}
import { tracked } from "@glimmer/tracking";
import { cancel } from "@ember/runloop";
import Service from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
const CONNECTIVITY_ERROR_CLASS = "network-disconnected";
const NetworkConnectivity = dt7948.c(class NetworkConnectivity extends Service {
  static #_ = (() => dt7948.g(this.prototype, "connected", [tracked], function () {
    return true;
  }))();
  #connected = (() => (dt7948.i(this, "connected"), void 0))();
  constructor() {
    super(...arguments);
    window.addEventListener("offline", () => {
      this.setConnectivity(false);
      this.startTimerToCheckNavigator();
    });
    window.addEventListener("online", this.pingServerAndSetConnectivity);
    window.addEventListener("visibilitychange", this.onFocus);
    if (!navigator.onLine) {
      this.pingServerAndSetConnectivity();
    }
  }
  onFocus() {
    if (!this.connected && document.visibilityState === "visible") {
      this.pingServerAndSetConnectivity();
    }
  }
  static #_2 = (() => dt7948.n(this.prototype, "onFocus", [bind]))();
  async pingServerAndSetConnectivity() {
    try {
      let response = await ajax("/srv/status", {
        dataType: "text"
      });
      if (response === "ok") {
        cancel(this._timer);
        this.setConnectivity(true);
      } else {
        throw "disconnected";
      }
    } catch {
      // Either the request didn't go out at all or the response wasn't "ok". Both are failures.
      // Start the timer to check every second if `navigator.onLine` comes back online in the event that
      // we miss the `online` event firing
      this.startTimerToCheckNavigator();
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "pingServerAndSetConnectivity", [bind]))();
  startTimerToCheckNavigator() {
    cancel(this._timer);
    this._timer = discourseDebounce(this, this.checkNavigatorOnline, 1000);
  }
  static #_4 = (() => dt7948.n(this.prototype, "startTimerToCheckNavigator", [bind]))();
  checkNavigatorOnline() {
    if (navigator.onLine) {
      this.pingServerAndSetConnectivity();
    } else {
      this.startTimerToCheckNavigator();
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "checkNavigatorOnline", [bind]))();
  setConnectivity(connected) {
    this.connected = connected;
    document.documentElement.classList.toggle(CONNECTIVITY_ERROR_CLASS, !connected);
  }
}, [disableImplicitInjections]);
export default NetworkConnectivity;
import Controller, { inject as controller } from "@ember/controller";
import { service } from "@ember/service";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(dt7948.p({
  currentUser: service(),
  user: controller(),
  userActionType: null,
  draftLabel(count) {
    return count > 0 ? I18n.t("drafts.label_with_count", {
      count
    }) : I18n.t("drafts.label");
  },
  pendingLabel(count) {
    return count > 0 ? I18n.t("pending_posts.label_with_count", {
      count
    }) : I18n.t("pending_posts.label");
  }
}, [["method", "draftLabel", [discourseComputed("currentUser.draft_count")]], ["method", "pendingLabel", [discourseComputed("model.pending_posts_count")]]]));
import { service } from "@ember/service";
import { userPath } from "discourse/lib/url";
import { postUrl } from "discourse/lib/utilities";
import { NEW_PRIVATE_MESSAGE_KEY, NEW_TOPIC_KEY } from "discourse/models/composer";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default class UserDraft extends RestModel {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  editableDraft(draftUsername) {
    return draftUsername === this.currentUser?.get("username");
  }
  static #_2 = (() => dt7948.n(this.prototype, "editableDraft", [discourseComputed("draft_username")]))();
  userUrl(usernameLower) {
    return userPath(usernameLower);
  }
  static #_3 = (() => dt7948.n(this.prototype, "userUrl", [discourseComputed("username_lower")]))();
  postUrl(topicId) {
    if (!topicId) {
      return;
    }
    return postUrl(this.slug, this.topic_id, this.post_number);
  }
  static #_4 = (() => dt7948.n(this.prototype, "postUrl", [discourseComputed("topic_id")]))();
  draftType(draftKey) {
    switch (draftKey) {
      case NEW_TOPIC_KEY:
        return I18n.t("drafts.new_topic");
      case NEW_PRIVATE_MESSAGE_KEY:
        return I18n.t("drafts.new_private_message");
      default:
        return false;
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "draftType", [discourseComputed("draft_key")]))();
}
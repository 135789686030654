import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import { notEq } from "truth-helpers";
import bodyClass from "discourse/helpers/body-class";
import deprecated from "discourse-common/lib/deprecated";
// Can add a body class from within a component
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DSection extends Component {
  constructor() {
    super(...arguments);
    deprecated(`<DSection> is deprecated. Use {{body-class "foo-page" "bar"}} and/or <section></section> instead.`, {
      since: "3.2.0.beta1",
      dropFrom: "3.3.0.beta1",
      id: "discourse.d-section"
    });
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if @pageClass}}
        {{bodyClass (concat @pageClass "-page")}}
      {{/if}}
  
      {{#if @bodyClass}}
        {{bodyClass @bodyClass}}
      {{/if}}
  
      {{#if (notEq @tagName "")}}
        <section id={{@id}} class={{@class}} ...attributes>{{yield}}</section>
      {{else}}
        {{yield}}
      {{/if}}
    
  */
  {
    "id": "DzxK1IWm",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[1,[28,[32,0],[[28,[32,1],[[30,1],\"-page\"],null]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,2],[[30,3],\"\"],null],[[[1,\"      \"],[11,\"section\"],[16,1,[30,4]],[16,0,[30,5]],[17,6],[12],[18,7,null],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,7,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@pageClass\",\"@bodyClass\",\"@tagName\",\"@id\",\"@class\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-section.js",
    "scope": () => [bodyClass, concat, notEq],
    "isStrictMode": true
  }), this))();
}
import { tracked } from "@glimmer/tracking";
export default class QuoteState {
  static #_ = (() => dt7948.g(this.prototype, "postId", [tracked], function () {
    return null;
  }))();
  #postId = (() => (dt7948.i(this, "postId"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "buffer", [tracked], function () {
    return "";
  }))();
  #buffer = (() => (dt7948.i(this, "buffer"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "opts", [tracked], function () {
    return null;
  }))();
  #opts = (() => (dt7948.i(this, "opts"), void 0))();
  selected(postId, buffer, opts) {
    this.postId = postId;
    this.buffer = buffer;
    this.opts = opts;
  }
  clear() {
    this.buffer = "";
    this.postId = null;
    this.opts = null;
  }
}
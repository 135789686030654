/* import __COLOCATED_TEMPLATE__ from './calendar-date-time-input.hbs'; */
/* global Pikaday:true */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { Promise } from "rsvp";
import loadScript from "discourse/lib/load-script";
import I18n from "discourse-i18n";
import TEMPLATE from "./calendar-date-time-input.hbs";
import { setComponentTemplate } from "@ember/component";
export default class CalendarDateTimeInput extends Component {
  _timeFormat = (() => this.args.timeFormat || "HH:mm:ss")();
  _dateFormat = (() => this.args.dateFormat || "YYYY-MM-DD")();
  _dateTimeFormat = (() => this.args.dateTimeFormat || "YYYY-MM-DD HH:mm:ss")();
  _picker = null;
  static #_ = (() => dt7948.g(this.prototype, "_time", [tracked]))();
  #_time = (() => (dt7948.i(this, "_time"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "_date", [tracked]))();
  #_date = (() => (dt7948.i(this, "_date"), void 0))();
  setupInternalDateTime() {
    this._time = this.args.time;
    this._date = this.args.date;
  }
  static #_3 = (() => dt7948.n(this.prototype, "setupInternalDateTime", [action]))();
  setupPikaday(element) {
    this.#setupPicker(element).then(picker => {
      this._picker = picker;
    });
  }
  static #_4 = (() => dt7948.n(this.prototype, "setupPikaday", [action]))();
  onChangeTime(event) {
    this._time = event.target.value;
    this.args.onChangeTime(this._time);
  }
  static #_5 = (() => dt7948.n(this.prototype, "onChangeTime", [action]))();
  changeDate() {
    if (moment(this.args.date, this._dateFormat).isValid()) {
      this._date = this.args.date;
      this._picker.setDate(moment.utc(this._date).format(this._dateFormat), true);
    } else {
      this._date = null;
      this._picker.setDate(null);
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "changeDate", [action]))();
  changeTime() {
    if (isEmpty(this.args.time)) {
      this._time = null;
      return;
    }
    if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.args.time)) {
      this._time = this.args.time;
    }
  }
  static #_7 = (() => dt7948.n(this.prototype, "changeTime", [action]))();
  changeMinDate() {
    if (this.args.minDate && moment(this.args.minDate, this._dateFormat).isValid()) {
      this._picker.setMinDate(moment(this.args.minDate, this._dateFormat).toDate());
    } else {
      this._picker.setMinDate(null);
    }
  }
  static #_8 = (() => dt7948.n(this.prototype, "changeMinDate", [action]))();
  #setupPicker(element) {
    return new Promise(resolve => {
      loadScript("/javascripts/pikaday.js").then(() => {
        const options = {
          field: element.querySelector(".fake-input"),
          container: element.querySelector(`#picker-container-${this.args.datePickerId}`),
          bound: false,
          format: "YYYY-MM-DD",
          reposition: false,
          firstDay: 1,
          setDefaultDate: true,
          keyboardInput: false,
          i18n: {
            previousMonth: I18n.t("dates.previous_month"),
            nextMonth: I18n.t("dates.next_month"),
            months: moment.months(),
            weekdays: moment.weekdays(),
            weekdaysShort: moment.weekdaysMin()
          },
          onSelect: date => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            this.args.onChangeDate(formattedDate);
          }
        };
        resolve(new Pikaday(options));
      });
    });
  }
}
setComponentTemplate(TEMPLATE, CalendarDateTimeInput);
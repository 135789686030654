import Component from "@glimmer/component";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { bind } from "discourse-common/utils/decorators";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class IframedHtml extends Component {
  writeHtml(element1) {
    const iframeDoc1 = element1.contentWindow.document;
    iframeDoc1.open("text/html", "replace");
    iframeDoc1.write(this.args.html);
    iframeDoc1.close();
  }
  static #_ = (() => dt7948.n(this.prototype, "writeHtml", [bind]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{! template-lint-disable require-iframe-title }}
      <iframe
        {{didInsert this.writeHtml}}
        {{didUpdate this.witeHtml @html}}
        sandbox="allow-same-origin"
        class={{if @html "iframed-html"}}
        ...attributes
      ></iframe>
    
  */
  {
    "id": "2zu/LRV7",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[11,\"iframe\"],[24,\"sandbox\",\"allow-same-origin\"],[16,0,[52,[30,1],\"iframed-html\"]],[17,2],[4,[32,0],[[30,0,[\"writeHtml\"]]],null],[4,[32,1],[[30,0,[\"witeHtml\"]],[30,1]],null],[12],[13],[1,\"\\n  \"]],[\"@html\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/iframed-html.js",
    "scope": () => [didInsert, didUpdate],
    "isStrictMode": true
  }), this))();
}
import { service } from "@ember/service";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class Users extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  queryParams = (() => ({
    period: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    asc: {
      refreshModel: true
    },
    name: {
      refreshModel: false,
      replace: true
    },
    group: {
      refreshModel: true
    },
    exclude_groups: {
      refreshModel: true
    },
    exclude_usernames: {
      refreshModel: true
    }
  }))();
  titleToken() {
    return I18n.t("directory.title");
  }
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        period: "weekly",
        order: "likes_received",
        asc: null,
        name: "",
        group: null,
        exclude_usernames: null,
        exclude_groups: null,
        lastUpdatedAt: null
      });
    }
  }
  beforeModel() {
    if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
      this.router.replaceWith("discovery");
    }
  }
  model(params) {
    return ajax("/directory-columns.json").then(response => {
      params.order = params.order || response.directory_columns[0]?.name || "likes_received";
      return {
        params,
        columns: response.directory_columns
      };
    }).catch(popupAjaxError);
  }
  setupController(controller, model) {
    controller.set("columns", model.columns);
    return Promise.all([controller.loadGroups(), controller.loadUsers(model.params)]);
  }
}
import { tracked } from "@glimmer/tracking";
import { registerDestructor } from "@ember/destroyable";
import Service, { service } from "@ember/service";
import { TrackedMap } from "@ember-compat/tracked-built-ins";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
const Header = dt7948.c(class Header extends Service {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "topic", [tracked], function () {
    return null;
  }))();
  #topic = (() => (dt7948.i(this, "topic"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "hamburgerVisible", [tracked], function () {
    return false;
  }))();
  #hamburgerVisible = (() => (dt7948.i(this, "hamburgerVisible"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "userVisible", [tracked], function () {
    return false;
  }))();
  #userVisible = (() => (dt7948.i(this, "userVisible"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "anyWidgetHeaderOverrides", [tracked], function () {
    return false;
  }))();
  #anyWidgetHeaderOverrides = (() => (dt7948.i(this, "anyWidgetHeaderOverrides"), void 0))();
  #hiders = (() => new TrackedMap())();
  get useGlimmerHeader() {
    if (this.siteSettings.glimmer_header_mode === "disabled") {
      return false;
    } else if (this.siteSettings.glimmer_header_mode === "enabled") {
      return true;
    } else {
      // Auto
      if (this.anyWidgetHeaderOverrides) {
        // eslint-disable-next-line no-console
        console.warn("Using legacy 'widget' header because themes and/or plugins are using deprecated APIs. https://meta.discourse.org/t/296544");
        return false;
      } else {
        return true;
      }
    }
  }
  registerHider(ref, buttons) {
    this.#hiders.set(ref, buttons);
    registerDestructor(ref, () => {
      this.#hiders.delete(ref);
    });
  }
  get headerButtonsHidden() {
    const buttonsToHide = new Set();
    this.#hiders.forEach(buttons => {
      buttons.forEach(button => {
        buttonsToHide.add(button);
      });
    });
    return Array.from(buttonsToHide);
  }
}, [disableImplicitInjections]);
export default Header;
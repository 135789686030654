import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <p>{{i18n "topics.bulk.choose_append_tags"}}</p>

<p><TagChooser @tags={{this.tags}} @categoryId={{@categoryId}} /></p>

<DButton
  @action={{fn @performAndRefresh (hash type="append_tags" tags=this.tags)}}
  @disabled={{not this.tags}}
  @label="topics.bulk.append_tags"
  class="topic-bulk-actions__append-tags"
/>
*/
{
  "id": "kbJC73MY",
  "block": "[[[10,2],[12],[1,[28,[35,0],[\"topics.bulk.choose_append_tags\"],null]],[13],[1,\"\\n\\n\"],[10,2],[12],[8,[39,1],null,[[\"@tags\",\"@categoryId\"],[[30,0,[\"tags\"]],[30,1]]],null],[13],[1,\"\\n\\n\"],[8,[39,2],[[24,0,\"topic-bulk-actions__append-tags\"]],[[\"@action\",\"@disabled\",\"@label\"],[[28,[37,3],[[30,2],[28,[37,4],null,[[\"type\",\"tags\"],[\"append_tags\",[30,0,[\"tags\"]]]]]],null],[28,[37,5],[[30,0,[\"tags\"]]],null],\"topics.bulk.append_tags\"]],null]],[\"@categoryId\",\"@performAndRefresh\"],false,[\"i18n\",\"tag-chooser\",\"d-button\",\"fn\",\"hash\",\"not\"]]",
  "moduleName": "discourse/components/bulk-actions/append-tags.hbs",
  "isStrictMode": false
});
import Controller, { inject as controller } from "@ember/controller";
import { alias } from "@ember/object/computed";
import { duration } from "discourse/lib/formatter";
import discourseComputed from "discourse-common/utils/decorators";

// should be kept in sync with 'UserSummary::MAX_BADGES'
const MAX_BADGES = 6;
export default Controller.extend(dt7948.p({
  userController: controller("user"),
  user: alias("userController.model"),
  moreBadges(badgesLength) {
    return badgesLength >= MAX_BADGES;
  },
  timeRead(timeReadSeconds) {
    return duration(timeReadSeconds, {
      format: "tiny"
    });
  },
  timeReadMedium(timeReadSeconds) {
    return duration(timeReadSeconds, {
      format: "medium"
    });
  },
  showRecentTimeRead(timeRead, recentTimeRead) {
    return timeRead !== recentTimeRead && recentTimeRead !== 0;
  },
  recentTimeRead(recentTimeReadSeconds) {
    return recentTimeReadSeconds > 0 ? duration(recentTimeReadSeconds, {
      format: "tiny"
    }) : null;
  },
  recentTimeReadMedium(recentTimeReadSeconds) {
    return recentTimeReadSeconds > 0 ? duration(recentTimeReadSeconds, {
      format: "medium"
    }) : null;
  }
}, [["method", "moreBadges", [discourseComputed("model.badges.length")]], ["method", "timeRead", [discourseComputed("model.time_read")]], ["method", "timeReadMedium", [discourseComputed("model.time_read")]], ["method", "showRecentTimeRead", [discourseComputed("model.time_read", "model.recent_time_read")]], ["method", "recentTimeRead", [discourseComputed("model.recent_time_read")]], ["method", "recentTimeReadMedium", [discourseComputed("model.recent_time_read")]]]));
/* import __COLOCATED_TEMPLATE__ from './flag.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { reload } from "discourse/helpers/page-reloader";
import { MAX_MESSAGE_LENGTH } from "discourse/models/post-action-type";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./flag.hbs";
import { setComponentTemplate } from "@ember/component";
const NOTIFY_MODERATORS_KEY = "notify_moderators";
export default class Flag extends Component {
  static #_ = (() => dt7948.g(this.prototype, "adminTools", [service]))();
  #adminTools = (() => (dt7948.i(this, "adminTools"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "userDetails", [tracked]))();
  #userDetails = (() => (dt7948.i(this, "userDetails"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "selected", [tracked]))();
  #selected = (() => (dt7948.i(this, "selected"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "message", [tracked]))();
  #message = (() => (dt7948.i(this, "message"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "isConfirmed", [tracked], function () {
    return false;
  }))();
  #isConfirmed = (() => (dt7948.i(this, "isConfirmed"), void 0))();
  static #_10 = (() => dt7948.g(this.prototype, "isWarning", [tracked], function () {
    return false;
  }))();
  #isWarning = (() => (dt7948.i(this, "isWarning"), void 0))();
  static #_11 = (() => dt7948.g(this.prototype, "spammerDetails", [tracked]))();
  #spammerDetails = (() => (dt7948.i(this, "spammerDetails"), void 0))();
  constructor() {
    super(...arguments);
    this.adminTools?.checkSpammer(this.args.model.flagModel.user_id).then(result => this.spammerDetails = result);
  }
  get flagActions() {
    return {
      icon: "gavel",
      label: I18n.t("flagging.take_action"),
      actions: [{
        id: "agree_and_hide",
        icon: "thumbs-up",
        label: I18n.t("flagging.take_action_options.default.title"),
        description: I18n.t("flagging.take_action_options.default.details")
      }, {
        id: "agree_and_suspend",
        icon: "ban",
        label: I18n.t("flagging.take_action_options.suspend.title"),
        description: I18n.t("flagging.take_action_options.suspend.details"),
        client_action: "suspend"
      }, {
        id: "agree_and_silence",
        icon: "microphone-slash",
        label: I18n.t("flagging.take_action_options.silence.title"),
        description: I18n.t("flagging.take_action_options.silence.details"),
        client_action: "silence"
      }]
    };
  }
  get canSendWarning() {
    return !this.args.model.flagTarget.targetsTopic() && this.currentUser.staff && this.selected?.name_key === "notify_user";
  }
  get showDeleteSpammer() {
    return this.spammerDetails?.canDelete && this.selected?.name_key === "spam";
  }
  get submitLabel() {
    if (this.selected?.is_custom_flag) {
      return this.args.model.flagTarget.customSubmitLabel();
    }
    return this.args.model.flagTarget.submitLabel();
  }
  get title() {
    return this.args.model.flagTarget.title();
  }
  get flagsAvailable() {
    return this.args.model.flagTarget.flagsAvailable(this).filterBy("enabled");
  }
  get staffFlagsAvailable() {
    return this.flagsAvailable.length > 1;
  }
  get submitEnabled() {
    if (!this.selected) {
      return false;
    }
    if (!this.selected.is_custom_flag) {
      return true;
    }
    if (this.selected.isIllegal && !this.isConfirmed) {
      return false;
    }
    const len = this.message?.length || 0;
    return len >= this.siteSettings.min_personal_message_post_length && len <= MAX_MESSAGE_LENGTH;
  }
  get notifyModeratorsFlag() {
    return this.flagsAvailable.find(f => f.id === NOTIFY_MODERATORS_KEY);
  }
  get canTakeAction() {
    return !this.args.model.flagTarget.targetsTopic() && !this.selected?.is_custom_flag && this.currentUser.staff;
  }
  onKeydown(event) {
    if (this.submitEnabled && event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      this.createFlag();
      return false;
    }
  }
  static #_12 = (() => dt7948.n(this.prototype, "onKeydown", [action]))();
  async penalize(adminToolMethod, performAction) {
    if (!this.adminTools) {
      return;
    }
    const createdBy = await User.findByUsername(this.args.model.flagModel.username);
    const opts = {
      before: performAction
    };
    if (this.args.model.flagTarget.editable()) {
      opts.postId = this.args.model.flagModel.id;
      opts.postEdit = this.args.model.flagModel.cooked;
    }
    return this.adminTools[adminToolMethod](createdBy, opts);
  }
  static #_13 = (() => dt7948.n(this.prototype, "penalize", [action]))();
  async deleteSpammer() {
    if (this.spammerDetails) {
      await this.spammerDetails.deleteUser();
      reload();
    }
  }
  static #_14 = (() => dt7948.n(this.prototype, "deleteSpammer", [action]))();
  async takeAction(actionable) {
    if (actionable.client_action === "suspend") {
      await this.penalize("showSuspendModal", () => this.createFlag({
        takeAction: true,
        skipClose: true
      }));
    } else if (actionable.client_action === "silence") {
      await this.penalize("showSilenceModal", () => this.createFlag({
        takeAction: true,
        skipClose: true
      }));
    } else if (actionable.client_action) {
      // eslint-disable-next-line no-console
      console.error(`No handler for ${actionable.client_action} found`);
    } else {
      this.args.model.setHidden();
      this.createFlag({
        takeAction: true
      });
    }
  }
  static #_15 = (() => dt7948.n(this.prototype, "takeAction", [action]))();
  createFlag() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (this.selected.is_custom_flag) {
      opts.message = this.message;
    }
    this.args.model.flagTarget.create(this, opts);
  }
  static #_16 = (() => dt7948.n(this.prototype, "createFlag", [action]))();
  createFlagAsWarning() {
    this.createFlag({
      isWarning: true
    });
    this.args.model.setHidden();
  }
  static #_17 = (() => dt7948.n(this.prototype, "createFlagAsWarning", [action]))();
  flagForReview() {
    this.selected ||= this.notifyModeratorsFlag;
    this.createFlag({
      queue_for_review: true
    });
    this.args.model.setHidden();
  }
  static #_18 = (() => dt7948.n(this.prototype, "flagForReview", [action]))();
  changePostActionType(actionType) {
    this.selected = actionType;
  }
  static #_19 = (() => dt7948.n(this.prototype, "changePostActionType", [action]))();
}
setComponentTemplate(TEMPLATE, Flag);
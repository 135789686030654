/* import __COLOCATED_TEMPLATE__ from './badge-card.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import { emojiUnescape, sanitize } from "discourse/lib/text";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./badge-card.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  size: "medium",
  classNameBindings: [":badge-card", "size", "badge.slug"],
  url(badgeUrl, filterUser, username) {
    return filterUser ? `${badgeUrl}?username=${username}` : badgeUrl;
  },
  displayCount(count, grantCount) {
    if (count == null) {
      return grantCount;
    }
    if (count > 1) {
      return count;
    }
  },
  summary(size, longDescription, description) {
    if (size === "large") {
      if (!isEmpty(longDescription)) {
        return emojiUnescape(sanitize(longDescription));
      }
    }
    return sanitize(description);
  },
  showFavorite(badgeId) {
    return ![1, 2, 3, 4].includes(badgeId);
  }
}, [["method", "url", [discourseComputed("badge.url", "filterUser", "username")]], ["method", "displayCount", [discourseComputed("count", "badge.grant_count")]], ["method", "summary", [discourseComputed("size", "badge.long_description", "badge.description")]], ["method", "showFavorite", [discourseComputed("badge.id")]]])));
/* import __COLOCATED_TEMPLATE__ from './navigation.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import ReorderCategories from "discourse/components/modal/reorder-categories";
import { calculateFilterMode } from "discourse/lib/filter-mode";
import { TRACKED_QUERY_PARAM_VALUE } from "discourse/lib/topic-list-tracked-filter";
import DiscourseURL from "discourse/lib/url";
import Category from "discourse/models/category";
import TEMPLATE from "./navigation.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DiscoveryNavigation extends Component {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  get filterMode() {
    return calculateFilterMode({
      category: this.args.category,
      filterType: this.args.filterType,
      noSubcategories: this.args.noSubcategories
    });
  }
  get skipCategoriesNavItem() {
    return this.router.currentRoute.queryParams.f === TRACKED_QUERY_PARAM_VALUE;
  }
  get canCreateTopic() {
    return this.currentUser?.can_create_topic;
  }
  get bodyClass() {
    if (this.args.tag) {
      return ["tags-page", this.args.additionalTags ? "tags-intersection" : null].filter(Boolean).join(" ");
    } else if (this.filterMode === "categories") {
      return "navigation-categories";
    } else if (this.args.category) {
      return "navigation-category";
    } else {
      return "navigation-topics";
    }
  }
  editCategory() {
    DiscourseURL.routeTo(`/c/${Category.slugFor(this.args.category)}/edit`);
  }
  static #_4 = (() => dt7948.n(this.prototype, "editCategory", [action]))();
  createCategory() {
    this.router.transitionTo("newCategory");
  }
  static #_5 = (() => dt7948.n(this.prototype, "createCategory", [action]))();
  reorderCategories() {
    this.modal.show(ReorderCategories);
  }
  static #_6 = (() => dt7948.n(this.prototype, "reorderCategories", [action]))();
}
setComponentTemplate(TEMPLATE, DiscoveryNavigation);
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { isDocumentRTL } from "discourse/lib/text-direction";
import { prefersReducedMotion } from "discourse/lib/utilities";
import { isTesting } from "discourse-common/config/environment";
import discourseLater from "discourse-common/lib/later";
import closeOnClickOutside from "../../modifiers/close-on-click-outside";
import UserMenu from "../user-menu/menu";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserMenuWrapper extends Component {
  clickOutside(e1) {
    if (e1.target.classList.contains("header-cloak") && !prefersReducedMotion()) {
      const panel1 = document.querySelector(".menu-panel");
      const headerCloak1 = document.querySelector(".header-cloak");
      const finishPosition1 = isDocumentRTL() ? "-340px" : "340px";
      panel1.animate([{
        transform: `translate3d(${finishPosition1}, 0, 0)`
      }], {
        duration: 200,
        fill: "forwards",
        easing: "ease-in"
      }).finished.then(() => {
        if (isTesting()) {
          this.args.toggleUserMenu();
        } else {
          discourseLater(() => this.args.toggleUserMenu());
        }
      });
      headerCloak1.animate([{
        opacity: 0
      }], {
        duration: 200,
        fill: "forwards",
        easing: "ease-in"
      });
    } else {
      this.args.toggleUserMenu();
    }
  }
  static #_ = (() => dt7948.n(this.prototype, "clickOutside", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        class="user-menu-dropdown-wrapper"
        {{closeOnClickOutside
          this.clickOutside
          (hash
            targetSelector=".user-menu-panel"
            secondaryTargetSelector=".user-menu-panel"
          )
        }}
      >
        <UserMenu @closeUserMenu={{@toggleUserMenu}} />
      </div>
    
  */
  {
    "id": "Mm9QfTxa",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"user-menu-dropdown-wrapper\"],[4,[32,0],[[30,0,[\"clickOutside\"]],[28,[32,1],null,[[\"targetSelector\",\"secondaryTargetSelector\"],[\".user-menu-panel\",\".user-menu-panel\"]]]],null],[12],[1,\"\\n      \"],[8,[32,2],null,[[\"@closeUserMenu\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@toggleUserMenu\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/user-menu-wrapper.js",
    "scope": () => [closeOnClickOutside, hash, UserMenu],
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './not-activated.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { resendActivationEmail } from "discourse/lib/user-activation";
import ActivationEdit from "./activation-edit";
import ActivationResent from "./activation-resent";
import TEMPLATE from "./not-activated.hbs";
import { setComponentTemplate } from "@ember/component";
export default class NotActivated extends Component {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  sendActivationEmail() {
    resendActivationEmail(this.args.model.currentEmail).then(() => {
      this.modal.show(ActivationResent, {
        model: {
          currentEmail: this.args.model.currentEmail
        }
      });
    });
  }
  static #_2 = (() => dt7948.n(this.prototype, "sendActivationEmail", [action]))();
  editActivationEmail() {
    this.modal.show(ActivationEdit, {
      model: {
        currentEmail: this.args.model.currentEmail,
        newEmail: this.args.model.currentEmail
      }
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "editActivationEmail", [action]))();
}
setComponentTemplate(TEMPLATE, NotActivated);
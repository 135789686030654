import icon from "discourse-common/helpers/d-icon";
import Logo from "./logo";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const HomeLogoContents = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @minimized}}
    {{#if @logoSmallUrl}}
      <Logo
        @key="logo-small"
        @url={{@logoSmallUrl}}
        @title={{@title}}
        @darkUrl={{@logoSmallUrlDark}}
      />
    {{else}}
      {{icon "home"}}
    {{/if}}
  {{else if @showMobileLogo}}
    <Logo
      @key="logo-mobile"
      @url={{@mobileLogoUrl}}
      @title={{@title}}
      @darkUrl={{@mobileLogoUrlDark}}
    />
  {{else if @logoUrl}}
    <Logo
      @key="logo-big"
      @url={{@logoUrl}}
      @title={{@title}}
      @darkUrl={{@logoUrlDark}}
    />
  {{else}}
    <h1 id="site-text-logo" class="text-logo">
      {{@title}}
    </h1>
  {{/if}}

*/
{
  "id": "lNIKKHLd",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[41,[30,2],[[[1,\"      \"],[8,[32,0],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-small\",[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[32,1],[\"home\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,5],[[[1,\"    \"],[8,[32,0],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-mobile\",[30,6],[30,3],[30,7]]],null],[1,\"\\n\"]],[]],[[[41,[30,8],[[[1,\"    \"],[8,[32,0],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-big\",[30,8],[30,3],[30,9]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h1\"],[14,1,\"site-text-logo\"],[14,0,\"text-logo\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]]],[]]]],[\"@minimized\",\"@logoSmallUrl\",\"@title\",\"@logoSmallUrlDark\",\"@showMobileLogo\",\"@mobileLogoUrl\",\"@mobileLogoUrlDark\",\"@logoUrl\",\"@logoUrlDark\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/home-logo-contents.js",
  "scope": () => [Logo, icon],
  "isStrictMode": true
}), templateOnly());
export default HomeLogoContents;
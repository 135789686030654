/* import __COLOCATED_TEMPLATE__ from './group-post.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { propertyEqual } from "discourse/lib/computed";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { userPath } from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-post.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  classNameBindings: [":user-stream-item", ":item", "moderatorAction", "primaryGroup"],
  postUrl(url) {
    return getURL(url);
  },
  moderatorAction: propertyEqual("post.post_type", "site.post_types.moderator_action"),
  name(postUser) {
    if (prioritizeNameInUx(postUser.name)) {
      return postUser.name;
    }
    return postUser.username;
  },
  primaryGroup(postUser) {
    if (postUser.primary_group_name) {
      return `group-${postUser.primary_group_name}`;
    }
  },
  userUrl(username) {
    return userPath(username.toLowerCase());
  },
  titleAriaLabel(title, postNumber) {
    return I18n.t("groups.aria_post_number", {
      postNumber,
      title
    });
  }
}, [["method", "postUrl", [discourseComputed("post.url")]], ["method", "name", [discourseComputed("post.user")]], ["method", "primaryGroup", [discourseComputed("post.user")]], ["method", "userUrl", [discourseComputed("post.user.username")]], ["method", "titleAriaLabel", [discourseComputed("post.title", "post.post_number")]]])));
/* import __COLOCATED_TEMPLATE__ from './reviewable-claimed-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-claimed-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  enabled() {
    return this.siteSettings.reviewable_claiming !== "disabled";
  },
  actions: {
    unclaim() {
      ajax(`/reviewable_claimed_topics/${this.topicId}`, {
        type: "DELETE"
      }).then(() => {
        this.set("claimedBy", null);
      });
    },
    claim() {
      let claim = this.store.createRecord("reviewable-claimed-topic");
      claim.save({
        topic_id: this.topicId
      }).then(() => {
        this.set("claimedBy", this.currentUser);
      }).catch(popupAjaxError);
    }
  }
}, [["method", "enabled", [discourseComputed]]])));
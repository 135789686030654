/* import __COLOCATED_TEMPLATE__ from './slow-mode-info.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { durationTextFromSeconds } from "discourse/helpers/slow-mode";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Topic from "discourse/models/topic";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./slow-mode-info.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  durationText(seconds) {
    return durationTextFromSeconds(seconds);
  },
  showSlowModeNotice(seconds, closed) {
    return seconds > 0 && !closed;
  },
  disableSlowMode() {
    Topic.setSlowMode(this.topic.id, 0).catch(popupAjaxError).then(() => this.set("topic.slow_mode_seconds", 0));
  }
}, [["method", "durationText", [discourseComputed("topic.slow_mode_seconds")]], ["method", "showSlowModeNotice", [discourseComputed("topic.slow_mode_seconds", "topic.closed")]], ["method", "disableSlowMode", [action]]])));
import Component from "@glimmer/component";
import { LinkTo } from "@ember/routing";
import { service } from "@ember/service";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import { defaultHomepage } from "discourse/lib/utilities";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class BackToForum extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get shouldDisplay() {
    return this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldDisplay}}
        <LinkTo
          @route="discovery.{{(defaultHomepage)}}"
          class="sidebar-sections__back-to-forum"
        >
          {{icon "arrow-left"}}
  
          <span>{{i18n "admin.back_to_forum"}}</span>
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "i1F34WgT",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"sidebar-sections__back-to-forum\"]],[[\"@route\"],[[29,[\"discovery.\",[28,[32,1],null,null]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,2],[\"arrow-left\"],null]],[1,\"\\n\\n        \"],[10,1],[12],[1,[28,[32,3],[\"admin.back_to_forum\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/back-to-forum.js",
    "scope": () => [LinkTo, defaultHomepage, icon, i18n],
    "isStrictMode": true
  }), this))();
}
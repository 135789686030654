import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
export default class extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "tagsForUser", [tracked], function () {
    return null;
  }))();
  #tagsForUser = (() => (dt7948.i(this, "tagsForUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "sortedByCount", [tracked], function () {
    return true;
  }))();
  #sortedByCount = (() => (dt7948.i(this, "sortedByCount"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "sortedByName", [tracked], function () {
    return false;
  }))();
  #sortedByName = (() => (dt7948.i(this, "sortedByName"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "sortProperties", [tracked], function () {
    return ["count:desc", "id"];
  }))();
  #sortProperties = (() => (dt7948.i(this, "sortProperties"), void 0))();
  sortByCount(event) {
    event?.preventDefault();
    this.sortProperties = ["count:desc", "id"];
    this.sortedByCount = true;
    this.sortedByName = false;
  }
  static #_5 = (() => dt7948.n(this.prototype, "sortByCount", [action]))();
  sortById(event) {
    event?.preventDefault();
    this.sortProperties = ["id"];
    this.sortedByCount = false;
    this.sortedByName = true;
  }
  static #_6 = (() => dt7948.n(this.prototype, "sortById", [action]))();
}
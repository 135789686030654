import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
export default class ConfirmNewEmailController extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "loading", [tracked]))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  async confirm() {
    this.loading = true;
    try {
      await ajax(`/u/confirm-new-email/${this.model.token}.json`, {
        type: "PUT"
      });
    } catch (error) {
      const nonce = error.jqXHR?.responseJSON?.second_factor_challenge_nonce;
      if (nonce) {
        this.router.transitionTo("second-factor-auth", {
          queryParams: {
            nonce
          }
        });
      } else {
        popupAjaxError(error);
      }
      return;
    } finally {
      this.loading = false;
    }
    await new Promise(resolve => this.dialog.dialog({
      message: I18n.t("user.change_email.confirm_success"),
      type: "alert",
      didConfirm: resolve
    }));
    this.router.transitionTo("/my/preferences/account");
  }
  static #_4 = (() => dt7948.n(this.prototype, "confirm", [action]))();
}
import Route from "@ember/routing/route";
import { service } from "@ember/service";
export default class GroupActivityIndex extends Route {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  beforeModel() {
    const group = this.modelFor("group");
    if (group.can_see_members) {
      this.router.transitionTo("group.activity.posts");
    } else {
      this.router.transitionTo("group.activity.mentions");
    }
  }
}
/* import __COLOCATED_TEMPLATE__ from './uppy-backup-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./uppy-backup-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, dt7948.p({
  id: "uppy-backup-uploader",
  tagName: "span",
  type: "backup",
  uploadRootPath: "/admin/backups",
  uploadUrl: "/admin/backups/upload",
  useMultipartUploadsIfAvailable(localBackupStorage) {
    return !localBackupStorage && this.siteSettings.enable_direct_s3_uploads;
  },
  // local backups
  useChunkedUploads: alias("localBackupStorage"),
  uploadButtonText(uploading, progress) {
    return uploading ? I18n.t("admin.backups.upload.uploading_progress", {
      progress
    }) : I18n.t("admin.backups.upload.label");
  },
  validateUploadedFilesOptions() {
    return {
      skipValidation: true
    };
  },
  uploadDone(responseData) {
    this.done(responseData.file_name);
  }
}, [["method", "useMultipartUploadsIfAvailable", [discourseComputed("localBackupStorage")]], ["method", "uploadButtonText", [discourseComputed("uploading", "uploadProgress")]]])));
import EmberObject from "@ember/object";
import { alias } from "@ember/object/computed";
import { isNone } from "@ember/utils";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { userPath } from "discourse/lib/url";
import Topic from "discourse/models/topic";
import User from "discourse/models/user";
import discourseComputed from "discourse-common/utils/decorators";
export default class Invite extends EmberObject {
  static create() {
    const result = super.create(...arguments);
    if (result.user) {
      result.user = User.create(result.user);
    }
    return result;
  }
  static findInvitedBy(user, filter, search, offset) {
    if (!user) {
      Promise.resolve();
    }
    const data = {};
    if (!isNone(filter)) {
      data.filter = filter;
    }
    if (!isNone(search)) {
      data.search = search;
    }
    data.offset = offset || 0;
    return ajax(userPath(`${user.username_lower}/invited.json`), {
      data
    }).then(result => {
      result.invites = result.invites.map(i => Invite.create(i));
      return EmberObject.create(result);
    });
  }
  static reinviteAll() {
    return ajax("/invites/reinvite-all", {
      type: "POST"
    });
  }
  static destroyAllExpired() {
    return ajax("/invites/destroy-all-expired", {
      type: "POST"
    });
  }
  static #_ = (() => dt7948.g(this.prototype, "topicId", [alias("topics.firstObject.id")]))();
  #topicId = (() => (dt7948.i(this, "topicId"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "topicTitle", [alias("topics.firstObject.title")]))();
  #topicTitle = (() => (dt7948.i(this, "topicTitle"), void 0))();
  save(data) {
    const promise = this.id ? ajax(`/invites/${this.id}`, {
      type: "PUT",
      data
    }) : ajax("/invites", {
      type: "POST",
      data
    });
    return promise.then(result => this.setProperties(result));
  }
  destroy() {
    return ajax("/invites", {
      type: "DELETE",
      data: {
        id: this.id
      }
    }).then(() => this.set("destroyed", true));
  }
  reinvite() {
    return ajax("/invites/reinvite", {
      type: "POST",
      data: {
        email: this.email
      }
    }).then(() => this.set("reinvited", true)).catch(popupAjaxError);
  }
  shortKey(key) {
    return key.slice(0, 4) + "...";
  }
  static #_3 = (() => dt7948.n(this.prototype, "shortKey", [discourseComputed("invite_key")]))();
  groupIds(groups) {
    return groups ? groups.map(group => group.id) : [];
  }
  static #_4 = (() => dt7948.n(this.prototype, "groupIds", [discourseComputed("groups")]))();
  topic(topicData) {
    return topicData ? Topic.create(topicData) : null;
  }
  static #_5 = (() => dt7948.n(this.prototype, "topic", [discourseComputed("topics.firstObject")]))();
  emailOrDomain(email, domain) {
    return email || domain;
  }
  static #_6 = (() => dt7948.n(this.prototype, "emailOrDomain", [discourseComputed("email", "domain")]))();
}
/* import __COLOCATED_TEMPLATE__ from './discourse-tag-bound.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./discourse-tag-bound.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "a",
  classNameBindings: [":discourse-tag", "style", "tagClass"],
  attributeBindings: ["href"],
  tagClass(tagRecordId) {
    return "tag-" + tagRecordId;
  },
  href(tagRecordId) {
    return getURL("/tag/" + tagRecordId);
  }
}, [["method", "tagClass", [discourseComputed("tagRecord.id")]], ["method", "href", [discourseComputed("tagRecord.id")]]])));
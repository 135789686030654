/* import __COLOCATED_TEMPLATE__ from './topic-footer-buttons.hbs'; */
import { getOwner } from "@ember/application";
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { alias, or } from "@ember/object/computed";
import { NotificationLevels } from "discourse/lib/notification-levels";
import { getTopicFooterButtons } from "discourse/lib/register-topic-footer-button";
import { getTopicFooterDropdowns } from "discourse/lib/register-topic-footer-dropdown";
import TopicBookmarkManager from "discourse/lib/topic-bookmark-manager";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-footer-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  elementId: "topic-footer-buttons",
  attributeBindings: ["role"],
  role: "region",
  canArchive(canSendPms, isPM) {
    return canSendPms && isPM;
  },
  inlineButtons: getTopicFooterButtons(),
  inlineDropdowns: getTopicFooterDropdowns(),
  inlineActionables: computed("inlineButtons.[]", "inlineDropdowns.[]", function () {
    return this.inlineButtons.filterBy("dropdown", false).filterBy("anonymousOnly", false).concat(this.inlineDropdowns).sortBy("priority").reverse();
  }),
  topicBookmarkManager: computed("topic", function () {
    return new TopicBookmarkManager(getOwner(this), this.topic);
  }),
  dropdownButtons(inlineButtons) {
    return inlineButtons.filter(button => button.dropdown);
  },
  showNotificationsButton(isPM) {
    return !isPM || this.canSendPms;
  },
  showNotificationUserTip(notificationLevel) {
    return notificationLevel >= NotificationLevels.TRACKING;
  },
  canSendPms: alias("currentUser.can_send_private_messages"),
  canInviteTo: alias("topic.details.can_invite_to"),
  canDefer: alias("currentUser.user_option.enable_defer"),
  inviteDisabled: or("topic.archived", "topic.closed", "topic.deleted"),
  archiveIcon: archived => archived ? "envelope" : "folder",
  archiveTitle: archived => archived ? "topic.move_to_inbox.help" : "topic.archive_message.help",
  archiveLabel: archived => archived ? "topic.move_to_inbox.title" : "topic.archive_message.title",
  showBookmarkLabel: isPM => !isPM
}, [["method", "canArchive", [discourseComputed("canSendPms", "topic.isPrivateMessage")]], ["method", "dropdownButtons", [discourseComputed("inlineButtons.[]", "topic.assigned_to_user")]], ["method", "showNotificationsButton", [discourseComputed("topic.isPrivateMessage")]], ["method", "showNotificationUserTip", [discourseComputed("topic.details.notification_level")]], ["field", "archiveIcon", [discourseComputed("topic.message_archived")]], ["field", "archiveTitle", [discourseComputed("topic.message_archived")]], ["field", "archiveLabel", [discourseComputed("topic.message_archived")]], ["field", "showBookmarkLabel", [discourseComputed("topic.isPrivateMessage")]]])));
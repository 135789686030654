import { reads } from "@ember/object/computed";
import { cook } from "discourse/lib/text";
import { userPath } from "discourse/lib/url";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import Category from "./category";
export default class PendingPost extends RestModel {
  expandedExcerpt = null;
  static #_ = (() => dt7948.g(this.prototype, "postUrl", [reads("topic_url")]))();
  #postUrl = (() => (dt7948.i(this, "postUrl"), void 0))();
  truncated = false;
  init() {
    super.init(...arguments);
    cook(this.raw_text).then(cooked => {
      this.set("expandedExcerpt", cooked);
    });
  }
  userUrl(username) {
    return userPath(username.toLowerCase());
  }
  static #_2 = (() => dt7948.n(this.prototype, "userUrl", [discourseComputed("username")]))();
  category() {
    return Category.findById(this.category_id);
  }
  static #_3 = (() => dt7948.n(this.prototype, "category", [discourseComputed("category_id")]))();
}
/* import __COLOCATED_TEMPLATE__ from './copy-button.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./copy-button.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  copyIcon: "copy",
  copyClass: "btn-primary",
  _restoreButton() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.set("copyIcon", "copy");
    this.set("copyClass", "btn-primary");
  },
  copy() {
    const target = document.querySelector(this.selector);
    target.select();
    target.setSelectionRange(0, target.value.length);
    try {
      document.execCommand("copy");
      if (this.copied) {
        this.copied();
      }
      this.set("copyIcon", "check");
      this.set("copyClass", "btn-primary ok");
      discourseDebounce(this._restoreButton, 3000);
    } catch (err) {}
  }
}, [["method", "_restoreButton", [bind]], ["method", "copy", [action]]])));
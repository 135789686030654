/* import __COLOCATED_TEMPLATE__ from './share-panel.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { alias } from "@ember/object/computed";
import { isEmpty } from "@ember/utils";
import Sharing from "discourse/lib/sharing";
import { escapeExpression } from "discourse/lib/utilities";
import discourseLater from "discourse-common/lib/later";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./share-panel.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: null,
  type: alias("panel.model.type"),
  topic: alias("panel.model.topic"),
  privateCategory: alias("panel.model.topic.category.read_restricted"),
  sources(topic) {
    const privateContext = this.siteSettings.login_required || topic && topic.isPrivateMessage || topic && topic.invisible || this.privateCategory;
    return Sharing.activeSources(this.siteSettings.share_links, privateContext);
  },
  shareTitle(type, topicTitle) {
    topicTitle = escapeExpression(topicTitle);
    return I18n.t("share.topic_html", {
      topicTitle
    });
  },
  shareUrl(forcedShareUrl, shareUrl) {
    shareUrl = forcedShareUrl || shareUrl;
    if (isEmpty(shareUrl)) {
      return;
    }

    // Relative urls
    if (shareUrl.startsWith("/")) {
      const location = window.location;
      shareUrl = `${location.protocol}//${location.host}${shareUrl}`;
    }
    return encodeURI(shareUrl);
  },
  didInsertElement() {
    this._super(...arguments);
    discourseLater(() => {
      if (this.element) {
        const textArea = this.element.querySelector(".topic-share-url");
        textArea.style.height = textArea.scrollHeight + "px";
        textArea.focus();
        textArea.setSelectionRange(0, this.shareUrl.length);
      }
    }, 200);
  },
  share(source) {
    Sharing.shareSource(source, {
      url: this.shareUrl,
      title: this.topic.get("title")
    });
  }
}, [["method", "sources", [discourseComputed("topic.{isPrivateMessage,invisible,category}")]], ["method", "shareTitle", [discourseComputed("type", "topic.title")]], ["method", "shareUrl", [discourseComputed("panel.model.shareUrl", "topic.shareUrl")]], ["method", "share", [action]]])));
import MountWidget from "discourse/components/mount-widget";
import { observes } from "discourse-common/utils/decorators";
export default MountWidget.extend(dt7948.p({
  widget: "avatar-flair",
  _rerender() {
    this.queueRerender();
  },
  buildArgs() {
    return {
      flair_name: this.flairName,
      flair_url: this.flairUrl,
      flair_bg_color: this.flairBgColor,
      flair_color: this.flairColor
    };
  }
}, [["method", "_rerender", [observes("flairName", "flairUrl", "flairBgColor", "flairColor")]]]));
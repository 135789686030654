import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class GroupManage extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  titleToken() {
    return I18n.t("groups.manage.title");
  }
  model() {
    return this.modelFor("group");
  }
  afterModel(group) {
    if (!this.currentUser || !(this.modelFor("group").can_admin_group && group.get("automatic")) && !this.currentUser.canManageGroup(group)) {
      this.router.transitionTo("group.members", group);
    }
  }
  setupController(controller, model) {
    this.controllerFor("group-manage").setProperties({
      model
    });
    this.controllerFor("group").set("showing", "manage");
  }
}
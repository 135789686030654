import Category from "discourse/models/category";
import i18n from "discourse-common/helpers/i18n";
import AllCategoriesSectionLink from "../common/all-categories-section-link";
import SidebarCommonCategoriesSection from "../common/categories-section";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarAnonymousCategoriesSection extends SidebarCommonCategoriesSection {
  shouldSortCategoriesByDefault = (() => !!this.siteSettings.default_navigation_menu_categories)();
  get categories() {
    if (this.siteSettings.default_navigation_menu_categories) {
      return Category.findByIds(this.siteSettings.default_navigation_menu_categories.split("|").map(categoryId1 => parseInt(categoryId1, 10)));
    } else {
      return this.topSiteCategories;
    }
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <Section
        @sectionName="categories"
        @headerLinkText={{i18n "sidebar.sections.categories.header_link_text"}}
        @collapsable={{@collapsable}}
      >
        {{#each this.sectionLinks as |sectionLink|}}
          <SectionLink
            @route={{sectionLink.route}}
            @title={{sectionLink.title}}
            @content={{sectionLink.text}}
            @currentWhen={{sectionLink.currentWhen}}
            @model={{sectionLink.model}}
            @prefixType={{sectionLink.prefixType}}
            @prefixValue={{sectionLink.prefixValue}}
            @prefixColor={{sectionLink.prefixColor}}
            data-category-id={{sectionLink.category.id}}
          />
        {{/each}}
  
        <AllCategoriesSectionLink />
      </Section>
    
  */
  {
    "id": "KOlCCP5R",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@collapsable\"],[\"categories\",[28,[32,1],[\"sidebar.sections.categories.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"        \"],[8,[32,2],[[16,\"data-category-id\",[30,2,[\"category\",\"id\"]]]],[[\"@route\",\"@title\",\"@content\",\"@currentWhen\",\"@model\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\"],[[30,2,[\"route\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"model\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"],[8,[32,3],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/anonymous/categories-section.js",
    "scope": () => [Section, i18n, SectionLink, AllCategoriesSectionLink],
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './topic-dismiss-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DismissReadModal from "discourse/components/modal/dismiss-read";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./topic-dismiss-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  classNames: ["topic-dismiss-buttons"],
  currentUser: service(),
  modal: service(),
  position: null,
  selectedTopics: null,
  model: null,
  containerClass(position) {
    return `dismiss-container-${position}`;
  },
  dismissReadId(position) {
    return `dismiss-topics-${position}`;
  },
  dismissNewId(position) {
    return `dismiss-new-${position}`;
  },
  showBasedOnPosition(position, topicCount) {
    if (position !== "top") {
      return topicCount > 5;
    }
    return true;
  },
  dismissLabel(selectedTopicCount) {
    if (selectedTopicCount === 0) {
      return I18n.t("topics.bulk.dismiss_button");
    }
    return I18n.t("topics.bulk.dismiss_button_with_selected", {
      count: selectedTopicCount
    });
  },
  dismissNewLabel(selectedTopicCount) {
    if (this.currentUser?.new_new_view_enabled) {
      return I18n.t("topics.bulk.dismiss_button");
    } else if (selectedTopicCount === 0) {
      return I18n.t("topics.bulk.dismiss_new");
    }
    return I18n.t("topics.bulk.dismiss_new_with_selected", {
      count: selectedTopicCount
    });
  },
  dismissReadPosts() {
    let dismissTitle = "topics.bulk.dismiss_read";
    if (this.selectedTopics.length) {
      dismissTitle = "topics.bulk.dismiss_read_with_selected";
    }
    this.modal.show(DismissReadModal, {
      model: {
        title: dismissTitle,
        count: this.selectedTopics.length,
        dismissRead: this.dismissRead
      }
    });
  }
}, [["method", "containerClass", [discourseComputed("position")]], ["method", "dismissReadId", [discourseComputed("position")]], ["method", "dismissNewId", [discourseComputed("position")]], ["method", "showBasedOnPosition", [discourseComputed("position", "model.topics.length")]], ["method", "dismissLabel", [discourseComputed("selectedTopics.length")]], ["method", "dismissNewLabel", [discourseComputed("selectedTopics.length")]], ["method", "dismissReadPosts", [action]]])));
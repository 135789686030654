/* import __COLOCATED_TEMPLATE__ from './user-card-contents.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import EmberObject, { action, set } from "@ember/object";
import { alias, and, gt, gte, not, or } from "@ember/object/computed";
import { dasherize } from "@ember/string";
import { isEmpty } from "@ember/utils";
import { propertyNotEqual, setting } from "discourse/lib/computed";
import { durationTiny } from "discourse/lib/formatter";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";
import CanCheckEmails from "discourse/mixins/can-check-emails";
import CardContentsBase from "discourse/mixins/card-contents-base";
import CleansUp from "discourse/mixins/cleans-up";
import User from "discourse/models/user";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./user-card-contents.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(CardContentsBase, CanCheckEmails, CleansUp, dt7948.p({
  elementId: "user-card",
  classNames: "user-card",
  avatarSelector: "[data-user-card]",
  avatarDataAttrKey: "userCard",
  mentionSelector: "a.mention",
  classNameBindings: ["visible:show", "showBadges", "user.card_background_upload_url::no-bg", "isFixed:fixed", "usernameClass", "primaryGroup"],
  attributeBindings: ["labelledBy:aria-labelledby"],
  allowBackgrounds: setting("allow_profile_backgrounds"),
  showBadges: setting("enable_badges"),
  postStream: alias("topic.postStream"),
  enoughPostsForFiltering: gte("topicPostCount", 2),
  showFilter: and("viewingTopic", "postStream.hasNoFilters", "enoughPostsForFiltering"),
  showName: propertyNotEqual("user.name", "user.username"),
  hasUserFilters: gt("postStream.userFilters.length", 0),
  showMoreBadges: gt("moreBadgesCount", 0),
  showDelete: and("viewingAdmin", "showName", "user.canBeDeleted"),
  linkWebsite: not("user.isBasic"),
  labelledBy(user) {
    return user ? "discourse-user-card-title" : null;
  },
  hasLocaleOrWebsite(user) {
    return user.location || user.website_name || this.userTimezone;
  },
  hasStatus() {
    return this.siteSettings.enable_user_status && this.user.status;
  },
  userStatusEmoji(emoji) {
    return emojiUnescape(escapeExpression(`:${emoji}:`));
  },
  isSuspendedOrHasBio: or("user.suspend_reason", "user.bio_excerpt"),
  showCheckEmail: and("user.staged", "canCheckEmails"),
  user: null,
  // If inside a topic
  topicPostCount: null,
  showFeaturedTopic: and("user.featured_topic", "siteSettings.allow_featured_topic_on_user_profiles"),
  showUserLocalTime: setting("display_local_time_in_user_card"),
  staff: isStaff => isStaff ? "staff" : "",
  newUser: trustLevel => trustLevel === 0 ? "new-user" : "",
  nameFirst(name) {
    return prioritizeNameInUx(name);
  },
  userTimezone(user) {
    if (!this.showUserLocalTime) {
      return;
    }
    return user.get("user_option.timezone");
  },
  formattedUserLocalTime(timezone) {
    return moment.tz(timezone).format(I18n.t("dates.time"));
  },
  usernameClass: username => username ? `user-card-${username}` : "",
  filterPostsLabel(username, count) {
    return I18n.t("topic.filter_to", {
      username,
      count
    });
  },
  publicUserFields() {
    const siteUserFields = this.site.get("user_fields");
    if (!isEmpty(siteUserFields)) {
      const userFields = this.get("user.user_fields");
      return siteUserFields.filterBy("show_on_user_card", true).sortBy("position").map(field => {
        set(field, "dasherized_name", dasherize(field.get("name")));
        const value = userFields ? userFields[field.get("id")] : null;
        return isEmpty(value) ? null : EmberObject.create({
          value,
          field
        });
      }).compact();
    }
  },
  removeNoFollow(trustLevel) {
    return trustLevel > 2 && !this.siteSettings.tl3_links_no_follow;
  },
  moreBadgesCount: (badgeCount, badgeLength) => badgeCount - badgeLength,
  showRecentTimeRead(timeRead, recentTimeRead) {
    return timeRead !== recentTimeRead && recentTimeRead !== 0;
  },
  recentTimeRead(recentTimeReadSeconds) {
    return durationTiny(recentTimeReadSeconds);
  },
  timeReadTooltip(showRecent, timeRead, recentTimeRead) {
    if (showRecent) {
      return I18n.t("time_read_recently_tooltip", {
        time_read: durationTiny(timeRead),
        recent_time_read: recentTimeRead
      });
    } else {
      return I18n.t("time_read_tooltip", {
        time_read: durationTiny(timeRead)
      });
    }
  },
  addBackground() {
    if (!this.allowBackgrounds) {
      return;
    }
    const thisElem = this.element;
    if (!thisElem) {
      return;
    }
    const url = this.get("user.card_background_upload_url");
    const bg = isEmpty(url) ? "" : `url(${getURLWithCDN(url)})`;
    thisElem.style.backgroundImage = bg;
  },
  primaryGroup(primaryGroup) {
    return `group-${primaryGroup}`;
  },
  contentHidden(profileHidden, inactive) {
    return profileHidden || inactive;
  },
  _showCallback(username, $target) {
    this._positionCard($target);
    this.setProperties({
      visible: true,
      loading: true
    });
    const args = {
      forCard: true,
      include_post_count_for: this.get("topic.id")
    };
    return User.findByUsername(username, args).then(user => {
      if (user.topic_post_count) {
        this.set("topicPostCount", user.topic_post_count[args.include_post_count_for]);
      }
      this.setProperties({
        user
      });
      this.user.statusManager.trackStatus();
      return user;
    }).catch(() => this._close()).finally(() => this.set("loading", null));
  },
  _close() {
    if (this.user) {
      this.user.statusManager.stopTrackingStatus();
    }
    this.setProperties({
      user: null,
      topicPostCount: null
    });
    this._super(...arguments);
  },
  cleanUp() {
    this._close();
  },
  handleShowUser(user, event) {
    if (wantsNewWindow(event)) {
      return;
    }
    event.preventDefault();
    // Invokes `showUser` argument. Convert to `this.args.showUser` when
    // refactoring this to a glimmer component.
    this.showUser(user);
    this._close();
  },
  actions: {
    close() {
      this._close();
    },
    composePM(user, post) {
      this._close();
      this.composePrivateMessage(user, post);
    },
    cancelFilter() {
      const postStream = this.postStream;
      postStream.cancelFilter();
      postStream.refresh();
      this._close();
    },
    filterPosts() {
      this.filterPosts(this.user);
      this._close();
    },
    deleteUser() {
      this.user.delete();
      this._close();
    },
    showUser(user) {
      this.handleShowUser(user);
    },
    checkEmail(user) {
      user.checkEmail();
    }
  }
}, [["method", "labelledBy", [discourseComputed("user")]], ["method", "hasLocaleOrWebsite", [discourseComputed("user")]], ["method", "hasStatus", [discourseComputed("user.status")]], ["method", "userStatusEmoji", [discourseComputed("user.status.emoji")]], ["field", "staff", [discourseComputed("user.staff")]], ["field", "newUser", [discourseComputed("user.trust_level")]], ["method", "nameFirst", [discourseComputed("user.name")]], ["method", "userTimezone", [discourseComputed("user")]], ["method", "formattedUserLocalTime", [discourseComputed("userTimezone")]], ["field", "usernameClass", [discourseComputed("username")]], ["method", "filterPostsLabel", [discourseComputed("username", "topicPostCount")]], ["method", "publicUserFields", [discourseComputed("user.user_fields.@each.value")]], ["method", "removeNoFollow", [discourseComputed("user.trust_level")]], ["field", "moreBadgesCount", [discourseComputed("user.badge_count", "user.featured_user_badges.length")]], ["method", "showRecentTimeRead", [discourseComputed("user.time_read", "user.recent_time_read")]], ["method", "recentTimeRead", [discourseComputed("user.recent_time_read")]], ["method", "timeReadTooltip", [discourseComputed("showRecentTimeRead", "user.time_read", "recentTimeRead")]], ["method", "addBackground", [observes("user.card_background_upload_url")]], ["method", "primaryGroup", [discourseComputed("user.primary_group_name")]], ["method", "contentHidden", [discourseComputed("user.profile_hidden", "user.inactive")]], ["method", "handleShowUser", [action]]])));
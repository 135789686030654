import Component from "@glimmer/component";
import { service } from "@ember/service";
import { extractLinkMeta } from "discourse/lib/render-topic-featured-link";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FeaturedLink extends Component {
  static #_ = (() => dt7948.g(this.prototype, "header", [service]))();
  #header = (() => (dt7948.i(this, "header"), void 0))();
  get meta() {
    return extractLinkMeta(this.header.topic);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.meta}}
        <a
          class="topic-featured-link"
          rel={{this.meta.rel}}
          target={{this.meta.target}}
          href={{this.meta.href}}
        >
          {{icon "external-link-alt"}}
          {{this.meta.domain}}
        </a>
      {{/if}}
    
  */
  {
    "id": "WVyk9GgC",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"meta\"]],[[[1,\"      \"],[10,3],[14,0,\"topic-featured-link\"],[15,\"rel\",[30,0,[\"meta\",\"rel\"]]],[15,\"target\",[30,0,[\"meta\",\"target\"]]],[15,6,[30,0,[\"meta\",\"href\"]]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"external-link-alt\"],null]],[1,\"\\n        \"],[1,[30,0,[\"meta\",\"domain\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/topic/featured-link.js",
    "scope": () => [icon],
    "isStrictMode": true
  }), this))();
}
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class CountI18n extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  get fullKey() {
    let key1 = this.args.key;
    if (this.args.suffix) {
      key1 += this.args.suffix;
    }
    if (this.currentUser?.new_new_view_enabled && key1 === "topic_count_new") {
      key1 = "topic_count_latest";
    }
    return key1;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <span>{{htmlSafe (i18n this.fullKey count=@count)}}</span>
    
  */
  {
    "id": "lROKUEwj",
    "block": "[[[1,\"\\n    \"],[10,1],[12],[1,[28,[32,0],[[28,[32,1],[[30,0,[\"fullKey\"]]],[[\"count\"],[[30,1]]]]],null]],[13],[1,\"\\n  \"]],[\"@count\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/count-i18n.js",
    "scope": () => [htmlSafe, i18n],
    "isStrictMode": true
  }), this))();
}
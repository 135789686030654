import EmberObject from "@ember/object";
import Mixin from "@ember/object/mixin";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Mixin.create(dt7948.p({
  nameInstructions() {
    return I18n.t(this.siteSettings.full_name_required ? "user.name.instructions_required" : "user.name.instructions");
  },
  nameValidation(accountName, forceValidationReason) {
    if (this.siteSettings.full_name_required && isEmpty(accountName)) {
      return EmberObject.create({
        failed: true,
        ok: false,
        message: I18n.t("user.name.required"),
        reason: forceValidationReason ? I18n.t("user.name.required") : null,
        element: document.querySelector("#new-account-name")
      });
    }
    return EmberObject.create({
      ok: true
    });
  }
}, [["method", "nameInstructions", [discourseComputed()]], ["method", "nameValidation", [discourseComputed("accountName", "forceValidationReason")]]]));
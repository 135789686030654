/* import __COLOCATED_TEMPLATE__ from './badge-selector.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import $ from "jquery";
import { makeArray } from "discourse-common/lib/helpers";
import { findRawTemplate } from "discourse-common/lib/raw-templates";
import discourseComputed, { observes, on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./badge-selector.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  placeholder(placeholderKey) {
    return placeholderKey ? I18n.t(placeholderKey) : "";
  },
  _update() {
    if (this.canReceiveUpdates === "true") {
      this._initializeAutocomplete({
        updateData: true
      });
    }
  },
  _initializeAutocomplete(opts) {
    let selectedBadges;
    $(this.element.querySelector("input")).autocomplete({
      allowAny: false,
      items: makeArray(this.badgeNames),
      single: this.single,
      updateData: opts && opts.updateData ? opts.updateData : false,
      template: findRawTemplate("badge-selector-autocomplete"),
      onChangeItems(items) {
        selectedBadges = items;
        this.set("badgeNames", items.join(","));
      },
      transformComplete(g) {
        return g.name;
      },
      dataSource(term) {
        return this.badgeFinder(term).then(badges => {
          if (!selectedBadges) {
            return badges;
          }
          return badges.filter(badge => !selectedBadges.any(s => s === badge.name));
        });
      }
    });
  }
}, [["method", "placeholder", [discourseComputed("placeholderKey")]], ["method", "_update", [observes("badgeNames")]], ["method", "_initializeAutocomplete", [on("didInsertElement")]]])));
import { action } from "@ember/object";
import { service } from "@ember/service";
import { RouteException } from "discourse/controllers/exception";
import User from "discourse/models/user";
import DiscourseRoute from "discourse/routes/discourse";
import { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default class UserRoute extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "searchService", [service("search")]))();
  #searchService = (() => (dt7948.i(this, "searchService"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "messageBus", [service]))();
  #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
  beforeModel() {
    if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
      throw new RouteException({
        status: 403,
        desc: I18n.t("user.login_to_view_profile")
      });
    }
  }
  model(params) {
    // If we're viewing the currently logged in user, return that object instead
    if (this.currentUser && params.username.toLowerCase() === this.currentUser.username_lower) {
      return this.currentUser;
    }
    return User.create({
      username: encodeURIComponent(params.username)
    });
  }
  afterModel() {
    const user = this.modelFor("user");
    return user.findDetails().then(() => user.findStaffInfo()).then(() => user.statusManager.trackStatus()).catch(() => this.router.replaceWith("/404"));
  }
  serialize(model) {
    if (!model) {
      return {};
    }
    return {
      username: (model.username || "").toLowerCase()
    };
  }
  setupController(controller, user) {
    controller.set("model", user);
    this.searchService.searchContext = user.searchContext;
  }
  activate() {
    super.activate(...arguments);
    const user = this.modelFor("user");
    this.messageBus.subscribe(`/u/${user.username_lower}`, this.onUserMessage);
    this.messageBus.subscribe(`/u/${user.username_lower}/counters`, this.onUserCountersMessage);
  }
  deactivate() {
    super.deactivate(...arguments);
    const user = this.modelFor("user");
    this.messageBus.unsubscribe(`/u/${user.username_lower}`, this.onUserMessage);
    this.messageBus.unsubscribe(`/u/${user.username_lower}/counters`, this.onUserCountersMessage);
    user.statusManager.stopTrackingStatus();

    // Remove the search context
    this.searchService.searchContext = null;
  }
  onUserMessage(data) {
    const user = this.modelFor("user");
    return user.loadUserAction(data);
  }
  static #_5 = (() => dt7948.n(this.prototype, "onUserMessage", [bind]))();
  onUserCountersMessage(data) {
    const user = this.modelFor("user");
    user.setProperties(data);
    Object.entries(data).forEach(_ref => {
      let [key, value] = _ref;
      return this.appEvents.trigger(`count-updated:${user.username_lower}:${key}`, value);
    });
  }
  static #_6 = (() => dt7948.n(this.prototype, "onUserCountersMessage", [bind]))();
  titleToken() {
    const username = this.modelFor("user").username;
    return username ? username : null;
  }
  undoRevokeApiKey(key) {
    key.undoRevoke();
  }
  static #_7 = (() => dt7948.n(this.prototype, "undoRevokeApiKey", [action]))();
  revokeApiKey(key) {
    key.revoke();
  }
  static #_8 = (() => dt7948.n(this.prototype, "revokeApiKey", [action]))();
}
/* import __COLOCATED_TEMPLATE__ from './blurb.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./blurb.hbs";
import { setComponentTemplate } from "@ember/component";
export default class Blurb extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
}
setComponentTemplate(TEMPLATE, Blurb);
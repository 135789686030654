/* import __COLOCATED_TEMPLATE__ from './group-manage-save-button.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { or } from "@ember/object/computed";
import { service } from "@ember/service";
import GroupDefaultNotificationsModal from "discourse/components/modal/group-default-notifications";
import { popupAutomaticMembershipAlert } from "discourse/controllers/groups-new";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-manage-save-button.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  modal: service(),
  saving: null,
  disabled: false,
  updateExistingUsers: null,
  hasFlair: or("model.flair_icon", "model.flair_upload_id"),
  savingText(saving) {
    return saving ? I18n.t("saving") : I18n.t("save");
  },
  privateGroupNameNotice(visibilityLevel, isPrimaryGroup, hasFlair) {
    if (visibilityLevel === 0) {
      return;
    }
    if (isPrimaryGroup) {
      return I18n.t("admin.groups.manage.alert.primary_group", {
        group_name: this.model.name
      });
    } else if (hasFlair) {
      return I18n.t("admin.groups.manage.alert.flair_group", {
        group_name: this.model.name
      });
    }
  },
  setUpdateExistingUsers(value) {
    this.updateExistingUsers = value;
  },
  save() {
    if (this.beforeSave) {
      this.beforeSave();
    }
    this.set("saving", true);
    const group = this.model;
    popupAutomaticMembershipAlert(group.id, group.automatic_membership_email_domains);
    const opts = {};
    if (this.updateExistingUsers !== null) {
      opts.update_existing_users = this.updateExistingUsers;
    }
    return group.save(opts).then(() => {
      this.setProperties({
        saved: true,
        updateExistingUsers: null
      });
      if (this.afterSave) {
        this.afterSave();
      }
    }).catch(error => {
      const json = error.jqXHR.responseJSON;
      if (error.jqXHR.status === 422 && json.user_count) {
        this.editGroupNotifications(json);
      } else {
        popupAjaxError(error);
      }
    }).finally(() => this.set("saving", false));
  },
  async editGroupNotifications(json) {
    await this.modal.show(GroupDefaultNotificationsModal, {
      model: {
        count: json.user_count,
        setUpdateExistingUsers: this.setUpdateExistingUsers
      }
    });
    this.save();
  }
}, [["method", "savingText", [discourseComputed("saving")]], ["method", "privateGroupNameNotice", [discourseComputed("model.visibility_level", "model.primary_group", "hasFlair")]], ["method", "setUpdateExistingUsers", [action]], ["method", "save", [action]], ["method", "editGroupNotifications", [action]]])));
import { action } from "@ember/object";
import { service } from "@ember/service";
import { queryParams, resetParams } from "discourse/controllers/discovery/list";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import PreloadStore from "discourse/lib/preload-store";
import { setTopicList } from "discourse/lib/topic-list-tracker";
import Category from "discourse/models/category";
import CategoryList from "discourse/models/category-list";
import TopicList from "discourse/models/topic-list";
import { filterQueryParams, findTopicList } from "discourse/routes/build-topic-route";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
const AbstractCategoryRoute = dt7948.c(class AbstractCategoryRoute extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "composer", [service]))();
  #composer = (() => (dt7948.i(this, "composer"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "store", [service]))();
  #store = (() => (dt7948.i(this, "store"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "searchService", [service("search")]))();
  #searchService = (() => (dt7948.i(this, "searchService"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "historyStore", [service]))();
  #historyStore = (() => (dt7948.i(this, "historyStore"), void 0))();
  queryParams = (() => queryParams)();
  templateName = "discovery/list";
  controllerName = "discovery/list";
  async model(params, transition) {
    const category = this.site.lazy_load_categories ? await Category.asyncFindBySlugPathWithID(params.category_slug_path_with_id) : Category.findBySlugPathWithID(params.category_slug_path_with_id);
    if (!category) {
      this.router.replaceWith("/404");
      return;
    }
    if (this.routeConfig?.no_subcategories === undefined && category.default_list_filter === "none" && this.routeConfig?.filter === "default" && params) {
      // TODO: avoid throwing away preload data by redirecting on the server
      PreloadStore.getAndRemove("topic_list");
      this.router.replaceWith("discovery.categoryNone", params.category_slug_path_with_id);
      return;
    }
    const subcategoryListPromise = this._createSubcategoryList(category);
    const topicListPromise = this._retrieveTopicList(category, transition, params);
    const noSubcategories = !!this.routeConfig?.no_subcategories;
    const filterType = this.filter(category).split("/")[0];
    return {
      category,
      modelParams: params,
      subcategoryList: await subcategoryListPromise,
      list: await topicListPromise,
      noSubcategories,
      filterType
    };
  }
  filter(category) {
    return this.routeConfig?.filter === "default" ? category.get("default_view") || "latest" : this.routeConfig?.filter;
  }
  async _createSubcategoryList(category) {
    if (category.isParent && category.show_subcategory_list) {
      return CategoryList.list(this.store, category);
    }
  }
  async _retrieveTopicList(category, transition, modelParams) {
    const findOpts = filterQueryParams(modelParams, this.routeConfig);
    const extras = {
      cached: this.historyStore.isPoppedState
    };
    let listFilter = `c/${Category.slugFor(category)}/${category.id}`;
    if (findOpts.no_subcategories) {
      listFilter += "/none";
    }
    listFilter += `/l/${this.filter(category)}`;
    const topicList = await findTopicList(this.store, this.topicTrackingState, listFilter, findOpts, extras);
    TopicList.hideUniformCategory(topicList, category);
    return topicList;
  }
  titleToken() {
    const category = this.currentModel.category;
    const filterText = I18n.t("filters." + this.filter(category).replace("/", ".") + ".title");
    let categoryName = category.name;
    if (category.parent_category_id) {
      const list = Category.list();
      const parentCategory = list.findBy("id", category.parent_category_id);
      categoryName = `${parentCategory.name}/${categoryName}`;
    }
    return I18n.t("filters.with_category", {
      filter: filterText,
      category: categoryName
    });
  }
  setupController(controller, model) {
    super.setupController(...arguments);
    controller.bulkSelectHelper.clear();
    this.searchService.searchContext = model.category.get("searchContext");
    setTopicList(model.list);
    const p = model.category.params;
    if (p?.order !== undefined) {
      controller.order = p.order;
    }
    if (p?.ascending !== undefined) {
      controller.ascending = p.ascending;
    }
  }
  deactivate() {
    super.deactivate(...arguments);
    this.composer.set("prioritizedCategoryId", null);
    this.searchService.searchContext = null;
  }
  setNotification(notification_level) {
    this.currentModel.setNotification(notification_level);
  }
  static #_8 = (() => dt7948.n(this.prototype, "setNotification", [action]))();
  triggerRefresh() {
    this.refresh();
  }
  static #_9 = (() => dt7948.n(this.prototype, "triggerRefresh", [action]))();
  resetParams() {
    let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    resetParams.call(this, skipParams);
  }
  static #_10 = (() => dt7948.n(this.prototype, "resetParams", [action]))();
}, [disableImplicitInjections]); // A helper function to create a category route with parameters
export default function buildCategoryRoute(routeConfig) {
  return class extends AbstractCategoryRoute {
    routeConfig = (() => routeConfig)();
  };
}
/* import __COLOCATED_TEMPLATE__ from './recent-searches.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import User from "discourse/models/user";
import TEMPLATE from "./recent-searches.hbs";
import { setComponentTemplate } from "@ember/component";
export default class RecentSearches extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  constructor() {
    super(...arguments);
    if (this.currentUser && this.siteSettings.log_search_queries && !this.currentUser.recent_searches?.length) {
      this.loadRecentSearches();
    }
  }
  async clearRecent() {
    const result = await User.resetRecentSearches();
    if (result.success) {
      this.currentUser.recent_searches.clear();
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "clearRecent", [action]))();
  onKeyup(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      return false;
    }
    this.search.handleArrowUpOrDown(e);
  }
  static #_4 = (() => dt7948.n(this.prototype, "onKeyup", [action]))();
  async loadRecentSearches() {
    const result = await User.loadRecentSearches();
    if (result.success && result.recent_searches?.length) {
      this.currentUser.set("recent_searches", result.recent_searches);
    }
  }
}
setComponentTemplate(TEMPLATE, RecentSearches);
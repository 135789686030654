import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { service } from "@ember/service";
import CategoriesAndLatestTopics from "discourse/components/categories-and-latest-topics";
import CategoriesAndTopTopics from "discourse/components/categories-and-top-topics";
import CategoriesBoxes from "discourse/components/categories-boxes";
import CategoriesBoxesWithTopics from "discourse/components/categories-boxes-with-topics";
import CategoriesOnly from "discourse/components/categories-only";
import CategoriesWithFeaturedTopics from "discourse/components/categories-with-featured-topics";
import ConditionalLoadingSpinner from "discourse/components/conditional-loading-spinner";
import LoadMore from "discourse/components/load-more";
import PluginOutlet from "discourse/components/plugin-outlet";
import SubcategoriesWithFeaturedTopics from "discourse/components/subcategories-with-featured-topics";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const mobileCompatibleViews = ["categories_with_featured_topics", "subcategories_with_featured_topics"];
const subcategoryComponents = {
  boxes_with_featured_topics: CategoriesBoxesWithTopics,
  boxes: CategoriesBoxes,
  rows_with_featured_topics: CategoriesWithFeaturedTopics,
  rows: CategoriesOnly
};
const globalComponents = {
  categories_and_latest_topics_created_date: CategoriesAndLatestTopics,
  categories_and_latest_topics: CategoriesAndLatestTopics,
  categories_and_top_topics: CategoriesAndTopTopics,
  categories_boxes_with_topics: CategoriesBoxesWithTopics,
  categories_boxes: CategoriesBoxes,
  categories_only: CategoriesOnly,
  categories_with_featured_topics: CategoriesWithFeaturedTopics,
  subcategories_with_featured_topics: SubcategoriesWithFeaturedTopics
};
export default class CategoriesDisplay extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  get #componentForSubcategories() {
    const parentCategory1 = this.args.parentCategory;
    const style1 = parentCategory1.subcategory_list_style;
    const component1 = subcategoryComponents[style1];
    if (!component1) {
      // eslint-disable-next-line no-console
      console.error("Unknown subcategory list style: " + style1);
      return CategoriesOnly;
    }
    return component1;
  }
  get #globalComponent() {
    let style1 = this.siteSettings.desktop_category_page_style;
    if (this.site.mobileView && !mobileCompatibleViews.includes(style1)) {
      style1 = mobileCompatibleViews[0];
    }
    const component1 = globalComponents[style1];
    if (!component1) {
      // eslint-disable-next-line no-console
      console.error("Unknown category list style: " + style1);
      return CategoriesOnly;
    }
    return component1;
  }
  get categoriesComponent() {
    if (this.args.parentCategory) {
      return this.#componentForSubcategories;
    } else {
      return this.#globalComponent;
    }
  }
  get canLoadMore() {
    return this.site.lazy_load_categories && this.args.loadMore;
  }
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <PluginOutlet
        @name="above-discovery-categories"
        @connectorTagName="div"
        @outletArgs={{hash categories=@categories topics=@topics}}
      />
      {{#if this.canLoadMore}}
        <LoadMore
          @selector=".category:not(.muted-categories *)"
          @action={{@loadMore}}
        >
          <this.categoriesComponent
            @categories={{@categories}}
            @topics={{@topics}}
          />
          <ConditionalLoadingSpinner @condition={{@loadingMore}} />
        </LoadMore>
      {{else}}
        <this.categoriesComponent
          @categories={{@categories}}
          @topics={{@topics}}
        />
      {{/if}}
    
  */
  {
    "id": "kcdr7TWA",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"above-discovery-categories\",\"div\",[28,[32,1],null,[[\"categories\",\"topics\"],[[30,1],[30,2]]]]]],null],[1,\"\\n\"],[41,[30,0,[\"canLoadMore\"]],[[[1,\"      \"],[8,[32,2],null,[[\"@selector\",\"@action\"],[\".category:not(.muted-categories *)\",[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,0,[\"categoriesComponent\"]],null,[[\"@categories\",\"@topics\"],[[30,1],[30,2]]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@condition\"],[[30,4]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,0,[\"categoriesComponent\"]],null,[[\"@categories\",\"@topics\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@categories\",\"@topics\",\"@loadMore\",\"@loadingMore\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/discovery/categories-display.js",
    "scope": () => [PluginOutlet, hash, LoadMore, ConditionalLoadingSpinner],
    "isStrictMode": true
  }), this))();
}
/* import __COLOCATED_TEMPLATE__ from './edit-category-tab.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { empty } from "@ember/object/computed";
import { scheduleOnce } from "@ember/runloop";
import { underscore } from "@ember/string";
import { propertyEqual } from "discourse/lib/computed";
import DiscourseURL from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./edit-category-tab.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "li",
  classNameBindings: ["active", "tabClassName"],
  newCategory: empty("params.slug"),
  tabClassName(tab) {
    return "edit-category-" + tab;
  },
  active: propertyEqual("selectedTab", "tab"),
  title(tab) {
    return I18n.t(`category.${underscore(tab)}`);
  },
  didInsertElement() {
    this._super(...arguments);
    scheduleOnce("afterRender", this, this._addToCollection);
  },
  willDestroyElement() {
    this._super(...arguments);
    this.setProperties({
      selectedTab: "general",
      params: {}
    });
  },
  _addToCollection() {
    this.panels.addObject(this.tabClassName);
  },
  fullSlug(slug, parentSlug) {
    const slugPart = parentSlug && slug ? `${parentSlug}/${slug}` : slug;
    return getURL(`/c/${slugPart}/edit/${this.tab}`);
  },
  select(event) {
    event?.preventDefault();
    this.set("selectedTab", this.tab);
    if (!this.newCategory) {
      DiscourseURL.routeTo(this.fullSlug);
    }
  }
}, [["method", "tabClassName", [discourseComputed("tab")]], ["method", "title", [discourseComputed("tab")]], ["method", "fullSlug", [discourseComputed("params.slug", "params.parentSlug")]], ["method", "select", [action]]])));
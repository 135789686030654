/* import __COLOCATED_TEMPLATE__ from './user-badge.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-badge.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  showGrantCount(count) {
    return count && count > 1;
  },
  badgeUrl() {
    // NOTE: I tried using a link-to helper here but the queryParams mean it fails
    let username = this.get("user.username_lower") || "";
    username = username !== "" ? "?username=" + username : "";
    return this.get("badge.url") + username;
  }
}, [["method", "showGrantCount", [discourseComputed("count")]], ["method", "badgeUrl", [discourseComputed("badge", "user")]]])));
import { service } from "@ember/service";
import { homepageDestination } from "discourse/lib/homepage-router-overrides";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import DiscourseRoute from "./discourse";
const DiscoveryIndex = dt7948.c(class DiscoveryIndex extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  beforeModel(transition) {
    const url = transition.intent.url;
    const params = url?.split("?", 2)[1];
    let destination = homepageDestination();
    if (params) {
      destination += `&${params}`;
    }
    this.router.transitionTo(destination);
  }
}, [disableImplicitInjections]);
export default DiscoveryIndex;
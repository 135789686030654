import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "user-activity-bookmarks-page"}}

<ConditionalLoadingSpinner @condition={{this.loading}}>
  <PluginOutlet @name="above-user-bookmarks" @connectorTagName="div" />

  {{#if this.permissionDenied}}
    <div class="alert alert-info">{{i18n
        "bookmarks.list_permission_denied"
      }}</div>
  {{else if this.userDoesNotHaveBookmarks}}
    <EmptyState
      @title={{i18n "user.no_bookmarks_title"}}
      @body={{this.emptyStateBody}}
    />
  {{else}}
    <div class="inline-form full-width bookmark-search-form">
      <Input
        @type="text"
        @value={{this.searchTerm}}
        placeholder={{i18n "bookmarks.search_placeholder"}}
        @enter={{action "search"}}
        id="bookmark-search"
        autocomplete="off"
      />
      <DButton @action={{this.search}} @icon="search" class="btn-primary" />
    </div>
    {{#if this.nothingFound}}
      <div class="alert alert-info">{{i18n "user.no_bookmarks_search"}}</div>
    {{else}}
      <BookmarkList
        @bulkSelectHelper={{this.bulkSelectHelper}}
        @loadMore={{action "loadMore"}}
        @reload={{action "reload"}}
        @loadingMore={{this.loadingMore}}
        @content={{this.model.bookmarks}}
      />
    {{/if}}
  {{/if}}
</ConditionalLoadingSpinner>
*/
{
  "id": "1oA/MGVc",
  "block": "[[[1,[28,[35,0],[\"user-activity-bookmarks-page\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@name\",\"@connectorTagName\"],[\"above-user-bookmarks\",\"div\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"permissionDenied\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,4],[\"bookmarks.list_permission_denied\"],null]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"userDoesNotHaveBookmarks\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@title\",\"@body\"],[[28,[37,4],[\"user.no_bookmarks_title\"],null],[30,0,[\"emptyStateBody\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"inline-form full-width bookmark-search-form\"],[12],[1,\"\\n      \"],[8,[39,6],[[16,\"placeholder\",[28,[37,4],[\"bookmarks.search_placeholder\"],null]],[24,1,\"bookmark-search\"],[24,\"autocomplete\",\"off\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,0,[\"searchTerm\"]],[28,[37,7],[[30,0],\"search\"],null]]],null],[1,\"\\n      \"],[8,[39,8],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\"],[[30,0,[\"search\"]],\"search\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"nothingFound\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,4],[\"user.no_bookmarks_search\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,9],null,[[\"@bulkSelectHelper\",\"@loadMore\",\"@reload\",\"@loadingMore\",\"@content\"],[[30,0,[\"bulkSelectHelper\"]],[28,[37,7],[[30,0],\"loadMore\"],null],[28,[37,7],[[30,0],\"reload\"],null],[30,0,[\"loadingMore\"]],[30,0,[\"model\",\"bookmarks\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]],[]]]],[]]]]]],[],false,[\"body-class\",\"conditional-loading-spinner\",\"plugin-outlet\",\"if\",\"i18n\",\"empty-state\",\"input\",\"action\",\"d-button\",\"bookmark-list\"]]",
  "moduleName": "discourse/templates/user/bookmarks.hbs",
  "isStrictMode": false
});
import { tracked } from "@glimmer/tracking";
import { setOwner } from "@ember/application";
import { service } from "@ember/service";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import { ajax } from "discourse/lib/ajax";
import SectionLink from "discourse/lib/sidebar/section-link";
import { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default class Section {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "dragCss", [tracked]))();
  #dragCss = (() => (dt7948.i(this, "dragCss"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "links", [tracked]))();
  #links = (() => (dt7948.i(this, "links"), void 0))();
  constructor(_ref) {
    let {
      section,
      owner
    } = _ref;
    setOwner(this, owner);
    this.section = section;
    this.slug = section.slug;
    this.links = this.section.links.map(link => {
      return new SectionLink(link, this, this.router);
    });
  }
  get decoratedTitle() {
    return this.section.title;
  }
  get indicatePublic() {
    return this.section.public && this.currentUser?.staff;
  }
  get headerActions() {
    if (!this.section.public || this.currentUser?.admin) {
      return [{
        action: () => {
          return this.modal.show(SidebarSectionForm, {
            model: this
          });
        },
        title: I18n.t("sidebar.sections.custom.edit")
      }];
    }
  }
  get headerActionIcon() {
    return "pencil-alt";
  }
  disable() {
    this.dragCss = "disabled";
  }
  static #_6 = (() => dt7948.n(this.prototype, "disable", [bind]))();
  enable() {
    this.dragCss = null;
  }
  static #_7 = (() => dt7948.n(this.prototype, "enable", [bind]))();
  moveLinkDown(link) {
    const position = this.links.indexOf(link) + 1;
    this.links = this.links.removeObject(link);
    this.links.splice(position, 0, link);
  }
  static #_8 = (() => dt7948.n(this.prototype, "moveLinkDown", [bind]))();
  moveLinkUp(link) {
    const position = this.links.indexOf(link) - 1;
    this.links = this.links.removeObject(link);
    this.links.splice(position, 0, link);
  }
  static #_9 = (() => dt7948.n(this.prototype, "moveLinkUp", [bind]))();
  reorder() {
    return ajax(`/sidebar_sections/reorder`, {
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify({
        sidebar_section_id: this.section.id,
        links_order: this.links.map(link => link.id)
      })
    });
  }
  static #_10 = (() => dt7948.n(this.prototype, "reorder", [bind]))();
}
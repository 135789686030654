/* import __COLOCATED_TEMPLATE__ from './group-manage-logs-row.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-manage-logs-row.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "",
  expandDetails: false,
  toggleDetails() {
    this.toggleProperty("expandDetails");
  },
  filter(params) {
    this.set(`filters.${params.key}`, params.value);
  }
}, [["method", "toggleDetails", [action]], ["method", "filter", [action]]])));
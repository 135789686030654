/* import __COLOCATED_TEMPLATE__ from './d-navigation.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { setting } from "discourse/lib/computed";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { NotificationLevels } from "discourse/lib/notification-levels";
import NavItem from "discourse/models/nav-item";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./d-navigation.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  router: service(),
  dialog: service(),
  tagName: "",
  filterMode: tracked(),
  fixedCategoryPositions: setting("fixed_category_positions"),
  get filterType() {
    return filterTypeForMode(this.filterMode);
  },
  categories() {
    let categories = this.site.categoriesList;
    if (!this.siteSettings.allow_uncategorized_topics) {
      categories = categories.filter(category => category.id !== this.site.uncategorized_category_id);
    }
    if (this.currentUser?.indirectly_muted_category_ids) {
      categories = categories.filter(category => !this.currentUser.indirectly_muted_category_ids.includes(category.id));
    }
    return categories;
  },
  showCategoryNotifications(category) {
    return category && this.currentUser;
  },
  categoryNotificationLevel(notificationLevel) {
    if (this.currentUser?.indirectly_muted_category_ids?.includes(this.category.id)) {
      return NotificationLevels.MUTED;
    } else {
      return notificationLevel;
    }
  },
  showTagNotifications(tagNotification, additionalTags) {
    return tagNotification && !additionalTags;
  },
  categoryReadOnlyBanner(category, createTopicDisabled) {
    if (category && this.currentUser && createTopicDisabled) {
      return category.read_only_banner;
    }
  },
  createTopicButtonDisabled(createTopicDisabled, hasDraft, categoryReadOnlyBanner, canCreateTopicOnTag, tagId) {
    if (tagId && !canCreateTopicOnTag) {
      return true;
    } else if (categoryReadOnlyBanner && !hasDraft) {
      return false;
    }
    return createTopicDisabled;
  },
  createTopicClass(categoryReadOnlyBanner, hasDraft) {
    let classNames = ["btn-default"];
    if (hasDraft) {
      classNames.push("open-draft");
    } else if (categoryReadOnlyBanner) {
      classNames.push("disabled");
    }
    return classNames.join(" ");
  },
  createTopicLabel(hasDraft) {
    return hasDraft ? "topic.open_draft" : "topic.create";
  },
  showCategoryEdit: canEdit => canEdit,
  showToggleInfo(additionalTags, category, tagId) {
    return !additionalTags && !category && tagId !== "none";
  },
  navItems(filterType, category, noSubcategories, tagId, currentRouteQueryParams, skipCategoriesNavItem) {
    return NavItem.buildList(category, {
      filterType,
      noSubcategories,
      currentRouteQueryParams,
      tagId,
      siteSettings: this.siteSettings,
      skipCategoriesNavItem
    });
  },
  notCategoriesRoute(filterType) {
    return filterType !== "categories";
  },
  async changeTagNotificationLevel(notificationLevel) {
    const response = await this.tagNotification.update({
      notification_level: notificationLevel
    });
    const payload = response.responseJson;
    this.tagNotification.set("notification_level", notificationLevel);
    this.currentUser.setProperties({
      watched_tags: payload.watched_tags,
      watching_first_post_tags: payload.watching_first_post_tags,
      tracked_tags: payload.tracked_tags,
      muted_tags: payload.muted_tags,
      regular_tags: payload.regular_tags
    });
  },
  actions: {
    changeCategoryNotificationLevel(notificationLevel) {
      this.category.setNotification(notificationLevel);
    },
    selectCategoryAdminDropdownAction(actionId) {
      switch (actionId) {
        case "create":
          this.createCategory();
          break;
        case "reorder":
          this.reorderCategories();
          break;
      }
    },
    clickCreateTopicButton() {
      if (this.categoryReadOnlyBanner && !this.hasDraft) {
        this.dialog.alert({
          message: htmlSafe(this.categoryReadOnlyBanner)
        });
      } else {
        this.createTopic();
      }
    }
  }
}, [["method", "filterType", [dependentKeyCompat]], ["method", "categories", [discourseComputed()]], ["method", "showCategoryNotifications", [discourseComputed("category")]], ["method", "categoryNotificationLevel", [discourseComputed("category.notification_level")]], ["method", "showTagNotifications", [discourseComputed("tagNotification", "additionalTags")]], ["method", "categoryReadOnlyBanner", [discourseComputed("category", "createTopicDisabled")]], ["method", "createTopicButtonDisabled", [discourseComputed("createTopicDisabled", "hasDraft", "categoryReadOnlyBanner", "canCreateTopicOnTag", "tag.id")]], ["method", "createTopicClass", [discourseComputed("categoryReadOnlyBanner", "hasDraft")]], ["method", "createTopicLabel", [discourseComputed("hasDraft")]], ["field", "showCategoryEdit", [discourseComputed("category.can_edit")]], ["method", "showToggleInfo", [discourseComputed("additionalTags", "category", "tag.id")]], ["method", "navItems", [discourseComputed("filterType", "category", "noSubcategories", "tag.id", "router.currentRoute.queryParams", "skipCategoriesNavItem")]], ["method", "notCategoriesRoute", [discourseComputed("filterType")]], ["method", "changeTagNotificationLevel", [action]]])));
/* import __COLOCATED_TEMPLATE__ from './user-stream-item.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { propertyEqual } from "discourse/lib/computed";
import { userPath } from "discourse/lib/url";
import { actionDescription } from "discourse/widgets/post-small-action";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-stream-item.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "li",
  classNameBindings: [":user-stream-item", ":item",
  // DEPRECATED: 'item' class
  "hidden", "item.deleted:deleted", "moderatorAction"],
  hidden: computed("item.hidden", function () {
    return this.get("item.hidden") && !(this.currentUser && this.currentUser.staff);
  }),
  moderatorAction: propertyEqual("item.post_type", "site.post_types.moderator_action"),
  actionDescription: actionDescription("item.action_code", "item.created_at", "item.action_code_who", "item.action_code_path"),
  userUrl(draftUsername, username) {
    return userPath((draftUsername || username).toLowerCase());
  }
}, [["method", "userUrl", [discourseComputed("item.draft_username", "item.username")]]])));
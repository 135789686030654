/* import __COLOCATED_TEMPLATE__ from './discourse-linked-text.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./discourse-linked-text.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  tagName: "span",
  translatedText(text) {
    if (text) {
      return I18n.t(...arguments);
    }
  },
  click(event) {
    if (event.target.tagName.toUpperCase() === "A") {
      this.action(this.actionParam);
    }
    return false;
  }
}, [["method", "translatedText", [discourseComputed("text", "textParams")]]])));
import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div id="second-factor">
  <h3>{{this.secondFactorTitle}}</h3>

  {{#if this.optionalText}}
    <p>{{html-safe this.optionalText}}</p>
  {{/if}}

  <p class="second-factor__description">{{this.secondFactorDescription}}</p>

  {{yield}}

  {{#if this.showToggleMethodLink}}
    <p>
      <a
        href
        class="toggle-second-factor-method"
        {{on "click" this.toggleSecondFactorMethod}}
      >{{i18n this.linkText}}</a>
    </p>
  {{/if}}
</div>
*/
{
  "id": "BOYxPfit",
  "block": "[[[10,0],[14,1,\"second-factor\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[30,0,[\"secondFactorTitle\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"optionalText\"]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,1],[[30,0,[\"optionalText\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,2],[14,0,\"second-factor__description\"],[12],[1,[30,0,[\"secondFactorDescription\"]]],[13],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\\n\"],[41,[30,0,[\"showToggleMethodLink\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[11,3],[24,6,\"\"],[24,0,\"toggle-second-factor-method\"],[4,[38,3],[\"click\",[30,0,[\"toggleSecondFactorMethod\"]]],null],[12],[1,[28,[35,4],[[30,0,[\"linkText\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"if\",\"html-safe\",\"yield\",\"on\",\"i18n\"]]",
  "moduleName": "discourse/components/second-factor-form.hbs",
  "isStrictMode": false
});
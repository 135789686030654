/* import __COLOCATED_TEMPLATE__ from './more-topics.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import { categoryBadgeHTML } from "discourse/helpers/category-link";
import getURL from "discourse-common/lib/get-url";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import TEMPLATE from "./more-topics.hbs";
import { setComponentTemplate } from "@ember/component";
export default class MoreTopics extends Component {
  static #_ = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [service]))();
  #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "pmTopicTrackingState", [service]))();
  #pmTopicTrackingState = (() => (dt7948.i(this, "pmTopicTrackingState"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  rememberTopicListPreference(value) {
    this.moreTopicsPreferenceTracking.updatePreference(value);
  }
  static #_6 = (() => dt7948.n(this.prototype, "rememberTopicListPreference", [action]))();
  get singleList() {
    return this.availableTabs.length === 1;
  }
  static #_7 = (() => dt7948.n(this.prototype, "singleList", [computed("moreTopicsPreferenceTracking.topicLists")]))();
  get selectedTab() {
    return this.moreTopicsPreferenceTracking.selectedTab;
  }
  static #_8 = (() => dt7948.n(this.prototype, "selectedTab", [computed("moreTopicsPreferenceTracking.selectedTab")]))();
  get availableTabs() {
    return this.moreTopicsPreferenceTracking.topicLists;
  }
  static #_9 = (() => dt7948.n(this.prototype, "availableTabs", [computed("moreTopicsPreferenceTracking.topicLists")]))();
  get browseMoreMessage() {
    return this.args.topic.isPrivateMessage ? this._privateMessageBrowseMoreMessage() : this._topicBrowseMoreMessage();
  }
  static #_10 = (() => dt7948.n(this.prototype, "browseMoreMessage", [computed("pmTopicTrackingState.isTracking", "pmTopicTrackingState.statesModificationCounter", "topicTrackingState.messageCount")]))();
  _privateMessageBrowseMoreMessage() {
    const username = this.currentUser.username;
    const suggestedGroupName = this.args.topic.suggested_group_name;
    const inboxFilter = suggestedGroupName ? "group" : "user";
    const unreadCount = this.pmTopicTrackingState.lookupCount("unread", {
      inboxFilter,
      groupName: suggestedGroupName
    });
    const newCount = this.pmTopicTrackingState.lookupCount("new", {
      inboxFilter,
      groupName: suggestedGroupName
    });
    if (unreadCount + newCount > 0) {
      const hasBoth = unreadCount > 0 && newCount > 0;
      if (suggestedGroupName) {
        return I18n.messageFormat("user.messages.read_more_group_pm_MF", {
          HAS_UNREAD_AND_NEW: hasBoth,
          UNREAD: unreadCount,
          NEW: newCount,
          username,
          groupName: suggestedGroupName,
          groupLink: this._groupLink(username, suggestedGroupName),
          basePath: getURL("")
        });
      } else {
        return I18n.messageFormat("user.messages.read_more_personal_pm_MF", {
          HAS_UNREAD_AND_NEW: hasBoth,
          UNREAD: unreadCount,
          NEW: newCount,
          username,
          basePath: getURL("")
        });
      }
    } else if (suggestedGroupName) {
      return I18n.t("user.messages.read_more_in_group", {
        groupLink: this._groupLink(username, suggestedGroupName)
      });
    } else {
      return I18n.t("user.messages.read_more", {
        basePath: getURL(""),
        username
      });
    }
  }
  _topicBrowseMoreMessage() {
    let category = this.args.topic.category;
    if (category && category.id === this.site.uncategorized_category_id) {
      category = null;
    }
    let unreadTopics = 0;
    let newTopics = 0;
    if (this.currentUser) {
      unreadTopics = this.topicTrackingState.countUnread();
      newTopics = this.topicTrackingState.countNew();
    }
    if (newTopics + unreadTopics > 0) {
      return I18n.messageFormat("topic.read_more_MF", {
        HAS_UNREAD_AND_NEW: unreadTopics > 0 && newTopics > 0,
        UNREAD: unreadTopics,
        NEW: newTopics,
        HAS_CATEGORY: category ? true : false,
        categoryLink: category ? categoryBadgeHTML(category) : null,
        basePath: getURL("")
      });
    } else if (category) {
      return I18n.t("topic.read_more_in_category", {
        categoryLink: categoryBadgeHTML(category),
        latestLink: getURL("/latest")
      });
    } else {
      return I18n.t("topic.read_more", {
        categoryLink: getURL("/categories"),
        latestLink: getURL("/latest")
      });
    }
  }
  _groupLink(username, groupName) {
    return `<a class="group-link" href="${getURL(`/u/${username}/messages/group/${groupName}`)}">${iconHTML("users")} ${groupName}</a>`;
  }
}
setComponentTemplate(TEMPLATE, MoreTopics);
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action, get } from "@ember/object";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import Yaml from "js-yaml";
import FormTemplate from "discourse/models/form-template";
import CheckboxField from "./checkbox";
import DropdownField from "./dropdown";
import InputField from "./input";
import MultiSelectField from "./multi-select";
import TextareaField from "./textarea";
import UploadField from "./upload";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FormTemplateField = setComponentTemplate(createTemplateFactory(
/*
  
  <@component
    @id={{@content.id}}
    @attributes={{@content.attributes}}
    @choices={{@content.choices}}
    @validations={{@content.validations}}
    @value={{@initialValue}}
  />

*/
{
  "id": "XB8p01N8",
  "block": "[[[1,\"\\n  \"],[8,[30,1],null,[[\"@id\",\"@attributes\",\"@choices\",\"@validations\",\"@value\"],[[30,2,[\"id\"]],[30,2,[\"attributes\"]],[30,2,[\"choices\"]],[30,2,[\"validations\"]],[30,3]]],null],[1,\"\\n\"]],[\"@component\",\"@content\",\"@initialValue\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/form-template-field/wrapper.js",
  "isStrictMode": true
}), templateOnly());
export default class FormTemplateFieldWrapper extends Component {
  static #_ = (() => dt7948.g(this.prototype, "error", [tracked], function () {
    return null;
  }))();
  #error = (() => (dt7948.i(this, "error"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "parsedTemplate", [tracked], function () {
    return null;
  }))();
  #parsedTemplate = (() => (dt7948.i(this, "parsedTemplate"), void 0))();
  initialValues = (() => this.args.initialValues ?? {})();
  fieldTypes = (() => ({
    checkbox: CheckboxField,
    input: InputField,
    dropdown: DropdownField,
    "multi-select": MultiSelectField,
    textarea: TextareaField,
    upload: UploadField
  }))();
  constructor() {
    super(...arguments);
    if (this.args.content) {
      // Content used when no id exists yet
      // (i.e. previewing while creating a new template)
      this._loadTemplate(this.args.content);
    } else if (this.args.id) {
      this._fetchTemplate(this.args.id);
    }
  }
  _loadTemplate(templateContent1) {
    try {
      this.parsedTemplate = Yaml.load(templateContent1);
      this.args.onSelectFormTemplate?.(this.parsedTemplate);
    } catch (e1) {
      this.error = e1;
    }
  }
  refreshTemplate() {
    if (Array.isArray(this.args?.id) && this.args?.id.length === 0) {
      return;
    }
    return this._fetchTemplate(this.args.id);
  }
  static #_3 = (() => dt7948.n(this.prototype, "refreshTemplate", [action]))();
  async _fetchTemplate(id1) {
    const response1 = await FormTemplate.findById(id1);
    const templateContent1 = await response1.form_template.template;
    return this._loadTemplate(templateContent1);
  }
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.parsedTemplate}}
        <div
          class="form-template-form__wrapper"
          {{didUpdate this.refreshTemplate @id}}
        >
          {{#each this.parsedTemplate as |content|}}
            <FormTemplateField
              @component={{get this.fieldTypes content.type}}
              @content={{content}}
              @initialValue={{get this.initialValues content.id}}
            />
          {{/each}}
        </div>
      {{else}}
        <div class="alert alert-error">
          {{this.error}}
        </div>
      {{/if}}
    
  */
  {
    "id": "sNV+8Gse",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"parsedTemplate\"]],[[[1,\"      \"],[11,0],[24,0,\"form-template-form__wrapper\"],[4,[32,0],[[30,0,[\"refreshTemplate\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"parsedTemplate\"]]],null]],null],null,[[[1,\"          \"],[8,[32,1],null,[[\"@component\",\"@content\",\"@initialValue\"],[[28,[32,2],[[30,0,[\"fieldTypes\"]],[30,2,[\"type\"]]],null],[30,2],[28,[32,2],[[30,0,[\"initialValues\"]],[30,2,[\"id\"]]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n        \"],[1,[30,0,[\"error\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@id\",\"content\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/form-template-field/wrapper.js",
    "scope": () => [didUpdate, FormTemplateField, get],
    "isStrictMode": true
  }), this))();
}